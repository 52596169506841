import React from 'react';

const Logo = ({
  style = {},
  fill = '#000',
  width = '24',
  className = '',
  viewBox = '0 0 195 222',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Logo Gamenight</title>
    <g
      id="Group"
      transform="translate(97.484378, 111.105003) rotate(-360.000000) translate(-97.484378, -111.105003) translate(-0.015622, 0.105003)"
      fill={fill}
    >
      <path
        d="M22.8195508,38.4394015 L93.7991257,3.92895677 C110.561165,-4.2207881 130.746912,0.628753942 141.977392,15.5035856 L180.867358,67.0135565 C188.188502,76.7104516 186.262575,90.5062983 176.56568,97.8274425 C175.642804,98.5242132 174.666428,99.1471369 173.645629,99.6904231 L92.9765761,142.623835 C81.2880901,148.844642 66.7915571,145.287783 59.3098065,134.363382 L14.6758347,69.1915437 C8.12237093,59.6225706 10.5669243,46.5527531 20.1358974,39.9992894 C20.9907425,39.4138351 21.8877434,38.8924485 22.8195508,38.4394015 Z"
        id="Rectangle-3-Copy-4"
      ></path>
      <path
        d="M5.66230734,66.9981725 C7.66109266,67.0679716 45.5961147,124.283075 62.5138698,156.642704 C67.7317524,166.623268 77.6267366,228.230235 63.4447402,215.96875 C57.2642421,210.625209 9.80633106,132.591337 8.12247458,127.863281 C3.53616113,114.985493 -4.00761454,66.9981725 5.66230734,66.9981725 Z"
        id="Rectangle-3-Copy-5"
      ></path>
      <path
        d="M81.3807756,160.417235 C74.3817066,170.248576 74.0158626,213.074429 82.1988344,220.774155 C91.7014936,229.715634 187.874366,181.459615 190.868721,163.673907 C191.611452,159.262272 194.802938,114.175567 193.920182,108.575832 C191.213101,91.403543 88.6559122,150.198112 81.3807756,160.417235 Z"
        id="Rectangle-3-Copy-6"
      ></path>
    </g>
  </svg>
);

Logo.displayName = 'Logo';

export default Logo;
