import React from 'react';
import {
  Box,
  Chip,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  Title as TitleIcon,
  Today as TodayIcon,
  Description as DescriptionIcon,
  Error as ErrorIcon,
} from '@material-ui/icons';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Options } from '@gamenight/common/dist/types';
import { Formik, Field } from 'formik';
import {
  INSERT_EVENT,
  INSERT_GUEST_EVENT,
  UPDATE_EVENT,
  UPSERT_ATTENDANCE,
  // UPSERT_GUEST_ATTENDANCE,
  GET_EVENT,
} from '@gamenight/common/dist/queries';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { generateCode } from '@gamenight/common/dist/utils';
import { useUiContext } from '@gamenight/common/dist/hooks/ui-context';
import ReactGA from 'react-ga';

import { useStyles } from './styles';
import { Props, Values } from './types';
import {
  DateTimePickerField,
  Persons,
  Location,
  BringYourOwn,
  Deadline,
  Duration,
  Public,
  Boardgames,
} from './form';
import { useAuth0 } from '../../auth';
import { getNewDateSuggestion, getValidationSchema } from './helpers';
import { useEventChange } from '../../helpers';
import DialogTitle from './components/dialog-title';
import LoadingScreen from '../loading-screen';

const optionalFields = {
  persons: Persons,
  public: Public,
  location: Location,
  boardgames: Boardgames,
  duration: Duration,
  deadline: Deadline,
  bringYourOwn: BringYourOwn,
};

const EditEvent: React.FC<Props> = ({ dialogOpen, handleClose, event }) => {
  const activeOptionsValues = (Object.keys(optionalFields) as Array<
    keyof Options
  >).reduce(
    (acc, key) => ({
      ...acc,
      [key]: !!event?.options?.[key],
    }),
    {} as Record<string, boolean>,
  );
  const eventChange = useEventChange();
  const { push } = useHistory();

  const [activeOptions, setActiveOptions] = React.useState(activeOptionsValues);
  const theme = useTheme();
  const dialogPadding = useMediaQuery(theme.breakpoints.down('sm'))
    ? theme.spacing(2)
    : theme.spacing(4);

  const { isAuthenticated, user } = useAuth0();
  const { setSnackbarMessage } = useUiContext();

  const classes = useStyles({ dialogPadding });
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = React.useState(false);

  const [submitInsertEventMutation] = useMutation(INSERT_EVENT);
  const [submitInsertGuestEventMutation] = useMutation(INSERT_GUEST_EVENT);
  const [submitUpdateEventMutation] = useMutation(UPDATE_EVENT);
  const [submitAttendanceMutation] = useMutation(UPSERT_ATTENDANCE);

  const initialValues: Values = {
    date: event?.date ? event.date : getNewDateSuggestion().toString(),
    title: event?.title ? event.title : '',
    description: event?.description ? event.description : '',
    // add optional field-value
    ...(Object.keys(optionalFields) as Array<keyof Options>).reduce(
      (acc, key) => ({
        ...acc,
        [key]: event?.options?.[key],
      }),
      {},
    ),
  };

  const isNewEvent = !event;

  const handleSubmit = async (values: Values) => {
    const options: Record<string, any> = {};
    (Object.keys(values) as Array<keyof Values>).forEach(key => {
      // set unused options to null
      if (Object.keys(optionalFields).includes(key) && activeOptions[key]) {
        // this is a option field
        options[key] = values[key];
        ReactGA.event({
          category: 'event_settings',
          action: `${key}_${isNewEvent ? 'create' : 'update'}`,
          value: event?.id,
        });
      }
    });
    const code = generateCode(6);
    const adminCode = generateCode(12);

    ReactGA.event({
      category: 'event_settings',
      action: `description_${values.description ? 'content' : 'empty'}`,
      value: event?.id,
    });

    const eventData = {
      title: values.title,
      date: new Date(values.date),
      description: values.description,
      options,
    };

    let result, query;

    if (isNewEvent) {
      query = 'insert_event';
      result = isAuthenticated
        ? await submitInsertEventMutation({
            variables: {
              code,
              adminCode,
              ...eventData,
              userId: user?.sub,
            },
          })
        : await submitInsertGuestEventMutation({
            variables: {
              code,
              adminCode,
              ...eventData,
              guestName: values.guestName,
              guestEmail: values.guestEmail,
            },
          });
    } else {
      query = 'update_event';
      result = await submitUpdateEventMutation({
        variables: { id: event?.id, ...eventData, guestName: values.guestName },
        refetchQueries: [
          {
            query: GET_EVENT,
            variables: { eventCode: event?.code },
          },
        ],
      });
      handleClose();
      window.scrollTo(0, 0);
    }

    const resultEvent = result?.data[query]?.returning?.[0];

    if (!resultEvent) {
      throw new Error('could not create/update event');
    }

    if (isNewEvent && isAuthenticated) {
      const attendanceData = {
        code: adminCode,
        attendance: true,
        eventId: resultEvent.id,
      };

      const attendanceResult = await submitAttendanceMutation({
        variables: {
          ...attendanceData,
          userId: user?.sub,
        },
      });

      if (attendanceResult.errors) {
        // TODO: log error, but continue to redirect with message?
      }
      eventChange(resultEvent, isAuthenticated, isNewEvent);
    }

    setLoading(false);
    push(`/e/${resultEvent.code}/${resultEvent.admin_code}`);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema(event, activeOptions)}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          submitForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Dialog
              open={dialogOpen}
              onClose={handleClose}
              scroll={isMobile ? 'body' : 'paper'}
              fullWidth
              maxWidth="md"
            >
              <DialogTitle handleClose={handleClose}>
                {isNewEvent ? 'New event' : 'Edit event'}
              </DialogTitle>
              <DialogContent dividers className={classes.content}>
                {loading && <LoadingScreen variant="overlay" fullscreen />}
                <Box display="flex" flexDirection="column">
                  <Box className={classes.formBlock}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <Box className={classes.iconContainer}>
                          <TitleIcon />
                        </Box>
                      </Grid>
                      <Grid item className={classes.fullWidthContainer}>
                        <TextField
                          autoFocus
                          className={classes.fullWidthField}
                          variant="outlined"
                          error={!!errors.title && touched.title}
                          id="event-form-input-title"
                          type="text"
                          label="Event title"
                          name="title"
                          placeholder="e.g. Gamenight at my place"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title}
                          helperText={
                            errors.title && touched.title && errors.title
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.formBlock}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <Box className={classes.iconContainer}>
                          <TodayIcon />
                        </Box>
                      </Grid>
                      <Grid item className={classes.fullWidthContainer}>
                        <Field
                          label="Date"
                          name="date"
                          component={DateTimePickerField}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.formBlock}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <Box className={classes.iconContainer}>
                          <DescriptionIcon />
                        </Box>
                      </Grid>
                      <Grid item className={classes.fullWidthContainer}>
                        <TextField
                          multiline
                          rows={1}
                          rowsMax={8}
                          className={classes.fullWidthField}
                          variant="outlined"
                          error={!!errors.description && touched.description}
                          id="event-form-input-description"
                          type="text"
                          label="Description"
                          name="description"
                          placeholder="E.g. games you will be playing, is this for hardcore gamers or just a fun night, etc"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                          helperText={
                            errors.description &&
                            touched.description &&
                            errors.description
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.formBlock}>
                    <Chip color="primary" label="Extra options" />
                    <Box mt={1} className={classes.optionsContainer}>
                      <Grid
                        className={classes.optionsWrapper}
                        container
                        spacing={1}
                        alignItems="flex-end"
                      >
                        {(Object.keys(optionalFields) as Array<
                          keyof typeof optionalFields
                        >).map(fieldName => (
                          <Grid key={fieldName} item>
                            <Field
                              name={fieldName}
                              component={optionalFields[fieldName]}
                              errors={errors}
                              activeOptions={activeOptions}
                              setActiveOptions={setActiveOptions}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions>
                {touched && Object.keys(errors).length > 0 && (
                  <ErrorIcon color="error" />
                )}
                <Button
                  type="submit"
                  color="secondary"
                  disabled={loading}
                  variant="contained"
                  onClick={() => {
                    if (
                      process.env.REACT_APP_DEPLOY === 'development' ||
                      process.env.NODE_ENV !== 'production'
                    ) {
                      console.log('Submit form', values, errors);
                    }
                    if (Object.keys(errors).length > 0) {
                      setSnackbarMessage('Some fields were not valid');
                    } else {
                      setLoading(true);
                      submitForm();
                    }
                  }}
                >
                  {isNewEvent ? 'Create event' : 'Update event'}
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        )}
      </Formik>
    </>
  );
};

EditEvent.displayName = 'EditEvent';

export default EditEvent;
