import { makeStyles } from '@material-ui/core/styles';

import { StyledHeaderProps } from './types';

export const useStyles = makeStyles(theme => ({
  header: (props: StyledHeaderProps) => ({
    position: 'relative' as 'relative',
    color: 'white',
    background: `linear-gradient(100deg, ${props.color1} 0%, ${props.color2} 100%)`,
    height: 'auto',
    overflow: 'hidden',
    'text-align': 'center',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(6),
    '& > *': {
      // position: 'relative' as 'relative',
      // zIndex: 0,
    },

    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(14),
    },
  }),
  headerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: `linear-gradient(0deg, rgba(118,179,157,0.1) 0%, rgba(118,179,157,0.6) 100%)`,
    // zIndex: 0,
  },

  title: {},
  calendarIcon: {
    color: 'white',
  },
  headerText: {
    // textShadow: '1px 1px 10px rgba(0,0,0,0.75)',
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
  },
}));
