import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { Props } from './types';

const LoadingScreen: React.FC<Props> = props => {
  const classes = useStyles(props);

  return (
    <Box className={classes.root}>
      <CircularProgress color="primary" size={60} className={classes.loader} />
      {props.variant !== 'overlay' && (
        <Typography variant="subtitle2" color="textSecondary">
          Loading
        </Typography>
      )}
    </Box>
  );
};

LoadingScreen.displayName = 'LoadingScreen';

export default LoadingScreen;
