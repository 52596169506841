import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  title: {
    flexGrow: 1,
  },
  main: {
    position: 'relative' as 'relative',
    'overflow-x': 'hidden',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  mainEvent: {
    marginTop: theme.spacing(-16),
  },
}));
