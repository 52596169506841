import React from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  Message as MessageIcon,
  Edit as EditIcon,
  Cancel as CancelIcon,
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
} from '@material-ui/icons';
import { useUiContext } from '@gamenight/common/dist/hooks';
import { EVENT_STATUS } from '@gamenight/common/dist/types';
import { Formik } from 'formik';
import { format, isPast } from 'date-fns';
import { useMutation } from '@apollo/react-hooks';
import { GET_EVENT, UPDATE_EVENT_STATUS } from '@gamenight/common/dist/queries';
import ReactGA from 'react-ga';

import { Actions, Props, NotifyFormValues } from './types';
import { useStyles } from './styles';
import { sendMail, getAttendees } from '../../../helpers';
import AttendeesGroup from '../../attendees-group';

const dialogContent = {
  notify: {
    title: 'Notify all attending people',
    description:
      'We will send your message by email. Use this only for important messages, like a change in the date or time. Do not spam your friends, man.✌️',
    placeholder: 'Hi everybody. Important news about this event: ...',
  },
  cancel: {
    title: 'Cancel your event',
    description:
      'BEWARE: this action is permanent. You can notify your friends with a message (if you leave this empty, people will not be notified of the cancelation).',
    placeholder: 'Unfortunately I had to cancel this event.',
  },
};

const AdminActions: React.FC<Props> = props => {
  const classes = useStyles(props);
  const [dialog, setDialog] = React.useState<'cancel' | 'notify' | undefined>(
    undefined,
  );
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const { event, setEditDialogOpen, closeMenu, ...menuProps } = props;
  const { setSnackbarMessage } = useUiContext();
  const [updateEventStatusMutation, { loading }] = useMutation(
    UPDATE_EVENT_STATUS,
  );

  const attendees = getAttendees(event).filter(item => item.attendance);

  const eventHasPassed = isPast(new Date(event.date));

  const handleSubmit = async (values: NotifyFormValues) => {
    setSubmitLoading(true);
    ReactGA.event({
      category: 'event',
      action: dialog === 'cancel' ? 'cancel' : 'notify',
      value: event?.id,
    });

    if (dialog === 'cancel') {
      await updateEventStatusMutation({
        variables: {
          id: event.id,
          status: EVENT_STATUS.CANCELED,
        },
        refetchQueries: [
          {
            query: GET_EVENT,
            variables: { eventCode: event.code },
          },
        ],
      });
    }
    if (!!values.message.trim() && attendees.length > 0) {
      attendees.forEach(attendance => {
        const email = attendance.user?.email || attendance.guest_email;
        console.log('sending notification mail to ', email);
        if (email) {
          sendMail('admin-message', email, {
            name: attendance.user?.name || attendance.guest_name || 'stranger',
            event_name: event.title,
            date: format(new Date(event.date), 'PPp'),
            notification: values.message.trim(),
            link: `${window.location.origin}/e/${event.code}/${event.admin_code}`,
          });
        }
      });
      setSnackbarMessage(
        dialog === 'cancel'
          ? 'Canceled event and notified attendees'
          : 'Notified attendees',
      );
      setDialog(undefined);
      return;
    }
    if (dialog === 'cancel') {
      setSnackbarMessage('Canceled event');
      setDialog(undefined);
    }
    setSubmitLoading(false);
  };

  const lockEvent = async (lock: boolean) => {
    closeMenu();
    // set event to locked/active
    await updateEventStatusMutation({
      variables: {
        id: event.id,
        status: lock ? EVENT_STATUS.LOCKED : EVENT_STATUS.ACTIVE,
      },
      refetchQueries: [
        {
          query: GET_EVENT,
          variables: { eventCode: event.code },
        },
      ],
    });
    setSnackbarMessage(lock ? 'Locked event' : 'Unlocked event');
    ReactGA.event({
      category: 'event',
      action: lock ? 'lock' : 'unlock',
      value: event?.id,
    });
  };

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const action = params.get('action');
    switch (action) {
      case Actions.cancel:
        setDialog('cancel');
        break;
      case Actions.lock:
        lockEvent(true);
        break;
      case Actions.notify:
        setDialog('notify');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Menu {...menuProps}>
        <MenuItem
          onClick={() => {
            closeMenu();
            setEditDialogOpen(true);
          }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <Typography variant="inherit">Edit event</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            closeMenu();
            setDialog('notify');
          }}
          disabled={eventHasPassed || attendees.length === 0}
        >
          <ListItemIcon>
            <MessageIcon />
          </ListItemIcon>
          <Typography variant="inherit">Notify attending people</Typography>
        </MenuItem>
        {event.status === EVENT_STATUS.ACTIVE && (
          <MenuItem onClick={() => lockEvent(true)}>
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
            <Typography variant="inherit">Lock event</Typography>
          </MenuItem>
        )}
        {event.status === EVENT_STATUS.LOCKED && (
          <MenuItem onClick={() => lockEvent(false)}>
            <ListItemIcon>
              <LockOpenIcon />
            </ListItemIcon>
            <Typography variant="inherit">Unlock event</Typography>
          </MenuItem>
        )}

        {!eventHasPassed && event.status === EVENT_STATUS.ACTIVE && (
          <MenuItem
            onClick={() => {
              closeMenu();
              setDialog('cancel');
            }}
            disabled={eventHasPassed}
          >
            <ListItemIcon>
              <CancelIcon className={classes.cancelIcon} />
            </ListItemIcon>
            <Typography variant="inherit">Cancel event</Typography>
          </MenuItem>
        )}
      </Menu>
      {dialog && (
        <Formik initialValues={{ message: '' }} onSubmit={handleSubmit}>
          {({ values, handleChange, handleBlur, handleSubmit, submitForm }) => (
            <form onSubmit={handleSubmit}>
              <Dialog open={true}>
                <DialogTitle id="alert-dialog-title">
                  {dialogContent[dialog].title}
                </DialogTitle>
                <DialogContent>
                  {attendees.length > 0 ? (
                    <>
                      <AttendeesGroup attendees={attendees} />
                      <br />
                      <DialogContentText id="notify-description">
                        {dialogContent[dialog].description}
                        <br />
                        <br />
                      </DialogContentText>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>
                          <MessageIcon />
                        </Grid>
                        <Grid item xs>
                          <TextField
                            className={classes.messageField}
                            autoFocus
                            variant="outlined"
                            multiline
                            rows={4}
                            rowsMax={8}
                            id="input-message"
                            type="text"
                            label="Message"
                            name="message"
                            placeholder={dialogContent[dialog].placeholder}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.message}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <DialogContentText id="cancel-prompt-description">
                      Are you sure?
                    </DialogContentText>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setDialog(undefined)}
                    variant="outlined"
                    color="secondary"
                    disabled={loading || submitLoading}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={submitForm}
                    color="secondary"
                    variant="contained"
                    disabled={loading || submitLoading}
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

AdminActions.displayName = 'AdminActions';

export default AdminActions;
