import { Account } from '@gamenight/common/dist/types';

import { normalizeUserGames } from './normalize-user-games';

export const normalizeAccountData = (data: any): Account => {
  return {
    ...data.user[0],
    user_games: normalizeUserGames(data.user[0]),
  };
};
