import React from 'react';
import {
  Container,
  Button,
  Box,
  Typography,
  Fab,
  Link,
} from '@material-ui/core';
import { useUiContext } from '@gamenight/common/dist/hooks/ui-context';
import { Add as AddIcon } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';

import { useAuth0 } from '../../auth';
import Layout from '../../components/layout';
import EventList from '../../components/event-list';
import EditEventDialog from '../../components/edit-event-dialog';
import { useStyles } from './styles';
import Logo from '../../components/logo';
import Bullet from '../../components/ui/bullet';

const Home: React.FC = () => {
  const { isAuthenticated, user, loginWithPopup } = useAuth0();
  const classes = useStyles();
  const { homeTab } = useUiContext();
  const [newEventDialogOpen, setNewEventDialogOpen] = React.useState(false);

  return (
    <>
      {isAuthenticated && user?.sub ? (
        <>
          <Layout variant="home">
            {homeTab === 0 && (
              <EventList
                userId={user.sub}
                setNewEventDialogOpen={setNewEventDialogOpen}
              />
            )}
            {homeTab === 1 && (
              <EventList
                userId={user.sub}
                setNewEventDialogOpen={setNewEventDialogOpen}
                archive
              />
            )}
          </Layout>
          <Fab
            className={classes.fab}
            color="secondary"
            aria-label="add"
            onClick={() => setNewEventDialogOpen(true)}
          >
            <AddIcon />
          </Fab>
        </>
      ) : (
        <Layout>
          <Container maxWidth="md">
            <Logo width="400" className={classes.backgroundLogo} />
            <Typography variant="h2" gutterBottom>
              Plan your perfect gamenight
            </Typography>
            <Typography variant="h5" gutterBottom>
              Create an event and share it with your boardgame-loving friends.
            </Typography>
            <Box textAlign="center" m={2}>
              <Button
                className={classes.cta}
                variant="contained"
                color="secondary"
                onClick={() => setNewEventDialogOpen(true)}
              >
                Start here!
              </Button>
            </Box>
            <Box maxWidth="sm" mb={8} textAlign="center">
              <Typography variant="body1" gutterBottom className={classes.usps}>
                totally free <Bullet /> no account needed
              </Typography>
            </Box>
            <Container maxWidth="sm">
              <Typography variant="h4" gutterBottom>
                What is this?
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Gamenight is a free service for people who want to organise
                boardgame nights. You can set a maximum players, check out who
                is coming, and a lot more. You can finally get that awesome
                5-player game out of the closet and have a perfect gamenight!
                <br />
                <br />
                Creating an event is free and{' '}
                <Link to="/new-event" component={RouterLink}>
                  super-easy.
                </Link>
                <br />
                <br />
                <br />
              </Typography>
              <Typography variant="h4" gutterBottom>
                What is next?
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                You can{' '}
                <Link
                  to="#"
                  onClick={() => loginWithPopup()}
                  component={RouterLink}
                >
                  create an account
                </Link>{' '}
                and see all your own events (and the events you signed up for)
                in one place. <br />
                <br />
                In the coming months, you will be able to connect to your
                friends, and invite them directly. You can also allow friends of
                friends to sign up for your event, so you can get a bigger group
                together quickly.
                <br />
                Stay tuned!
                <br />
                <br />
                In the meantime, I'd love some{' '}
                <Link to="/feedback" component={RouterLink}>
                  feedback
                </Link>
                .
              </Typography>
            </Container>
          </Container>
        </Layout>
      )}
      <EditEventDialog
        dialogOpen={newEventDialogOpen}
        handleClose={() => setNewEventDialogOpen(false)}
      />
    </>
  );
};

export default Home;
