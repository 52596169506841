// check if authenticated
// check if attendanceCode
// == admin_code > is this your event? (show avatar + event title)? > yes (connect) / no (push to default event-url)
// == attendance > double checking: did you respond to this event earlier as 'old name'? yes (connect) / no (push)

import React from 'react';
import {
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Chip,
} from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import {
  UPDATE_ATTENDANCE_OWNER,
  UPDATE_EVENT_OWNER,
  GET_EVENT,
} from '@gamenight/common/dist/queries';
import { useUiContext } from '@gamenight/common/dist/hooks/ui-context';

import { getAvatarLetter, getAttendees } from '../../../helpers';
import { Props } from './types';

const UpdateOwnerDialog: React.FC<Props> = props => {
  const { event, user, attendanceCode } = props;
  const { setSnackbarMessage } = useUiContext();

  const [dialog, setDialog] = React.useState<
    undefined | 'event' | 'attendance' | 'both'
  >(undefined);

  const responses = getAttendees(event);
  const responseByCode = responses.find(
    response => response.code === attendanceCode,
  );

  const [updateEventOwnerMutation] = useMutation(UPDATE_EVENT_OWNER);
  const [updateAttendanceOwnerMutation] = useMutation(UPDATE_ATTENDANCE_OWNER);

  React.useEffect(() => {
    let currentDialog = dialog;

    if (!event.user?.id && event.admin_code === attendanceCode) {
      // check if guest-event might be from this user
      currentDialog = 'event';
    }

    const responseByUser = responses.find(
      response => response.user?.id === user.sub,
    );
    if (!responseByUser && responseByCode) {
      console.log(responseByCode, responseByUser, user.sub);
      currentDialog = currentDialog === 'event' ? 'both' : 'attendance';
    }
    setDialog(currentDialog);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, event, attendanceCode]);

  // hard check attendance (outside userttendance)
  // if (!userAttendance.user?.id && )

  const updateOwner = async () => {
    if (dialog === 'event' || dialog === 'both') {
      console.log('update event');
      await updateEventOwnerMutation({
        variables: {
          id: event.id,
          userId: user.sub,
        },
        refetchQueries: [
          {
            query: GET_EVENT,
            variables: { eventCode: event?.code },
          },
        ],
      });
      setSnackbarMessage('Connected event to account');
    }
    if (responseByCode && (dialog === 'attendance' || dialog === 'both')) {
      await updateAttendanceOwnerMutation({
        variables: {
          code: responseByCode.code,
          userId: user.sub,
        },
        refetchQueries: [
          {
            query: GET_EVENT,
            variables: { eventCode: event?.code },
          },
        ],
      });
      if (dialog === 'attendance') {
        setSnackbarMessage('Connected response to account');
      }
    }
    setDialog(undefined);
  };

  return (
    <Dialog
      open={!!dialog}
      onClose={() => setDialog(undefined)}
      aria-label="update ownership of event or attendance"
    >
      <DialogTitle id="dialog-update-owner-title">Just checking...</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          {dialog &&
            ['event', 'both'].includes(dialog) &&
            'Is this your event? Click Yes to connect it to your account.'}
          {dialog === 'attendance' &&
            'Did you respond to this event? Click Yes to connect it to your account.'}
        </Typography>
        <br />
        <Typography variant="body1" color="textSecondary" gutterBottom>
          You are currently logged in as:
        </Typography>
        <Chip
          avatar={<Avatar alt={user.name} src={user.picture} />}
          color="primary"
          label={user.name}
        />

        <br />
        <br />
        {dialog && ['event', 'both'].includes(dialog) && (
          <>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              This event is posted by:
            </Typography>
            <Chip
              avatar={<Avatar>{getAvatarLetter(event.guest_name)}</Avatar>}
              color="primary"
              label={event.guest_name}
            />
          </>
        )}
        {dialog === 'attendance' && (
          <>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              This response was given by:
            </Typography>
            <Chip
              avatar={
                <Avatar>{getAvatarLetter(responseByCode?.guest_name)}</Avatar>
              }
              color="primary"
              label={responseByCode?.guest_name}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setDialog(undefined);
          }}
          color="secondary"
          variant="outlined"
        >
          No
        </Button>
        <Button
          onClick={() => {
            updateOwner();
          }}
          color="secondary"
          variant="contained"
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
UpdateOwnerDialog.displayName = 'UpdateOwnerDialog';

export default UpdateOwnerDialog;
