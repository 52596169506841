import { makeStyles } from '@material-ui/core/styles';

import { Props } from './types';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 600,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'hidden',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  listHeader: {
    cursor: 'pointer',
  },
  listUser: (props: Props) => ({
    '& > span': {
      color: props.attending
        ? theme.palette.text.primary
        : theme.palette.text.secondary,
    },
  }),
  divider: {
    color: 'white', // fix to remove weird symbol
  },
  contactIcon: {
    fontSize: 20,
    verticalAlign: 'middle',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  extraPersonsAvatar: {
    backgroundColor: 'transparent',
    border: `2px dashed ${theme.palette.primary.main}`,
  },
  clickableUser: {
    cursor: 'pointer',
  },
  changeAttendance: {
    margin: theme.spacing(1),
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(9),
      flexDirection: 'row',
    },
  },
}));
