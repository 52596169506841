import React from 'react';
import {
  TextField,
  Grid,
  Avatar,
  Chip,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import { LocalMall as BringYourOwnIcon } from '@material-ui/icons';

import Block from './block';
import { useStyles } from './styles';
import { BlockProps } from './types';

const optionName = 'bringYourOwn';

const BringYourOwn: React.FC<BlockProps<number>> = props => {
  const { form, errors, activeOptions, setActiveOptions } = props;

  const checkedOptions: Array<string> = (form.values[optionName]?.options || '')
    .split('|')
    .filter(Boolean);
  const [otherActive, setOtherActive] = React.useState(
    checkedOptions.includes('other'),
  );

  const optionError = activeOptions[optionName] && errors?.[optionName];

  const classes = useStyles(props);

  const hasValue =
    !!activeOptions[optionName] && !!form.values[optionName]?.options;

  const checkboxOptions = ['food', 'drinks', 'games'];

  return (
    <Block
      title="Bring your own"
      description="Check the things people should bring with them"
      optionValue={form.values[optionName]}
      optionName={optionName}
      hasValue={hasValue}
      form={form}
      errors={errors}
      cardContent={
        hasValue && !optionError ? (
          <Chip
            icon={<BringYourOwnIcon />}
            color="secondary"
            variant="outlined"
            label={form.values[optionName]?.options.split('|').join(', ')}
            className={classes.chip}
          />
        ) : (
          <Avatar
            className={[
              classes.avatarLarge,
              !!optionError ? classes.avatarError : '',
            ].join(' ')}
          >
            <BringYourOwnIcon color="inherit" />
          </Avatar>
        )
      }
      activeOptions={activeOptions}
      setActiveOptions={setActiveOptions}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item xs>
          <FormGroup>
            {checkboxOptions.map(key => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    checked={checkedOptions.includes(key)}
                    onChange={() => {
                      let newCheckedOptions = [];
                      if (checkedOptions.includes(key)) {
                        newCheckedOptions = checkedOptions.filter(
                          checkedOption => key !== checkedOption,
                        );
                      } else {
                        newCheckedOptions = [...checkedOptions, key];
                      }
                      form.setFieldValue(
                        'bringYourOwn',
                        {
                          ...form.values[optionName],
                          options: newCheckedOptions.join('|'),
                        },
                        true,
                      );
                    }}
                    value={key}
                    color="primary"
                  />
                }
                // TODO: map to nice strings
                label={`${key}`}
              />
            ))}
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedOptions.includes('other')}
                  onChange={() => {
                    let newCheckedOptions = [];
                    if (checkedOptions.includes('other')) {
                      newCheckedOptions = checkedOptions.filter(
                        checkedOption => 'other' !== checkedOption,
                      );
                      setOtherActive(false);
                    } else {
                      newCheckedOptions = [...checkedOptions, 'other'];
                      setOtherActive(true);
                    }
                    form.setFieldValue(
                      'bringYourOwn',
                      {
                        ...form.values[optionName],
                        options: newCheckedOptions.join('|'),
                      },
                      true,
                    );
                  }}
                  value={'other'}
                  color="primary"
                />
              }
              label={checkedOptions.includes('other') ? '' : 'other...'}
            />
            {otherActive && (
              <TextField
                autoFocus
                className={classes.checkboxOtherTextField}
                type="text"
                variant="outlined"
                value={form.values[optionName]?.other}
                label="Other"
                name="other"
                helperText={errors[optionName]?.other}
                error={!!errors[optionName]?.other}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldValue(
                    'bringYourOwn',
                    {
                      ...form.values[optionName],
                      other: event.target.value,
                    },
                    true,
                  );
                }}
              />
            )}
          </FormGroup>
        </Grid>
      </Grid>
    </Block>
  );
};

BringYourOwn.displayName = 'BringYourOwn';

export default BringYourOwn;
