import React from 'react';
import { Snackbar, Grid, Box, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import Confetti from 'react-confetti';

import Logo from '../../logo';
import { Props } from './types';
import { useStyles } from './styles';

const Celebration: React.FC<Props> = props => {
  const { celebrationMessage, setCelebrationMessage } = props;
  const classes = useStyles();

  const handleCelebrationClose = () => {
    setCelebrationMessage(null);
  };

  const action = (
    <IconButton size="small" onClick={handleCelebrationClose}>
      <CloseIcon />
    </IconButton>
  );

  return !!celebrationMessage ? (
    <Box position="fixed" width="100%" height="100%" className={classes.root}>
      <div className={classes.confetti}>
        <Confetti tweenDuration={10000} recycle={false} numberOfPieces={500} />
      </div>
      <Snackbar
        className={classes.celebration}
        open={true}
        autoHideDuration={60000}
        onClose={handleCelebrationClose}
        action={action}
        message={
          <>
            <Logo width="200" className={classes.celebrationLogo} />
            <Grid container className={classes.messageContainer}>
              <Grid item>{celebrationMessage}</Grid>
            </Grid>
          </>
        }
      />
    </Box>
  ) : null;
};

Celebration.displayName = 'Celebration';

export default Celebration;
