import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  avatar: {
    height: 50,
    width: 50,
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1,
  },
  expand: {
    padding: 0,
    alignSelf: 'flex-start',
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  boardgamesContainer: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
  },
}));
