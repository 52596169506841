import React from 'react';
import { Typography, Box } from '@material-ui/core';

import Layout from '../../components/layout';

const FourOhFour: React.FC = () => {
  return (
    <Layout>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        textAlign="center"
      >
        <Typography variant="h1" gutterBottom>
          404
        </Typography>
        <br />
        <Typography variant="h5" gutterBottom>
          This page could not be found.
          <br />
          :(
        </Typography>
      </Box>
    </Layout>
  );
};

FourOhFour.displayName = 'FourOhFour';

export default FourOhFour;
