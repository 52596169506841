import { makeStyles } from '@material-ui/core/styles';

import { Props } from './types';

export const useStyles = makeStyles(theme => ({
  root: (props: Props) => ({
    position: 'relative' as 'relative',
    width: props.variant === 'full' ? '100%' : '80%',
    backgroundColor: theme.palette.primary.light,
    marginLeft: props.variant === 'right' ? '20%' : 0,
    marginRight: props.variant === 'left' ? '20%' : 0,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    overflow: 'visible',
    paddingLeft: !!props.corner ? 30 : 0,
  }),
  content: {
    position: 'relative' as 'relative',
    overflow: 'hidden',
  },
  corner: {
    position: 'absolute' as 'absolute',
    top: -20,
    left: -20,
    borderRadius: 30,
    height: 60,
    width: 60,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.paper,
    paddingTop: 10,
    textAlign: 'center',
  },
  backgroundIcon: {
    position: 'absolute' as 'absolute',
    bottom: -20,
    right: 20,
    zIndex: 0,

    '& > svg': {
      height: 200,
      width: 200,
      fill: '#9ED8C3',
    },
  },
}));
