import React from 'react';
import { Grid, Collapse, IconButton, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import clsx from 'clsx';
import createDOMPurify from 'dompurify';

import { Props } from './types';
import { useStyles } from './styles';

const ExpandableText: React.FC<Props> = ({ text, startExpanded = false }) => {
  const [expanded, setExpanded] = React.useState(!!startExpanded);
  const classes = useStyles();

  const DOMPurify = createDOMPurify(window);

  const isLongText = text.length > 100;

  return (
    <Grid container>
      <Grid item xs>
        {isLongText ? (
          <>
            <Collapse
              in={expanded}
              timeout="auto"
              collapsedHeight={75}
              className={classes.collapse}
            >
              {' '}
              <Typography
                paragraph
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    text.replace(/&#10;|(?:\r\n|\r|\n)/gi, '<br />'),
                  ),
                }}
              />
              <div
                className={clsx(classes.fadeOut, {
                  [classes.fadeOutExpanded]: expanded,
                })}
              />
            </Collapse>
          </>
        ) : (
          <Typography
            paragraph
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                text.replace(/&#10;|(?:\r\n|\r|\n)/gi, '<br />'),
              ),
            }}
          />
        )}
      </Grid>
      <Grid item>
        {isLongText && (
          <Grid item>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

ExpandableText.displayName = 'ExpandableText';

export default ExpandableText;
