import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  badgeIcon: {
    height: 22,
    width: 22,
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.background.paper}`,
    '& > svg': {
      height: 12,
      width: 12,
    },
  },
}));
