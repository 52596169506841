import React from 'react';
import { Typography } from '@material-ui/core';
import ReactGA from 'react-ga';
import { format } from 'date-fns';
import { useUiContext } from '@gamenight/common/dist/hooks';
import { Event } from '@gamenight/common/dist/types';

import { sendMail } from './send-mail';

type UseEventChange = () => (
  event: Event,
  isAuthenticated: boolean,
  isNewEvent: boolean,
) => void;

export const useEventChange: UseEventChange = () => {
  const { setSnackbarMessage, setCelebrationMessage } = useUiContext();

  return (event, isAuthenticated, isNewEvent) => {
    if (isNewEvent) {
      setCelebrationMessage(
        <>
          <Typography variant="h3" color="inherit" gutterBottom>
            Hooray!
          </Typography>
          <Typography variant="h6" color="inherit" gutterBottom>
            You created your event! You can now invite your friends.
          </Typography>
          <Typography variant="h6" color="inherit" gutterBottom>
            You can also change the event by clicking on the{' '}
            <b>event settings</b>.
          </Typography>
        </>,
      );
      ReactGA.event({
        category: 'event',
        action: 'create',
        value: event.id,
      });
    } else {
      setSnackbarMessage('Your event is updated');
      ReactGA.event({
        category: 'event',
        action: 'update',
        value: event.id,
      });
    }

    // send mail to guest
    console.log('NEW EVENT::::', isAuthenticated, event.guest_email, isNewEvent);
    if (!isAuthenticated && event.guest_email && isNewEvent) {
      sendMail('new-guest-event', event.guest_email, {
        event_name: event.title,
        date: format(new Date(event.date), 'PPp'),
        link: `${window.location.origin}/e/${event.code}/${event.admin_code}`,
      });
    }
  };
};
