export const getGameComplexity = (complexity: number): string => {
  switch (true) {
    case complexity < 2:
      return 'Easy';
    case complexity < 3:
      return 'Medium';
    case complexity < 4:
      return 'Hard';
    default:
      return 'Hardcore';
  }
};
