import { makeStyles } from '@material-ui/core/styles';

import { Props } from './types';

export const useStyles = makeStyles(theme => ({
  root: (props: Props) => ({
    position: props.fullscreen
      ? ('fixed' as 'fixed')
      : ('relative' as 'relative'),
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: props.fullscreen
      ? props.variant === 'overlay'
        ? 'transparent'
        : theme.palette.background.paper
      : 'transparent',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  }),
  loader: {
    marginBottom: theme.spacing(4),
  },
}));
