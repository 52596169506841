import { makeStyles, Theme } from '@material-ui/core/styles';

import { StyledProps } from './types';

export const useStyles = makeStyles<Theme, StyledProps>(theme => ({
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    'overscroll-behavior-x': 'none',
  },
  margin: {
    margin: theme.spacing(2),
  },
  fullWidthContainer: {
    flex: 1,
  },
  fullWidthField: {
    width: '100%',
  },
  largeWidthField: {
    width: '100%',
    maxWidth: 400,
  },
  optionsContainer: ({ dialogPadding }) => ({
    padding: `${theme.spacing(1)}px ${dialogPadding}px`,
    margin: `0 -${dialogPadding}px`,
    'overflow-x': 'scroll',
  }),
  optionsWrapper: ({ dialogPadding }) => ({
    flexWrap: 'nowrap',
    '& > .MuiGrid-item:last-child': { paddingRight: dialogPadding },
  }),
  formBlock: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(2),
    },
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    height: 36,
    width: 36,
    borderRadius: '50%',
    color: theme.palette.background.paper,
  },
}));
