import React from 'react';
import ApolloClientProvider from '@gamenight/common/dist/apollo/apollo-client-provider';

import { useAuth0 } from '../../auth/auth';

const ApolloProvider: React.FC = ({ children }) => {
  const graphqlApiUrl = process.env.REACT_APP_GRAPHQL_URL;
  const { getIdToken } = useAuth0();

  return (
    <ApolloClientProvider graphqlApiUrl={graphqlApiUrl} getIdToken={getIdToken}>
      {children}
    </ApolloClientProvider>
  );
};

ApolloProvider.displayName = 'ApolloProvider';

export default ApolloProvider;
