import React from 'react';
import { TextField, Grid, Avatar, Box, Typography } from '@material-ui/core';
import { Group as GroupIcon } from '@material-ui/icons';

import Block from './block';
import { useStyles } from './styles';
import { BlockProps } from './types';
import { PersonsType } from '../../types';

const optionName = 'persons';

const Persons: React.FC<BlockProps<PersonsType>> = props => {
  const { form, errors, activeOptions, setActiveOptions } = props;

  const optionError = activeOptions[optionName] && errors[optionName];

  const classes = useStyles(props);

  const hasValue =
    !!activeOptions[optionName] &&
    (!!form.values[optionName]?.min || !!form.values[optionName]?.max);

  const value: PersonsType = form.values.persons;

  return (
    <Block
      title="Min / max persons"
      description={
        <span>
          Show how many people you want at least and limit the number of
          sign-ups for your event.
        </span>
      }
      optionValue={value}
      optionName={optionName}
      hasValue={hasValue}
      form={form}
      errors={errors}
      cardContent={
        hasValue && !optionError ? (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            {value.min && value.max && (
              <>
                <Avatar
                  className={[classes.avatarMedium, classes.avatarActive].join(
                    ' ',
                  )}
                >
                  {value.min}
                </Avatar>
                <div className={classes.avatarDivider} />
                <Avatar
                  className={[classes.avatarLarge, classes.avatarActive].join(
                    ' ',
                  )}
                >
                  {value.max}
                </Avatar>
              </>
            )}
            {value.min && !value.max && (
              <>
                <Typography
                  variant="h6"
                  color="secondary"
                  className={classes.avatarLabel}
                >
                  Min
                </Typography>
                <Avatar
                  className={[classes.avatarLarge, classes.avatarActive].join(
                    ' ',
                  )}
                >
                  {value.min}
                </Avatar>
              </>
            )}
            {!value.min && value.max && (
              <>
                <Typography
                  variant="h6"
                  color="secondary"
                  className={classes.avatarLabel}
                >
                  Max
                </Typography>
                <Avatar
                  className={[classes.avatarLarge, classes.avatarActive].join(
                    ' ',
                  )}
                >
                  {value.max}
                </Avatar>
              </>
            )}
          </Box>
        ) : (
          <Avatar
            className={[
              classes.avatarLarge,
              !!optionError ? classes.avatarError : '',
            ].join(' ')}
          >
            <GroupIcon color="inherit" />
          </Avatar>
        )
      }
      activeOptions={activeOptions}
      setActiveOptions={setActiveOptions}
    >
      <Grid
        container
        spacing={1}
        alignItems="center"
        className={classes.inputGroup}
      >
        <Grid item>
          <GroupIcon color="inherit" />
        </Grid>
        <Grid item xs>
          <TextField
            className={classes.inputFieldSmall}
            type="number"
            variant="outlined"
            inputProps={{ min: '1', max: '50', step: '1' }}
            value={form.values[optionName]?.min}
            label="Min persons"
            name="min"
            helperText={errors[optionName]?.min}
            error={!!errors[optionName]?.min}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newMin = event.target.value;
              form.setFieldValue(
                'persons',
                {
                  ...form.values.persons,
                  min: newMin,
                },
                true,
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        alignItems="center"
        className={classes.inputGroup}
      >
        <Grid item>
          <GroupIcon color="inherit" />
        </Grid>
        <Grid item xs>
          <TextField
            type="number"
            variant="outlined"
            className={classes.inputFieldSmall}
            inputProps={{ min: '1', max: '50', step: '1' }}
            value={form.values[optionName]?.max}
            label="Max persons"
            name="max"
            helperText={errors[optionName]?.max}
            error={!!errors[optionName]?.max}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.preventDefault();
              const newMax = event.target.value;
              form.setFieldValue(
                'persons',
                {
                  ...form.values[optionName],
                  max: newMax,
                },
                true,
              );
            }}
          />
        </Grid>
      </Grid>
    </Block>
  );
};

Persons.displayName = 'Persons';

export default Persons;
