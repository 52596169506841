import React, { ReactNode } from 'react';
import { MenuItem } from '@material-ui/core';

export const getTimeOptions = (): ReactNode => {
  return [...Array(24)].map((val, index) => {
    const hour = ('0' + index).slice(-2);
    const partsOfDay: Record<string, string> = {
      '00': 'Night',
      '08': 'Morning',
      '12': 'Afternoon',
      '18': 'Evening',
      '22': 'Night',
    };
    const timeOptions =
      hour in partsOfDay
        ? [
            <MenuItem key={partsOfDay[hour]} value="" disabled>
              <em>{partsOfDay[hour]}</em>
            </MenuItem>,
          ]
        : [];
    for (let minutes = 0; minutes <= 45; minutes += 15) {
      const timeStr = `${hour}:${('0' + minutes).slice(-2)}`;
      timeOptions.push(
        <MenuItem key={timeStr} value={timeStr}>
          {timeStr}
        </MenuItem>,
      );
    }
    return timeOptions;
  });
};
