import React from 'react';
import { Typography, Card, CardContent } from '@material-ui/core';

import Layout from '../../components/layout';

const Feedback: React.FC = () => {
  return (
    <Layout>
      <Card>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            Feedback
          </Typography>
          <Typography variant="body1" gutterBottom>
            {/* Do you have some feedback? Great! You can either check out the{' '}
            <a
              href="https://trello.com/b/YiXxn7pW/gamenight-roadmap"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              public roadmap
            </a>{' '}
            for Gamenight and request any feature.
            <br />
            <br /> */}
            You can mail us at{' '}
            <a href="mailto:info@are-you.nl">info@are-you.nl</a>. Any feedback
            is welcome!
          </Typography>
        </CardContent>
      </Card>
    </Layout>
  );
};

Feedback.displayName = 'Feedback';

export default Feedback;
