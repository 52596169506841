import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import debounce from 'lodash.debounce';
import { useUiContext } from '@gamenight/common/dist/hooks';

import { Props, Game, GameSummary } from './types';
import GameDialog from '../game-dialog';
import { getOptionTitle, checkUpdateNeeded, getNewGames } from './helpers';

const functionPath =
  process.env.REACT_APP_FUNCTIONS_PATH || '/.netlify/functions';

const GameSearch: React.FC<Props> = ({
  action,
  onChange,
  userGames,
  initialOptions,
}) => {
  const { setSnackbarMessage } = useUiContext();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<GameSummary[]>(
    initialOptions || [],
  );
  const [loading, setLoading] = React.useState(false);
  const [game, setGame] = React.useState<GameSummary | null>(null);
  const [memoGame, setMemoGame] = React.useState<GameSummary | null>(null);

  const applyUserGamesToOptions = React.useCallback(
    games => {
      if (!userGames) return games;
      return games.map((game: Game) => {
        const gameInUserGames = userGames.find(
          userGame => userGame.bgg_id === game.bgg_id,
        );
        return !!gameInUserGames
          ? {
              ...game,
              isUserGame: true,
              own: gameInUserGames.own,
              favorite: gameInUserGames.favorite,
            }
          : game;
      });
    },
    [userGames],
  );

  const syncBgg = async (q: string) => {
    setLoading(true);
    const response = await fetch(`${functionPath}/sync-bgg-search?q=${q}`);
    const games = await response.json();
    setLoading(false);
    return games;
  };

  const updateOptions = async (q: string) => {
    let json;
    try {
      const response = await fetch(`${functionPath}/search-boardgames?q=${q}`);
      json = await response.json();
    } catch (err) {
      setSnackbarMessage('Sorry - could not load boardgames');
    }
    if (!json) {
      setOpen(false);
      setLoading(false);
      return;
    }

    const {
      data: { game_title: dbItems, bgg_sync },
    } = json;
    let games = dbItems
      .map((item: any) => ({
        id: item.id,
        game_id: item.game_id,
        bgg_id: item.bgg_id,
        title: item.title,
        type: item.type,
        publish_year: item.gameByBggId?.publish_year || null,
      }))
      .sort((a: Game, b: Game) => (a.title.length > b.title.length ? 1 : -1));

    // set options while waiting on any new BGG games

    const syncLastUpdate = bgg_sync?.[0]?.updated_at;

    if (checkUpdateNeeded(syncLastUpdate)) {
      // old / non-existing query in cache: get it from BGG
      try {
        const bggGames = await syncBgg(q);

        // only append any new games
        const newGames = getNewGames(bggGames, games);
        games = [...games, ...newGames].sort((a: Game, b: Game) =>
          a.title.length > b.title.length ? 1 : -1,
        );
      } catch (err) {
        setSnackbarMessage('Sorry - could not load other boardgames');
        console.error(err);
      }
    }

    setOptions(applyUserGamesToOptions(games));
    setLoading(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const q: string = event.target.value;
    setMemoGame(null);
    if (q && q.length > 1) {
      setLoading(true);
      updateOptions(q);
    } else {
      setOptions(initialOptions || []);
      setLoading(false);
    }
  };

  const debouncedHandleChange = debounce(handleChange, 500);

  const handleClick = () => {
    if (memoGame && open) {
      setGame(memoGame);
    }
  };

  return (
    <>
      <Autocomplete
        id="search-boardgames"
        noOptionsText="No boardgames found"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(
          event: React.ChangeEvent<{}>,
          newValue: GameSummary | null,
        ) => {
          setGame(newValue);
          setMemoGame(newValue);
          if (onChange) {
            onChange(newValue);
          }
        }}
        getOptionSelected={(option, value) => option.title === value.title}
        getOptionLabel={option => option.title}
        options={options}
        loading={loading}
        renderOption={getOptionTitle}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            label="Search boardgames"
            variant="outlined"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.persist();
              debouncedHandleChange(event);
            }}
            onClick={handleClick}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {game && (
        <GameDialog
          gameOrSummary={game}
          action={action}
          onClose={() => {
            setGame(null);
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

GameSearch.displayName = 'GameSearch';

export default GameSearch;
