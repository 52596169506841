import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core';
import {
  Group as GroupIcon,
  LocationOn as LocationOnIcon,
  Description as DescriptionIcon,
  LocalMall as BringYourOwnIcon,
  Public as PublicIcon,
  Casino as CasinoIcon,
} from '@material-ui/icons';
import { EVENT_STATUS } from '@gamenight/common/dist/types';

import Deadline from './deadline';
import EventGameList from './event-game-list';
import { Props } from './types';
import { useStyles } from './styles';
import { getAttendingCount } from '../../../helpers';
import Bullet from '../../ui/bullet';
import ExpandableText from '../../ui/expandable-text';

const Details: React.FC<Props> = props => {
  const classes = useStyles(props);
  const { event, userAttendance, userIsAdmin } = props;
  // TODO: fix invalid prop for children: 05ev22

  const attendingCount = getAttendingCount(event);

  const personsMissing = (event.options?.persons?.min || 0) - attendingCount;

  const bringYourOwnArray = (event.options?.bringYourOwn?.options || '')
    .split('|')
    .filter(Boolean)
    .map(item =>
      // TODO: map 'item' to nice (translatable) string
      item !== 'other' ? item : event.options?.bringYourOwn?.other || '',
    );

  return (
    <>
      <List>
        {event.description && (
          <>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <Box className={classes.iconContainer}>
                  <DescriptionIcon />
                </Box>
              </ListItemIcon>
              <ListItemText>
                <ExpandableText text={event.description} />
              </ListItemText>
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        )}
        <>
          <ListItem className={classes.listItem}>
            <ListItemIcon>
              <Box className={classes.iconContainer}>
                <GroupIcon />
              </Box>
            </ListItemIcon>
            <ListItemText>
              {event.status === EVENT_STATUS.LOCKED && (
                <Typography variant="subtitle2" color="textSecondary">
                  This event is locked; people can no longer sign up.
                </Typography>
              )}
              {event.status === EVENT_STATUS.CANCELED && (
                <Typography variant="subtitle2" color="textSecondary">
                  This event is canceled; people can no longer sign up.
                </Typography>
              )}
              {event.options?.persons?.min && (
                <Typography variant="body1" gutterBottom>
                  Min: {event.options.persons.min}{' '}
                </Typography>
              )}
              {event.options?.persons?.max && (
                <Typography variant="body1" gutterBottom>
                  Max: {event.options.persons.max}
                </Typography>
              )}
              <Typography variant="body1" gutterBottom>
                Attending: {attendingCount}{' '}
                {personsMissing > 0 && event.status === EVENT_STATUS.ACTIVE && (
                  <strong>(we need {personsMissing} more)</strong>
                )}
              </Typography>
              {!!event.options?.deadline?.date &&
                event.status === EVENT_STATUS.ACTIVE && (
                  <Deadline event={event} userAttendance={userAttendance} />
                )}
            </ListItemText>
          </ListItem>
          <Divider variant="inset" component="li" />
        </>
        {event.options?.public?.share && (
          <>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <Box className={classes.iconContainer}>
                  <PublicIcon />
                </Box>
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body1">
                  Public event
                  <Bullet />
                  <Typography
                    component="span"
                    variant="body2"
                    color="textSecondary"
                  >
                    {userIsAdmin
                      ? 'friends can invite other friends'
                      : 'you can invite your friends'}
                  </Typography>
                </Typography>
              </ListItemText>
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        )}
        {bringYourOwnArray.length > 0 && (
          <>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <Box className={classes.iconContainer}>
                  <BringYourOwnIcon />
                </Box>
              </ListItemIcon>
              <ListItemText>
                <Typography variant="subtitle2" color="textSecondary">
                  It{<span>&rsquo;</span>}d be super if everybody could bring
                  some:{' '}
                </Typography>
                <Typography variant="body1">
                  {bringYourOwnArray.join(', ')}
                </Typography>
              </ListItemText>
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        )}
        {event.options?.location?.location && (
          <>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <Box className={classes.iconContainer}>
                  <LocationOnIcon />
                </Box>
              </ListItemIcon>
              <ListItemText>
                <ExpandableText text={event.options.location.location} />
              </ListItemText>
            </ListItem>
          </>
        )}
        {event.options?.boardgames?.gameList &&
          event.options.boardgames.gameList.length > 0 && (
            <>
              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <Box className={classes.iconContainer}>
                    <CasinoIcon />
                  </Box>
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="subtitle2" color="textSecondary">
                    Boardgames on the menu:
                  </Typography>
                  <br />
                  <EventGameList
                    gameTitleIdList={event.options.boardgames.gameList}
                  />
                </ListItemText>
              </ListItem>
            </>
          )}
        {!event.description &&
          (!event.options ||
            (event.options && Object.entries(event.options).length === 0 && (
              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <Box className={classes.iconContainer}>
                    <DescriptionIcon />
                  </Box>
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body1" color="textSecondary">
                    No extra information available.
                  </Typography>
                </ListItemText>
              </ListItem>
            )))}
      </List>
    </>
  );
};

Details.displayName = 'Details';

export default Details;
