import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  listItem: {
    alignItems: 'flex-start',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    height: 36,
    width: 36,
    borderRadius: '50%',
    color: theme.palette.background.paper,
  },
}));
