import { makeStyles } from '@material-ui/core/styles';

import { StyledCardImage } from './types';

export const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  cardLink: {
    textDecoration: 'none',
  },
  cardImage: (props: StyledCardImage) => ({
    position: 'relative' as 'relative',
    padding: `${theme.spacing(2)}px 0`,
    color: 'white',
    background: `linear-gradient(100deg, ${props.color1} 0%, ${props.color2} 100%)`,
  }),
  headerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: `linear-gradient(0deg, rgba(118,179,157,0.1) 0%, rgba(118,179,157,0.6) 100%)`,
  },
  headerContainer: {
    position: 'relative' as 'relative',
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  eventIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  notAttendingIcon: {
    marginRight: theme.spacing(2),
  },
}));
