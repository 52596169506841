import React from 'react';

import { Props } from './types';

const ConditionalWrapper: React.FC<Props> = ({
  condition,
  wrapper,
  elseWrapper,
  children,
}) =>
  condition
    ? wrapper(children)
    : !!elseWrapper
    ? elseWrapper(children)
    : children;

ConditionalWrapper.displayName = 'ConditionalWrapper';

export default ConditionalWrapper;
