import React from 'react';
import { Typography, Button, ButtonGroup } from '@material-ui/core';
import { EVENT_STATUS } from '@gamenight/common/dist/types';

import { Props } from './types';
import { useStyles } from './styles';

const AttendanceButtons: React.FC<Props> = props => {
  const classes = useStyles(props);
  const { event, attending, handleClick, loading } = props;

  return (
    <>
      <ButtonGroup
        disabled={loading}
        aria-label="contained button group"
        className={classes.root}
      >
        <Button
          className={[classes.buttonYes, classes.button].join(' ')}
          onClick={() => handleClick(true)}
          variant={attending === true ? 'contained' : 'outlined'}
          disabled={event.status !== EVENT_STATUS.ACTIVE}
        >
          Jep!
        </Button>
        <Button
          className={[classes.buttonNo, classes.button].join(' ')}
          onClick={() => handleClick(false)}
          variant={attending === false ? 'contained' : 'outlined'}
        >
          Nope
        </Button>
      </ButtonGroup>

      {event.status !== EVENT_STATUS.ACTIVE && !attending && (
        <>
          <br />
          <Typography variant="caption">
            You can no longer sign up for locked or canceled events
          </Typography>
        </>
      )}
      {event.status !== EVENT_STATUS.ACTIVE && attending && (
        <>
          <br />
          <Typography variant="caption">
            WARNING: when you sign out for this event, you can not sign up again
          </Typography>
        </>
      )}
    </>
  );
};

AttendanceButtons.displayName = 'AttendanceButtons';

export default AttendanceButtons;
