import { format } from 'date-fns';
import { isValidDate } from './is-valid-date';

// default, difference to now, time, date, weekday
export const formatDate = (
  date: Date | string | null | undefined,
  formatting = 'default',
) => {
  if (!date) return '-';
  const parsedDate = isValidDate(date) ? date : new Date(date);

  switch (formatting) {
    case 'date':
      return format(parsedDate, 'PP');
    default:
      return format(parsedDate, 'PPp');
  }
};
