import React, { ReactElement } from 'react';
import { RenderOptionState } from '@material-ui/lab/Autocomplete';
import {
  CheckCircleOutline as NotInCollectionIcon,
  CheckCircle as InCollectionIcon,
  Favorite as FavoriteIcon,
} from '@material-ui/icons';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { GameSummary } from '@gamenight/common/dist/types';

interface Part {
  text: string;
  highlight: boolean;
}

type GetOptionTitle = (
  game: GameSummary,
  state: RenderOptionState,
) => ReactElement;

export const getOptionTitle: GetOptionTitle = (game, { inputValue }) => {
  if (!game || !game.title) {
    throw new Error('invalid boardgame option');
  }

  const matches = match(game.title, inputValue);
  const parts = parse(game.title, matches);

  const isUserGameStyles: any = {
    color: '#aaa',
    marginRight: 8,
    verticalAlign: 'middle',
  };

  const isFavoriteStyles: any = {
    verticalAlign: 'middle',
    marginLeft: 8,
  };

  return (
    <div>
      {game.isUserGame && game.own && (
        <InCollectionIcon style={isUserGameStyles} />
      )}
      {game.isUserGame && !game.own && (
        <NotInCollectionIcon style={isUserGameStyles} />
      )}
      {parts.map((part: Part, index: number) => (
        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
          {part.text}
        </span>
      ))}
      {game.publish_year && (
        <span
          style={{
            color: '#aaa',
            fontSize: 12,
            verticalAlign: 'center',
            marginLeft: 8,
          }}
        >
          ({game.publish_year})
        </span>
      )}
      {game.isUserGame && game.favorite && (
        <FavoriteIcon color="primary" style={isFavoriteStyles} />
      )}
    </div>
  );
};
