import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 1,
  },
  confetti: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: theme.zIndex.snackbar + 100,
  },
  celebration: {
    top: '50%',
    bottom: 'auto',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
    maxWidth: '90%',
    width: 400,
    minHeight: 200,

    '& > div': {
      alignItems: 'flex-start',
      backgroundColor: theme.palette.primary.main,
      borderRadius: 20,
      color: theme.palette.text.primary,
      maxWidth: '100%',
      width: 400,
      minHeight: 300,
    },
    '& .MuiSnackbarContent-message': {
      flex: 1,
    },
  },
  celebrationLogo: {
    position: 'absolute',
    zIndex: 0,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    '& g': {
      fill: theme.palette.primary.light,
    },
  },
  messageContainer: {
    padding: theme.spacing(2),
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.background.paper,
    zIndex: 1,
  },
}));
