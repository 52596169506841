import { Event } from '@gamenight/common/dist/types';
import { formatISO, addHours } from 'date-fns';

// get Google Calendar link
export const getCalendarLink = (event: Event): string => {
  const endDateStr = event.options?.duration?.date
    ? `${formatISO(new Date(event.options.duration.date), { format: 'basic' })}`
    : `${formatISO(addHours(new Date(event.date), 2), { format: 'basic' })}`;

  return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURI(
    event.title,
  )}&dates=${formatISO(new Date(event.date), {
    format: 'basic',
  })}/${endDateStr}&details=${encodeURI(
    `Gamenight link: ${window.location.href}\n\n${
      event.description ? event.description : ''
    }`,
  )}&location=${
    event.options?.location?.location
      ? encodeURI(
          event.options.location.location.replace(/(?:\r\n|\r|\n)/g, ' '),
        )
      : ''
  }&sprop=website:${window.location.href}&sprop=name:Gamenight.nu`;
};
