import React from 'react';
import { Container, Snackbar, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useUiContext } from '@gamenight/common/dist/hooks/ui-context';
import { Helmet } from 'react-helmet';

import { useStyles } from './styles';
import { Props } from './types';
import LayoutHeader from './header';
import LayoutDrawer from './drawer';
import LayoutFooter from './footer';
import Celebration from './celebration';

const Layout: React.FC<Props> = ({ children, variant }) => {
  const classes = useStyles();
  const [showDrawer, setShowDrawer] = React.useState(false);
  const {
    snackbarMessage,
    setSnackbarMessage,
    celebrationMessage,
    setCelebrationMessage,
  } = useUiContext();

  const handleMenuClick = () => {
    setShowDrawer(!showDrawer);
  };

  const handleSnackbarClose = () => {
    setSnackbarMessage('');
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Gamenight - plan your perfect boardgame night</title>
        {window.location.origin.indexOf('gamenight-dev') > -1 && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <LayoutHeader
        handleMenuClick={handleMenuClick}
        showDrawer={showDrawer}
        variant={variant}
      />
      <LayoutDrawer showDrawer={showDrawer} />
      {variant === 'event' ? (
        <main className={classes.mainEvent}>{children}</main>
      ) : (
        <Container component="main" className={classes.main} maxWidth="md">
          {children}
        </Container>
      )}
      <LayoutFooter />
      <Celebration
        celebrationMessage={celebrationMessage}
        setCelebrationMessage={setCelebrationMessage}
      />
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </div>
  );
};

Layout.displayName = 'Layout';

export default Layout;
