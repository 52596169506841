import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  backgroundLogo: {
    position: 'absolute' as 'absolute',
    top: 0,
    right: -20,
    zIndex: -1,
    '& g': {
      fill: '#E1ECE8', //theme.palette.background.paper,
    },
  },
  cta: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    transform: 'scale(1.4)',
  },
  usps: {
    textTransform: 'uppercase',
    letterSpacing: 0.35,
  },
}));
