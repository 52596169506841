import { Event, Attendance } from '@gamenight/common/dist/types';

const isGuestAttendance = (attendance: Attendance) =>
  !!(attendance.guest_email && attendance.guest_name);
const isUserAttendance = (attendance: Attendance) =>
  !!(attendance.user && attendance.user.id);

export const getAttendees = (event: Event): Attendance[] => {
  if (!event || !event.attendees) return [];

  return event.attendees.filter(
    item => isGuestAttendance(item) || isUserAttendance(item),
  );
};
