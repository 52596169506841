import React from 'react';
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { Person as PersonIcon } from '@material-ui/icons';

import PersonAvatar from '../person-avatar';
import AvatarBadge from '../ui/avatar-badge';
import ConditionalWrapper from '../conditional-wrapper';
import { Props } from './types';
import { useStyles } from './styles';

const PersonItem: React.FC<Props> = props => {
  const classes = useStyles();
  const {
    itemWithPerson,
    primaryText,
    secondaryText,
    secondaryAction,
    onClick,
  } = props;

  const userAccount = itemWithPerson.user;

  const primary = primaryText
    ? primaryText
    : itemWithPerson.guest_name || itemWithPerson.user?.name;

  return (
    <ConditionalWrapper
      wrapper={children => (
        <div
          className={classes.clickableAvatar}
          onClick={
            onClick && userAccount ? () => onClick(userAccount) : undefined
          }
        >
          {children}
        </div>
      )}
      condition={!!onClick && !!userAccount}
    >
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <ConditionalWrapper
            wrapper={children => (
              <AvatarBadge badge={<PersonIcon />}>{children}</AvatarBadge>
            )}
            condition={!!itemWithPerson.user}
          >
            <PersonAvatar {...itemWithPerson} />
          </ConditionalWrapper>
        </ListItemAvatar>
        <ListItemText primary={primary} secondary={secondaryText} />
        {secondaryAction && (
          <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>
        )}
      </ListItem>
    </ConditionalWrapper>
  );
};

PersonItem.displayName = 'PersonItem';

export default PersonItem;
