import { Game } from '@gamenight/common/dist/types';

export const normalizeGame = (gameTitle: any): Game => {
  if (!gameTitle || !gameTitle.id) {
    throw new Error('Could not normalize invalid gameTitle object');
  }
  return {
    id: gameTitle.gameByBggId?.id,
    gameTitleId: gameTitle.id,
    title: gameTitle.title,
    publish_year: gameTitle.gameByBggId?.publish_year,
    thumbnail: gameTitle.gameByBggId?.thumbnail,
    image: gameTitle.gameByBggId?.image,
    players_min: gameTitle.gameByBggId?.players_min,
    players_max: gameTitle.gameByBggId?.players_max,
    playtime_min: gameTitle.gameByBggId?.playtime_min,
    playtime_max: gameTitle.gameByBggId?.playtime_max,
    complexity: gameTitle.gameByBggId?.complexity,
    description: gameTitle.gameByBggId?.description,
    bgg_id: gameTitle.gameByBggId?.bgg_id,
    primaryGameTitleId: gameTitle.gameByBggId?.gameTitlesByBggId?.id,
    primaryTitle: gameTitle.gameByBggId?.gameTitlesByBggId?.title,
  };
};
