import React from 'react';
import { Box, Card, CardHeader, CardContent } from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import { motion } from 'framer-motion';

import { Props } from './types';
import { useStyles } from './styles';

const CtaBlock: React.FC<Props> = props => {
  const { startExpanded, title, children } = props;
  const [expanded, setExpanded] = React.useState(!!startExpanded);
  const classes = useStyles({
    expanded,
  });

  const variants = {
    open: { opacity: 1, height: 'auto' },
    closed: { opacity: 0, height: 0 },
  };

  return (
    <Card className={classes.ctaBlock}>
      <CardHeader
        className={classes.header}
        disableTypography
        onClick={() => setExpanded(!expanded)}
        title={
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {title}
            {/* <Typography variant="subtitle1" color="inherit">
              Invite your friends
            </Typography> */}
            {expanded ? (
              <ExpandMoreIcon className={classes.closeButton} />
            ) : (
              <ExpandLessIcon className={classes.closeButton} />
            )}
          </Box>
        }
      />
      <motion.div
        animate={expanded ? 'open' : 'closed'}
        variants={variants}
        initial="open"
      >
        <CardContent className={classes.cardContent}>{children}</CardContent>
      </motion.div>
    </Card>
  );
};

CtaBlock.displayName = 'CtaBlock';

export default CtaBlock;
