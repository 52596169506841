import React from 'react';
import {
  Box,
  Paper,
  AppBar,
  Link,
  Typography,
  Toolbar,
  Button,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Chip,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
} from '@material-ui/icons';
import { useUiContext } from '@gamenight/common/dist/hooks/ui-context';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

import Logo from '../../logo';
import { useAuth0 } from '../../../auth';
import { Props } from './types';
import { useStyles } from './styles';

const LayoutHeader: React.FC<Props> = props => {
  const theme = useTheme();
  const { push } = useHistory();
  const classes = useStyles(props);
  const { loginWithPopup, isAuthenticated, user, logout } = useAuth0();
  const [
    accountOptionsEl,
    setaccountOptionsEl,
  ] = React.useState<Element | null>(null);
  const { homeTab, setHomeTab, setSnackbarMessage } = useUiContext();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setHomeTab(newValue);
  };

  const { handleMenuClick, showDrawer, variant } = props;

  const isEvent = variant === 'event';
  const isHome = variant === 'home';

  const handleAccountClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ): void => {
    setaccountOptionsEl(event.currentTarget as Element);
  };

  const handleAccountClose = (): void => {
    setaccountOptionsEl(null);
  };

  const handleLogin = async (): Promise<void> => {
    setSnackbarMessage('Logging in...');
    await loginWithPopup();
  };

  const handleLogout = async (): Promise<void> => {
    handleAccountClose();
    setSnackbarMessage('Logging out...');
    await logout();
  };

  return (
    <>
      <AppBar
        position={isEvent ? 'relative' : 'fixed'}
        className={classes.appBar}
        elevation={isEvent || isHome ? 0 : 16}
      >
        <Toolbar>
          {isAuthenticated && !isEvent && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleMenuClick}
            >
              {showDrawer ? <ArrowBackIcon /> : <MenuIcon />}
            </IconButton>
          )}

          {isAuthenticated && isEvent && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="back"
              onClick={() => push('/')}
            >
              {<ArrowBackIcon />}
            </IconButton>
          )}

          <Link href="/" underline="none">
            <Box display="flex" flexDirection="row" alignItems="center">
              <Logo
                fill={theme.palette.background.paper}
                className={classes.logo}
              />
              {!isEvent && (
                <>
                  <Typography
                    variant="h4"
                    component="h2"
                    className={classes.title}
                  >
                    Gamenight
                  </Typography>
                  <Chip
                    color="secondary"
                    label="BETA"
                    className={classes.betaLabel}
                  />
                </>
              )}
            </Box>
          </Link>

          <Box key="spacing" flex="1" />

          {isAuthenticated && user ? (
            <>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={handleAccountClick}
                color="inherit"
                href="#"
              >
                <Avatar
                  alt={`Profile Picture of ${user.nickname}`}
                  src={user.picture}
                />
              </IconButton>
              <Menu
                id="account-actions"
                anchorEl={accountOptionsEl}
                keepMounted
                open={Boolean(accountOptionsEl)}
                onClose={handleAccountClose}
              >
                <MenuItem component="a" href="/account">
                  My account
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          ) : (
            <Button color="inherit" onClick={handleLogin}>
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>{' '}
      <div className={classes.toolbar} />
      {isHome && (
        <Paper elevation={6} square>
          <Box maxWidth="sm" className={classes.tabsContainer}>
            {' '}
            <Tabs
              aria-label="events tabs"
              value={homeTab}
              onChange={handleChange}
              centered
              variant="fullWidth"
            >
              <Tab label="Current" />
              <Tab label="Archive" />
            </Tabs>
          </Box>
        </Paper>
      )}
    </>
  );
};

LayoutHeader.displayName = 'LayoutHeader';

export default LayoutHeader;
