import { Event } from '@gamenight/common/dist/types';
import { format } from 'date-fns';

type Medium = 'whatsapp' | 'mail' | 'sms' | 'link';

interface Message {
  title?: string;
  body: string;
}

type ShareMessages = Record<Medium, Message>;

export const getShareMessages = (
  event: Event,
  // forwardName?: string,
): ShareMessages => {
  const {
    title,
    // description,
    date,
    code,
    options,
    user,
    guest_name,
  } = event;
  const maxPersons = options?.persons?.max;
  const minPersons = options?.persons?.min;
  // const location = options?.location?.location;
  const organiserName = user?.name || guest_name;

  const dateFormatted = format(new Date(date), 'PPPp');

  const smsBody = encodeURI(
    `You are invited to ${title} (on ${dateFormatted}).\n\nGo to ${window.location.origin}/e/${code} to let ${organiserName} know if you're in.`,
  );

  const whatsAppBody = encodeURI(
    `You are invited to *${title}* on *${dateFormatted}*.\n\n_Go to ${window.location.origin}/e/${code} to let ${organiserName} know if you're in._`,
  );
  const mailTitle = encodeURI(`Come to ${title} on ${dateFormatted}`);
  const mailBody = encodeURI(`Hi!\nYou are invited!\nGo to ${
    window.location.origin
  }/e/${code} to let ${organiserName} know if you're in.
  \nEVENT NAME: ${title}
DATE/TIME: ${dateFormatted}
${organiserName && `ORGANISER: ${organiserName}`}
${minPersons && `MIN PERSONS: ${minPersons}`}${maxPersons &&
    `\nMAX PERSONS: ${maxPersons}`}
    \nCheck out any other details at ${window.location.origin}/e/${code}.`);
  // ${location && `\nLOCATION:\n${location}`}
  // ${description && `\nEXTRA INFO:\n${description}`}`

  return {
    whatsapp: {
      title: undefined,
      body: whatsAppBody,
    },
    mail: {
      title: mailTitle,
      body: mailBody,
    },
    sms: {
      title: undefined,
      body: smsBody,
    },
    link: {
      title: undefined,
      body: `${window.location.origin}/e/${code}`,
    },
  };
};
