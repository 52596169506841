import React from 'react';
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  Container,
  Box,
  Divider,
} from '@material-ui/core';
import { Cancel as CancelIcon, Event as EventIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import generateColorHash from 'material-color-hash';
import { format, formatDistanceToNow, isPast } from 'date-fns';

import { useAuth0 } from '../../auth';
import PersonAvatar from '../person-avatar';
import AttendeesGroup from '../attendees-group';
import { Props } from './types';
import { useStyles } from './styles';

const EventItem: React.FC<Props> = ({ event }) => {
  const { isAuthenticated, user } = useAuth0();

  const hue = isPast(new Date(event.date)) ? 200 : 500;
  const color1 = generateColorHash(event.code, hue);
  const color2 = generateColorHash(
    event.admin_code || `${event.code}_gradient`,
    hue,
  );

  const classes = useStyles({
    color1: color1.backgroundColor,
    color2: color2.backgroundColor,
  });
  const dateFormatted = format(new Date(event.date), 'PPp');
  const maxPersons = event.options?.persons?.max;
  const isNotAttending =
    isAuthenticated &&
    event.attendees.find(
      item => item.user_id === user?.sub && !item.attendance,
    );
  const isAttending =
    isAuthenticated &&
    event.attendees.find(
      item => item.user_id === user?.sub && !!item.attendance,
    );
  const attendeesYes = event.attendees.filter(
    attendance => !!attendance.attendance,
  );

  return (
    <Link to={`/e/${event.code}`} className={classes.cardLink}>
      <Card className={classes.card}>
        <CardMedia className={classes.cardImage} title={event.title}>
          <div className={classes.headerOverlay} />
          <Container className={classes.headerContainer}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h3" component="h3">
                  {event.title}
                </Typography>
                <Typography variant="subtitle1">
                  {formatDistanceToNow(new Date(event.date), {
                    addSuffix: true,
                  })}
                </Typography>
              </Box>
              <PersonAvatar
                user={event.user}
                guest_name={event.guest_name}
                code={event.code}
              />
            </Box>
          </Container>
        </CardMedia>
        <CardContent>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <EventIcon color="inherit" className={classes.eventIcon} />
            <Typography variant="subtitle2" color="textSecondary">
              {dateFormatted}
            </Typography>
          </Box>
          <Divider className={classes.divider} />
          <Typography variant="subtitle2" color="textSecondary">
            {attendeesYes.length} attending
            {isAttending && attendeesYes.length > 1 && ', including you'}
            {maxPersons && ` (max ${maxPersons} people)`}
          </Typography>
          {attendeesYes.length ? (
            <AttendeesGroup attendees={attendeesYes} />
          ) : (
            <Typography variant="body2">
              <br />
              <span aria-label="no attendees" role="img">
                🙁
              </span>{' '}
              No attendees registered.
            </Typography>
          )}
          {isNotAttending && (
            <>
              <Divider className={classes.divider} />
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <CancelIcon
                  color="error"
                  className={classes.notAttendingIcon}
                />
                <Typography variant="subtitle2" color="textSecondary">
                  You are not there
                </Typography>
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    </Link>
  );
};

EventItem.displayName = 'EventItem';

export default EventItem;
