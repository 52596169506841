import React from 'react';
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import Layout from '../../components/layout';
import { useStyles } from './styles';

const Faq: React.FC = () => {
  const classes = useStyles();

  return (
    <Layout>
      <Typography variant="h2" gutterBottom>
        Frequently asked questions
      </Typography>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            What is Gamenight?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            This is a tool to plan a gamenight (or some other event). You invite
            your friends, and easily keep track of who is coming.
            <br />
            <br />
            It's free and you don't even need an account to use it.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>How does it work?</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Easy peasy, lemon squeezy:
            <br />
            <ol>
              <li>
                Decide on a good date for a gamenight (maybe check with a friend
                up first)
              </li>
              <li>
                Create an event and put in all the details (like max or min
                players, what to bring). We'll make a special <b>event-link</b>{' '}
                for you.
              </li>
              <li>
                Share your event to your friends. (Pro-tip: share it with your
                WhatsApp gaming-groups)
              </li>
              <li>See who of your friends is attending</li>
              <li>Don't want any more people? Lock the event</li>
              <li>Have a great gamenight! (or cancel it if needed)</li>
            </ol>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Why is Gamenight in BETA?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography variant="body1" gutterBottom>
            We are working hard to give you a briljant experience with
            Gamenight. Right now we are fishing out any bugs and finishing some
            must-have features, before we <em>really</em> launch.
            <br />
            <br />
            So thank you for checking out the Beta! If you come across some
            issues, or if you want to weigh in on the features & decisions,
            check out our{' '}
            <a
              href="https://trello.com/b/YiXxn7pW/gamenight-roadmap"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              public roadmap
            </a>{' '}
            on Trello. Or get in touch directly with{' '}
            <a href="mailto:info@are-you.nl">info@are-you.nl</a>.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            How can I invite people?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            After creating an event, you'll see a big orange banner with some
            options for inviting people:
            <ul>
              <li>via WhatsApp</li>
              <li>via mail</li>
              <li>via SMS (when you're on a smartphone)</li>
              <li>with an event link</li>
            </ul>
            The event link is handy for your message-medium of choice: just copy
            the link and paste it into Facebook messenger, Signal, Telegram or
            what have you.
            <br />
            <br />
            You can allow your friends to invite their friends as well.
            Project-X the hell out of your event and set your event as{' '}
            <b>public</b> in the extra options.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            What if too many people sign up for my gamenight?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            When you create an event, you can set an amount for{' '}
            <b>max persons</b>. When you reach this number of attendees, other
            people can no longer sign up.
            <br />
            Max persons is especially handy if you decide to make your event{' '}
            <b>public</b> (allow your friends to invite others)
            <br />
            <br />
            If you have a nice group at a moment in time, you can also{' '}
            <b>lock</b> your event to prevent any new sign-ups.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            What if too few people sign up?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            You can try a couple of things to get enough people for your event:
            <ul>
              <li>
                Set a <b>deadline</b>. People absolutely love deadlines. And it
                creates a sense of urgency for people to sign up.
              </li>
              <li>
                Set a <b>min persons</b> amount. Let people know: when you
                snooze, you lose
              </li>
              <li>
                Make your event <b>public</b>: allow your friends to share the
                event and invite others.
              </li>
            </ul>
            <br />
            Did the above not work? Then you can decide to cancel the event. Or
            just postpone it: go to your <b>Event settings</b>, edit your event
            and notify the attendees.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            How can I lock my event (stop sign-ups from new people)?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            You can lock an event (which prevents other people from signing up)
            by checking out the <b>Event settings</b> (visible under the 'posted
            by' info block).
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Can I cancel an event?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Yes, via the <b>Event settings</b> (see the 'posted by' info block
            on the event). You will be prompted to write a message to all
            attendees, because they will want to know this.
            <br />
            You can also just cancel it and notify the people on your own.{' '}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Can I write a message to all attendees?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Sure thing!
            <br />
            Check out the <b>Notify</b> option in the <b>Event settings</b> (see
            the 'posted by' info block on the event).
            <br />
            <br />
            Don't spam your friends though, so just use it for important stuff,
            like a change in location, time or date.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Is my location visible for everybody?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Yes, anybody with the event-link can view your address if you wrote
            it down.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            When can I use (... some feature ...)?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Currently we are working on new features. To see what is up, to vote
            for features or suggest new ones, visit our{' '}
            <a
              href="https://trello.com/b/YiXxn7pW/gamenight-roadmap"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              public roadmap
            </a>{' '}
            on Trello. Or get in touch directly with{' '}
            <a href="mailto:info@are-you.nl">info@are-you.nl</a>.<br />
            <br />
            All feedback is welcome!
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Layout>
  );
};

Faq.displayName = 'Faq';

export default Faq;
