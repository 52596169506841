import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { useAuth0, deleteUser } from '../../../auth';
import LoadingScreen from '../../../components/loading-screen';
import { DeleteDialogProps } from './types';

const DeleteDialog: React.FC<DeleteDialogProps> = props => {
  const { open, closeDialog } = props;
  const [feedback, setFeedback] = React.useState('');
  const { logout, getIdToken } = useAuth0();
  const [loading, setLoading] = React.useState(false);
  const { replace } = useHistory();

  const deleteAccount = async () => {
    const token = await getIdToken();

    setLoading(true);
    if (token) {
      // delete from db + auth0
      await deleteUser(feedback, token)
        .then(() => {
          logout();
          setLoading(false);
          replace('/?action=delete_user');
        })
        .catch(err => {
          // TODO: Sentry
          console.log(err);
        });
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to delete your account?
      </DialogTitle>
      <DialogContent>
        {loading && <LoadingScreen variant="overlay" fullscreen />}
        <DialogContentText id="alert-dialog-description">
          We like to improve Gamenight where we can. Do you want to tell us why
          you decided to delete your account? Or do you have any other questions
          or feedback?
        </DialogContentText>
        <br />
        <TextField
          fullWidth
          autoFocus
          multiline
          rows={4}
          rowsMax={8}
          variant="outlined"
          id="input-feedback"
          type="text"
          label="Feedback"
          name="feedback"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setFeedback(event.target.value)
          }
          value={feedback}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeDialog}
          color="secondary"
          variant="outlined"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={deleteAccount}
          color="secondary"
          variant="contained"
          disabled={loading}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteDialog.displayName = 'DeleteDialog';

export default DeleteDialog;
