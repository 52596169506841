const functionPath = '/.netlify/functions/delete-user';

export const deleteUser = async (message: string, token: string) => {
  const params = new URLSearchParams({
    message,
    token,
  });
  const url = `${window.location.origin}${functionPath}`;

  const response = await fetch(url, {
    method: 'POST',
    body: params,
  });
  if (response.ok) {
    // Need any response?
    // const text = await response.text();
    // return text;
  } else {
    throw new Error(response.statusText);
  }
};
