import React from 'react';
import { Card, CardContent, Box, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { Props } from './types';

const InfoBlock: React.FC<Props> = props => {
  const classes = useStyles(props);

  const { children, corner, backgroundIcon } = props;

  return (
    <Card className={classes.root}>
      {!!corner && (
        <Box boxShadow={2} className={classes.corner}>
          <Typography variant="h3" color="secondary">
            {corner}
          </Typography>
        </Box>
      )}
      <CardContent className={classes.content}>
        <Box zIndex={1} position="relative">
          {children}
        </Box>
        {!!backgroundIcon && (
          <div className={classes.backgroundIcon}>{backgroundIcon}</div>
        )}
      </CardContent>
    </Card>
  );
};

InfoBlock.displayName = 'InfoBlock';

export default InfoBlock;
