import React from 'react';
import { Typography } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { GET_GAMES_BY_GAME_TITLE_IDS } from '@gamenight/common/dist/queries';
import * as Sentry from '@sentry/browser';

import { EventGameListProps } from '../types';
import LoadingScreen from '../../../loading-screen';
import GameList from '../../../game-list';
import { normalizeGame, sortGames } from '../../../../helpers';

const EventGameList: React.FC<EventGameListProps> = props => {
  const { gameTitleIdList } = props;
  const { data, loading, error } = useQuery(GET_GAMES_BY_GAME_TITLE_IDS, {
    variables: { gameTitleIds: gameTitleIdList },
  });

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    Sentry.captureException(
      `Failed to load event games ${gameTitleIdList.toString()}: ${error.toString()}`,
    );
    console.log(error);
    return (
      <Typography variant="body2" color="textSecondary">
        Sorry - could not load the games...
      </Typography>
    );
  }

  const eventGames = data
    ? sortGames(data.game_title.map(normalizeGame), gameTitleIdList)
    : [];

  return <GameList games={eventGames} hideHeader />;
};

EventGameList.displayName = 'EventGameList';

export default EventGameList;
