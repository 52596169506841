import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  bullet: {
    display: 'none',
    margin: '0 8px',
    transform: 'scale(0.8)',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
    },
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: theme.palette.background.default,
    filter: 'brightness(85%)',
    paddingBottom: theme.spacing(6),
  },
  footerLinks: {
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  footerLink: {
    color: '#4a3916',
    marginBottom: theme.spacing(1),
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
  footerLogo: {
    marginBottom: theme.spacing(3),
    '& g': {
      fill: '#4a3916',
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
      marginRight: theme.spacing(2),
    },
  },
}));
