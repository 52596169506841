import {
  getHours,
  setHours,
  differenceInDays,
  differenceInHours,
} from 'date-fns';

export const getDefaultDeadline = (eventDate: Date): Date => {
  const eventHour = getHours(eventDate);
  if (
    differenceInDays(eventDate, new Date()) > 0 ||
    differenceInHours(eventDate, new Date()) > 8
  ) {
    return setHours(eventDate, eventHour - 8);
  } else {
    return setHours(eventDate, eventHour - 1);
  }
};
