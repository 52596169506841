import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  avatarGroup: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(1),

    '& > *': { border: '2px solid white' },
    '& > * + *': { marginLeft: -10 },
  },
}));
