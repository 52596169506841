import React from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Card,
  CardContent,
  Divider,
  IconButton,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from '@material-ui/core';
import {
  WhatsApp as WhatsAppIcon,
  Mail as MailIcon,
  Sms as SmsIcon,
  Link as LinkIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useUiContext } from '@gamenight/common/dist/hooks';

import { ShareProps } from '../types';
import { useStyles } from './styles';
import { getShareMessages } from '../../../helpers';

const Share: React.FC<ShareProps> = props => {
  const classes = useStyles(props);
  const { event } = props;
  const theme = useTheme();
  const [linkDialog, setLinkDialog] = React.useState(false);
  const { setSnackbarMessage } = useUiContext();
  const textInput = React.createRef<HTMLInputElement>();
  const showSms = useMediaQuery(theme.breakpoints.down('sm'));

  const messages = getShareMessages(event);

  const copyLink = () => {
    if (textInput && textInput.current) {
      textInput.current.focus();
      textInput.current.select();
      textInput.current.setSelectionRange(0, 99999);
      document.execCommand('copy');
      setSnackbarMessage('Event link copied');
    } else {
      setSnackbarMessage('Failed to copy. Could you try in yourself?');
    }
    setLinkDialog(false);
  };

  return (
    <>
      <Card className={classes.share}>
        <CardContent>
          <List>
            <ListSubheader>Invite your friends</ListSubheader>
            <ListItem
              button
              alignItems="flex-start"
              component="a"
              target="_blank"
              href={`whatsapp://send?text=${messages.whatsapp.body}`}
            >
              <ListItemAvatar>
                <WhatsAppIcon className={classes.whatsAppIcon} />
              </ListItemAvatar>
              <ListItemText primary="via WhatsApp" />
            </ListItem>
            <Divider variant="inset" component="li" />
            {showSms && (
              <>
                <ListItem
                  button
                  alignItems="flex-start"
                  component="a"
                  target="_blank"
                  href={`sms:?&body=${messages.sms.body}`}
                >
                  <ListItemAvatar>
                    <SmsIcon className={classes.smsIcon} />
                  </ListItemAvatar>
                  <ListItemText primary="via SMS" />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            )}
            <ListItem
              button
              alignItems="flex-start"
              component="a"
              target="_blank"
              href={`mailto:?subject=${messages.mail.title}&body=${messages.mail.body}`}
            >
              <ListItemAvatar>
                <MailIcon className={classes.mailIcon} />
              </ListItemAvatar>
              <ListItemText primary="via mail" />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem
              button
              alignItems="flex-start"
              onClick={() => setLinkDialog(true)}
            >
              <ListItemAvatar>
                <LinkIcon className={classes.linkIcon} />
              </ListItemAvatar>
              <ListItemText primary="event link" />
            </ListItem>
          </List>
        </CardContent>
      </Card>
      <Dialog open={linkDialog}>
        <DialogTitle>
          <Grid container alignItems="center">
            <Grid item xs>
              Event link
            </Grid>
            <Grid item>
              <IconButton size="small" onClick={() => setLinkDialog(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <LinkIcon />
            </Grid>
            <Grid item xs>
              <TextField
                inputRef={textInput}
                fullWidth
                autoFocus
                variant="outlined"
                multiline
                rows={2}
                rowsMax={2}
                type="text"
                label="Link"
                name="message"
                placeholder="Event link"
                value={messages.link.body}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setLinkDialog(false)}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button onClick={copyLink} color="secondary" variant="contained">
            Copy link
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

Share.displayName = 'Share';

export default Share;
