import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  // new
  tableRow: {
    cursor: 'pointer',
  },
  tableRowDragging: {
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.background.paper,
    width: 'auto',
  },
  contentFitColumn: {
    whiteSpace: 'nowrap',
    width: '1%',
  },
}));
