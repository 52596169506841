import React from 'react';
import { Switch, Route } from 'react-router-dom';

import {
  Faq,
  EventPage,
  Home,
  Feedback,
  PrivacyPolicy,
  TermsConditions,
  FourOhFour,
  Account,
  NewEvent,
} from '../screens';
import ScrollToTop from './scroll-to-top';
import ga from '../analytics/ga';

const AppWithRouting: React.FC = () => {
  return (
    <>
      {ga.init() && <ga.RouteTracker />}
      <ScrollToTop />
      <Switch>
        <Route path="/e/:eventCode/:attendanceCode?">
          <EventPage />
        </Route>
        <Route path="/new-event">
          <NewEvent />
        </Route>
        <Route path="/account">
          <Account />
        </Route>
        <Route path="/faq">
          <Faq />
        </Route>
        <Route path="/feedback">
          <Feedback />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/terms-conditions">
          <TermsConditions />
        </Route>
        <Route path="/404">
          <FourOhFour />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </>
  );
};

export default AppWithRouting;
