import {
  createMuiTheme,
  // makeStyles,
  // createStyles,
  Theme as AugmentedTheme,
  // ThemeProvider,
} from '@material-ui/core/styles';
import { orange, red, green } from '@material-ui/core/colors';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    status: {
      danger: {
        main: string;
        hover: string;
      };
      error: {
        main: string;
        hover: string;
      };
      success: {
        main: string;
        hover: string;
      };
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    status?: {
      danger?: {
        main?: string;
        hover?: string;
      };
      error?: {
        main?: string;
        hover?: string;
      };
      success?: {
        main?: string;
        hover?: string;
      };
    };
  }
}

const baseTheme = createMuiTheme();

export const theme: AugmentedTheme = createMuiTheme({
  status: {
    danger: {
      main: orange[500],
      hover: orange[700],
    },
    error: {
      main: red[500],
      hover: red[700],
    },
    success: {
      main: green[500],
      hover: green[700],
    },
  },
  //https://colorhunt.co/palette/135151
  palette: {
    // text: {
    //   primary: '#05004e',
    // },
    background: {
      default: '#f9f8eb',
      paper: '#fdfdfb',
    },
    primary: {
      main: '#76b39d', //rgb:118,179,157
    },
    secondary: {
      main: '#fd5f00',
    },
    // dark blue: '#05004e'  -  https://colorhunt.co/palette/135151
  },
  typography: {
    fontFamily: '"Open Sans", Arial, sans-serif',
    h1: {
      fontSize: 36,
      fontFamily: 'Baloo, "Open Sans", Arial, sans-serif',
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: 64,
      },
    },
    h2: {
      fontSize: 32,
      fontFamily: 'Baloo, "Open Sans", Arial, sans-serif',
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: 58,
      },
    },
    h3: {
      fontSize: 28,
      fontFamily: 'Baloo, "Open Sans", Arial, sans-serif',
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: 32,
      },
    },
    h4: {
      fontSize: 26,
      fontFamily: 'Baloo, "Open Sans", Arial, sans-serif',
    },
  },
  // CSS overrides
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 22,
      },
      outlined: {
        paddingTop: 10,
        paddingBottom: 10,
      },
      contained: {
        paddingTop: 10,
        paddingBottom: 10,
      },
      label: {
        textTransform: 'none',
        fontWeight: 700,
        fontSize: 12,
        letterSpacing: '0.05em',
      },
    },
    MuiTooltip: {
      tooltip: {
        // backgroundColor: '#05004e',
        fontSize: 12,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 30,
      },
      input: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    MuiTextField: {
      root: {
        borderRadius: 30,
      },
    },
    MuiFormLabel: {
      root: {
        backgroundColor: '#fdfdfb',
      },
    },
    MuiCard: {
      root: {
        borderRadius: 20,
      },
    },
    MuiCardMedia: {
      root: {
        // margin: '12px 12px 0 12px',
        // borderRadius: '12px 12px 0 0',
      },
    },
    // MuiSnackbar: {
    //   root: {
    //     backgroundColor: '#05004e',
    //   },
    // },
    // MuiSnackbarContent: {
    //   root: {
    //     backgroundColor: '#05004e',
    //   },
    // },
  },
  // props defaults
  props: {
    MuiLink: {
      color: 'secondary',
    },
  },
});
