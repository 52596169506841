import { Event } from '@gamenight/common/dist/types';
import { MenuProps } from '@material-ui/core';

export interface Props extends MenuProps {
  event: Event;
  setEditDialogOpen: (value: boolean) => void;
  closeMenu: () => void;
}

export interface NotifyFormValues {
  message: string;
}

export enum Actions {
  cancel = 'cancel',
  lock = 'lock',
  notify = 'notify',
}
