import * as Sentry from '@sentry/browser';

const functionPath = '/.netlify/functions/mail-direct';
// TODO: make message enum in top-level types file

export const sendMail = (
  message: string,
  to: string,
  data: Record<string, string>,
) => {
  const params = new URLSearchParams({
    message,
    to,
    ...data,
  });
  const url = `${window.location.origin}${functionPath}?${params}`;

  return fetch(url, {
    method: 'GET',
  })
    .then(async response => {
      if (response.ok) {
        // if HTTP-status is 200-299
        //let json = await response.text(); json();
      } else {
        throw new Error(await response.text());
      }
    })
    .catch(err => {
      Sentry.captureException(err);
    });
};
