import React from 'react';
import {
  Button,
  Card,
  CardContent,
  List,
  ListSubheader,
} from '@material-ui/core';
import {
  Settings as SettingsIcon,
  Person as PersonIcon,
} from '@material-ui/icons';
import { format } from 'date-fns';
import { EVENT_STATUS, User } from '@gamenight/common/dist/types';

import { useAuth0 } from '../../../auth';
import { Props } from './types';
import { useStyles } from './styles';
import AdminMenu from '../admin-menu';
import PersonListItem from '../../person-item';
import UserDialog from '../../user-dialog';

const Author: React.FC<Props> = props => {
  const classes = useStyles(props);
  const {
    event,
    setEditDialogOpen,
    userIsAdmin,
    guestHasAdminLinkForUserEvent,
  } = props;
  const { loginWithPopup } = useAuth0();

  const [userDialog, setUserDialog] = React.useState<User>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isGuestEvent = !event.user;

  if (
    (isGuestEvent && !event.guest_name) ||
    (!isGuestEvent && !event.user?.name)
  ) {
    throw new Error('missing user object / guest name');
  }

  return (
    <>
      <Card className={classes.author}>
        <CardContent>
          <List>
            <ListSubheader>Posted by</ListSubheader>
            <PersonListItem
              itemWithPerson={event}
              onClick={
                !!event.user
                  ? (organiserUser: User) => {
                      setUserDialog(organiserUser);
                    }
                  : undefined
              }
              secondaryText={`on ${format(new Date(event.created_at), 'PPp')}`}
            />
          </List>
          {userIsAdmin && event.status !== EVENT_STATUS.CANCELED && (
            <>
              <Button
                aria-controls="author-admin-menu"
                aria-haspopup="true"
                onClick={handleClick}
                // color="primary"
                // variant="contained"
                startIcon={<SettingsIcon />}
              >
                Event settings
              </Button>
              <AdminMenu
                event={event}
                setEditDialogOpen={setEditDialogOpen}
                anchorEl={anchorEl}
                id="author-admin-menu"
                open={Boolean(anchorEl)}
                onClose={handleClose}
                keepMounted
                closeMenu={() => setAnchorEl(null)}
              />
            </>
          )}
          {guestHasAdminLinkForUserEvent &&
            event.status !== EVENT_STATUS.CANCELED && (
              <Button
                onClick={() => loginWithPopup()}
                color="secondary"
                variant="contained"
                startIcon={<PersonIcon />}
              >
                Login to edit
              </Button>
            )}
        </CardContent>
      </Card>
      {!!userDialog && (
        <UserDialog
          userId={userDialog.id}
          onClose={() => setUserDialog(undefined)}
        />
      )}
    </>
  );
};

Author.displayName = 'Author';

export default Author;
