import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  Link,
  Box,
  Grid,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import {
  UPSERT_GUEST_ATTENDANCE,
  GET_EVENT,
} from '@gamenight/common/dist/queries';
import { generateCode } from '@gamenight/common/dist/utils';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import { useUiContext } from '@gamenight/common/dist/hooks';
import ReactGA from 'react-ga';

import { useStyles } from './styles';
import { Props, GuestData } from './types';
import { useAuth0 } from '../../auth';
import { getAttendanceEmails, sendMail } from '../../helpers';
import AttendanceButtons from '../attendance-buttons';
import LoadingScreen from '../loading-screen';

const getGuestFormSchema = (guestEmails: string[]) =>
  Yup.object().shape({
    name: Yup.string()
      .min(1, 'Too Short!')
      .max(30, 'Too Long!')
      .required('Required'),
    email: Yup.string()
      .email('Invalid email')
      .required('Required')
      .notOneOf(guestEmails, 'This email is already used'),
  });

const GuestForm: React.FC<Props> = props => {
  const classes = useStyles(props);
  const { loginWithPopup } = useAuth0();
  const { replace } = useHistory();
  const { setCelebrationMessage, setSnackbarMessage } = useUiContext();

  const [submitGuestAttendanceMutation, { loading }] = useMutation(
    UPSERT_GUEST_ATTENDANCE,
  );
  const [attendingButton, setAttendingButton] = React.useState();

  const { event, attendance, isYou, fab } = props;

  const isNewAttendance = !attendance;
  const userIsAdmin = event.admin_code === attendance?.code;

  const guestFormSchema = React.useMemo(() => {
    const existingEmails = attendance?.code ? [] : getAttendanceEmails(event);
    return getGuestFormSchema(existingEmails);
  }, [event, attendance]);

  const initialValues: GuestData = {
    email: attendance?.guest_email || '',
    name: attendance?.guest_name || '',
  };

  const selectAttendance = (attending: boolean): void =>
    setAttendingButton(attending);

  const handleGuestAttendance = (
    values: GuestData,
    validateForm: FormikHelpers<GuestData>['validateForm'],
    setTouched: FormikHelpers<GuestData>['setTouched'],
  ) => {
    return async (attending: boolean) => {
      if (
        attendance?.attendance === attending &&
        values.name === attendance?.guest_name
      ) {
        // no change
        return;
      }
      const errors = await validateForm();

      if (errors.email || errors.name) {
        // if we have errors, touch the form, so error messages show up
        setTouched({ email: true, name: true }, true);
      } else {
        const code = attendance?.code || generateCode(12);
        const { email: guestEmail, name: guestName } = values;

        const result = await submitGuestAttendanceMutation({
          variables: {
            attendance: attending,
            eventId: event.id,
            guestName,
            guestEmail,
            code,
          },
          refetchQueries: [
            { query: GET_EVENT, variables: { eventCode: event.code } },
          ],
        });

        const returnedAttendanceCode =
          result?.data?.insert_attendance?.returning?.[0].code;
        if (returnedAttendanceCode) {
          if (isNewAttendance) {
            const organiserMail = event?.user?.email || event?.guest_email;

            ReactGA.event({
              category: 'attendance',
              action: attending ? 'yes_guest' : 'no_guest',
              value: event?.id,
            });

            if (attending) {
              sendMail('guest-attendance', guestEmail, {
                event_name: event.title,
                date: format(new Date(event.date), 'PPp'),
                link: `${window.location.origin}/e/${event.code}/${returnedAttendanceCode}`,
              });

              if (organiserMail) {
                console.log('notifying (for guest)', organiserMail);
                sendMail('attendance-notification', organiserMail, {
                  event_name: event.title,
                  name: guestName,
                  date: format(new Date(event.date), 'PPp'),
                  link: `${window.location.origin}/e/${event.code}/${event.admin_code}`,
                });
              }

              setCelebrationMessage(
                <>
                  <Typography variant="h2" color="inherit" gutterBottom>
                    You are in!
                  </Typography>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    Great! You will be attending this event!
                  </Typography>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    You can change your attendance later on when you click on
                    your name.
                  </Typography>
                </>,
              );
            } else {
              // TODO: send 'not attending' mail?
              setSnackbarMessage(
                `You marked your attendance: ${attending ? 'yes' : 'no'}`,
              );
            }

            replace(`/e/${event.code}/${returnedAttendanceCode}`);
          } else {
            ReactGA.event({
              category: 'attendance',
              action: attending ? 'yes_guest_change' : 'no_guest_change',
              value: event?.id,
            });
            setSnackbarMessage(
              `You marked your attendance: ${attending ? 'yes' : 'no'}`,
            );
          }
        } else {
          // TODO: gracefull fallback - app should not crash
          throw new Error('could not set attendance');
        }
      }
    };
  };

  const showGuestForm = !!(
    (isYou && !fab) ||
    (isYou && fab && typeof attendingButton === 'boolean')
  );

  const variants = {
    open: { opacity: 1, height: 'auto' },
    closed: { opacity: 0, height: 0 },
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={guestFormSchema}
      onSubmit={() => undefined}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setTouched,
        validateForm,
        // isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          {loading && <LoadingScreen variant="overlay" fullscreen />}
          <AttendanceButtons
            event={event}
            loading={loading}
            attending={
              typeof attendingButton === 'boolean'
                ? attendingButton
                : attendance?.attendance
            }
            fab={fab}
            handleClick={
              fab
                ? selectAttendance
                : handleGuestAttendance(values, validateForm, setTouched)
            }
          />
          {isNewAttendance && (
            <motion.div
              animate={showGuestForm ? 'open' : 'closed'}
              variants={variants}
              initial="closed"
            >
              <Box pb={1}>
                <br />
                <br />
                <Typography variant="h6">Got it! And who are you?</Typography>
                <TextField
                  className={classes.textField}
                  error={!!errors.name && touched.name}
                  label="Your name"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  helperText={errors.name && touched.name && errors.name}
                  variant="outlined"
                  disabled={userIsAdmin}
                />
                <Grid container alignItems="center">
                  <Grid item xs>
                    <TextField
                      className={classes.textField}
                      error={!!errors.email && touched.email}
                      label="Email"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      helperText={errors.email && touched.email && errors.email}
                      variant="outlined"
                      disabled={!!attendance?.guest_email}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      className={classes.okButton}
                      variant="contained"
                      color="secondary"
                      disabled={loading}
                      onClick={() =>
                        handleGuestAttendance(
                          values,
                          validateForm,
                          setTouched,
                        )(attendingButton)
                      }
                    >
                      OK
                    </Button>
                  </Grid>
                </Grid>
                <div>
                  <Typography variant="caption">
                    {isNewAttendance && (
                      <span>
                        You get an email with a special link, so you can change
                        your attendance for this event later on.{' '}
                      </span>
                    )}
                    {!attendance?.code && (
                      <span>
                        You can also{' '}
                        <Link href="#" onClick={() => loginWithPopup()}>
                          login or signup
                        </Link>
                        .
                      </span>
                    )}
                  </Typography>
                </div>
              </Box>
            </motion.div>
          )}
        </form>
      )}
    </Formik>
  );
};

export default GuestForm;
