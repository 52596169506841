import { makeStyles } from '@material-ui/core/styles';

import { Props } from './types';

export const useStyles = makeStyles(theme => ({
  appBar: (props: Props) => ({
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.background.paper,
    backgroundColor:
      props.variant === 'event' ? 'transparent' : theme.palette.primary.main,
  }),
  betaLabel: {
    marginLeft: theme.spacing(1),
    height: 24,
    '& > span': {
      padding: '0 8px',
    },
  },
  menuButton: {
    color: theme.palette.background.paper,
    marginRight: theme.spacing(2),
  },
  logo: {
    color: theme.palette.background.paper,
    marginRight: theme.spacing(1),
  },
  title: {
    color: theme.palette.background.paper,
    flexGrow: 1,
    textDecoration: 'none',
    // '& a': {
    //   color: theme.palette.background.paper,
    //   textDecoration: 'none',
    // },
    '&:hover': {
      color: theme.palette.background.paper,
      textDecoration: 'none',
    },
  },
  tabsContainer: {
    margin: `${theme.spacing()}px auto 0`,
    maxWidth: theme.breakpoints.values.sm,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
}));
