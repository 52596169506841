import React from 'react';
import { Avatar, Badge } from '@material-ui/core';

import { useStyles } from './styles';
import { Props } from './types';

const AvatarBadge: React.FC<Props> = ({ children, badge }) => {
  const classes = useStyles();
  return (
    <Badge
      overlap="circle"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={<Avatar className={classes.badgeIcon}>{badge}</Avatar>}
    >
      {children}
    </Badge>
  );
};

AvatarBadge.displayName = 'AvatarBadge';

export default AvatarBadge;
