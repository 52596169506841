import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer as MuiDrawer,
} from '@material-ui/core';
import {
  Home as HomeIcon,
  Add as AddIcon,
  Help as HelpIcon,
  Feedback as FeedbackIcon,
  Person as PersonIcon,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';

import { Props } from './types';
import { useStyles } from './styles';

const LayoutDrawer: React.FC<Props> = ({ showDrawer }) => {
  const classes = useStyles();

  return (
    <MuiDrawer
      className={classes.drawer}
      open={showDrawer}
      variant="persistent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolbar} />
      <List>
        <ListItem button component={Link} to="/">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Events" />
        </ListItem>
        <ListItem button component={Link} to="/new-event">
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="New event" />
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/account">
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="My account" />
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/faq">
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary="FAQ" />
        </ListItem>
        <ListItem button component={Link} to="/feedback">
          <ListItemIcon>
            <FeedbackIcon />
          </ListItemIcon>
          <ListItemText primary="Feedback" />
        </ListItem>
      </List>
    </MuiDrawer>
  );
};

LayoutDrawer.displayName = 'LayoutDrawer';

export default LayoutDrawer;
