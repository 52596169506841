import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  collapse: {
    position: 'relative',
  },
  fadeOut: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: `linear-gradient(180deg, rgba(255,2555,255,0) 0%, rgba(255,2555,255,1) 100%)`,
    opacity: 1,
    pointerEvents: 'none',
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  fadeOutExpanded: {
    opacity: 0,
  },
  expand: {
    padding: 0,
    alignSelf: 'flex-end',
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));
