import { makeStyles } from '@material-ui/core/styles';
import { grey, red, teal, lightBlue } from '@material-ui/core/colors';

export const useStyles = makeStyles(theme => ({
  share: {
    width: '100%',
    marginTop: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
      marginTop: 0,
    },
  },
  mainInviteButton: {
    backgroundColor: '#fff',
    padding: '10px 20px',
    '&:hover': { backgroundColor: '#eee' },
  },
  mailIcon: {
    color: red[500],
  },
  whatsAppIcon: {
    color: teal[500],
  },
  smsIcon: {
    color: lightBlue[500],
  },
  googleCalendarIcon: {
    color: lightBlue[800],
  },
  linkIcon: {
    color: grey[500],
  },
}));
