import { addDays, isPast, set } from 'date-fns';

// returns a date 0 days in the future, at 20:00
export const getNewDateSuggestion = () => {
  const suggestion = set(new Date(), {
    hours: 20,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  return isPast(suggestion) ? addDays(suggestion, 1) : suggestion;
};
