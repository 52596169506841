import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  ctaBlock: {
    position: 'fixed' as 'fixed',
    width: 400,
    maxWidth: '100%',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: 20,
    zIndex: theme.zIndex.modal,
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.background.paper,
    cursor: 'pointer',
  },
  closeButton: {
    fill: theme.palette.background.paper,
  },
  cardContent: {
    '&&': { paddingBottom: theme.spacing(3) },
  },
}));
