import React from 'react';
import { Typography, Chip, Box } from '@material-ui/core';
import { HourglassEmpty as HourglassEmptyIcon } from '@material-ui/icons';
import { EVENT_STATUS } from '@gamenight/common/dist/types';
import { formatDistanceToNow, isPast, format } from 'date-fns';

import { DeadlineProps } from '../types';
// import { useStyles } from '../styles';
import { getAttendingCount } from '../../../../helpers';

const Deadline: React.FC<DeadlineProps> = props => {
  // const classes = useStyles(props);
  const { event, userAttendance } = props;
  const [timeRemaining, setTimeRemaining] = React.useState('');

  const attendingCount = getAttendingCount(event);

  const { min, max } = event.options?.persons || {
    min: undefined,
    max: undefined,
  };
  const deadlineDate = event.options?.deadline?.date
    ? new Date(event.options.deadline.date)
    : undefined;

  React.useEffect(() => {
    // count down seconds
    const timer = setInterval(() => {
      if (deadlineDate) {
        setTimeRemaining(
          formatDistanceToNow(deadlineDate, { includeSeconds: true }),
        );
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [deadlineDate]);

  if (
    !deadlineDate ||
    (max && attendingCount >= max) ||
    event.status !== EVENT_STATUS.ACTIVE
  ) {
    // event is full/canceled anyway - no need to show timer
    return null;
  }

  return (
    <>
      {!isPast(deadlineDate) && (
        <>
          {!!userAttendance ? (
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              Others can respond until {format(deadlineDate, 'E, d MMM, p')}
            </Typography>
          ) : (
            <>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Please respond before {format(deadlineDate, 'E, d MMM, p')}
              </Typography>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="subtitle2" color="textSecondary">
                  Time left:
                </Typography>
                <Box ml={1}>
                  <Chip
                    color="secondary"
                    icon={<HourglassEmptyIcon />}
                    label={timeRemaining}
                  />
                </Box>
              </Box>
            </>
          )}
        </>
      )}
      {isPast(deadlineDate) && event.status === EVENT_STATUS.ACTIVE && (
        <>
          <Typography variant="caption">
            The time to respond ended on {format(deadlineDate, 'E, d MMM, p')}.
          </Typography>
          {!userAttendance && (
            <>
              <Typography variant="caption">
                <br />
                The organiser has not yet locked this event, so you can still
                try to join.
              </Typography>
            </>
          )}
          {min && min - attendingCount > 0 && (
            <>
              <Chip
                variant="outlined"
                color="secondary"
                label="Minimum persons not reached in time"
              />
              <br />
              {!!userAttendance && (
                <Typography variant="caption">
                  {!userAttendance &&
                    'If the organiser decides to cancel the event, you will be notified by mail.'}
                </Typography>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

Deadline.displayName = 'Deadline';

export default Deadline;
