import React, { ReactElement } from 'react';
import {
  Collapse,
  Box,
  Typography,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
  Avatar,
  Divider,
  Tooltip,
} from '@material-ui/core';
import {
  PersonAdd as PersonAddIcon,
  Person as PersonIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import { motion } from 'framer-motion';
import {
  Attendance as AttendanceType,
  EVENT_STATUS,
} from '@gamenight/common/dist/types';
import { formatDistanceToNow, isPast } from 'date-fns';
import { useMutation } from '@apollo/react-hooks';
import { User } from '@gamenight/common/dist/types';
import { UPSERT_ATTENDANCE, GET_EVENT } from '@gamenight/common/dist/queries';
import { useUiContext } from '@gamenight/common/dist/hooks';

import { useAuth0 } from '../../auth';
import { Props } from './types';
import { useStyles } from './styles';
import AttendanceButtons from '../attendance-buttons';
import GuestAttendanceForm from '../guest-attendance-form';
import LoadingScreen from '../loading-screen';
import UserDialog from '../user-dialog';
import PersonItem from '../person-item';

const SingleList: React.FC<Props> = (props): ReactElement => {
  const {
    attendees,
    event,
    attendanceCode,
    maxExtraPersons,
    children,
    attending,
  } = props;
  const { setSnackbarMessage } = useUiContext();
  const [submitAttendanceMutation, { loading }] = useMutation(
    UPSERT_ATTENDANCE,
  );
  const [userDialog, setUserDialog] = React.useState<User>();
  const [listExpanded, setListExpanded] = React.useState<boolean>(!!attending);
  const [expanded, setExpanded] = React.useState<{ [key: string]: boolean }>(
    {},
  );
  const { isAuthenticated, user } = useAuth0();
  const classes = useStyles(props);
  const eventHasPassed = isPast(new Date(event.date));

  const isAdmin =
    (!isAuthenticated && event.admin_code === attendanceCode) ||
    (isAuthenticated && !!user && event.user_id === user.sub);

  const toggleAttendanceChange = (code: string) => {
    setExpanded({
      ...expanded,
      [code]: !expanded[code],
    });
  };

  const userAttendance = attendees.find(
    (item: AttendanceType) =>
      (!isAuthenticated && item.code === attendanceCode) ||
      (isAuthenticated && !!user && item.user_id === user.sub),
  );

  const setAttendance = (code?: string, userId?: string) => (
    attending: boolean,
  ): void => {
    if (!userId) {
      throw new Error('received no user-id for attendance mutation');
    }
    const currentlyAttending = userAttendance && userAttendance.attendance;
    if (
      typeof currentlyAttending !== 'undefined' &&
      attending === currentlyAttending
    ) {
      // no change in attendance-setting
      return;
    }

    setExpanded({});

    submitAttendanceMutation({
      variables: {
        attendance: attending,
        code,
        eventId: event.id,
        userId: userId,
      },
      refetchQueries: [
        {
          query: GET_EVENT,
          variables: { eventCode: event.code },
        },
      ],
    });
    setSnackbarMessage(
      `Changed your attendance to: ${attending ? 'YES' : 'NO'}`,
    );
  };

  const bull = <span className={classes.bullet}>•</span>;

  const variants = {
    open: { opacity: 1, height: 'auto' },
    closed: { opacity: 0, height: 0 },
  };

  return (
    <>
      {loading && <LoadingScreen variant="overlay" fullscreen />}
      <ul className={classes.ul}>
        <ListSubheader
          onClick={() => setListExpanded(!listExpanded)}
          className={classes.listHeader}
        >
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            {children} {listExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
        </ListSubheader>
        <motion.div
          animate={listExpanded ? 'open' : 'closed'}
          variants={variants}
          initial={!!attending ? 'open' : 'closed'}
        >
          {attendees.map(item => {
            const isYou = userAttendance && item.code === userAttendance.code;
            const isUser = !!item.user_id;
            const isOrganiser =
              (isUser && item.user_id === event.user_id) ||
              (!isUser && item.guest_email === event.guest_email);
            const isContact =
              isUser && !!item.user?.isContactOf_aggregate?.aggregate?.count;
            const name = item.guest_name || (item.user && item.user.name);
            const responseDate = formatDistanceToNow(
              new Date(item.created_at),
              {
                addSuffix: true,
              },
            );

            if (!name) return null;

            return (
              <React.Fragment key={`attendance-${item.code}`}>
                <PersonItem
                  itemWithPerson={item}
                  primaryText={
                    isYou ? (
                      <>
                        <strong>{`${name}`}</strong> (you)
                      </>
                    ) : (
                      undefined
                    )
                  }
                  secondaryText={
                    <>
                      {isUser && !isYou && isContact ? (
                        <>
                          <Tooltip title="Contact">
                            <PersonIcon
                              className={classes.contactIcon}
                              fontSize="inherit"
                            />
                          </Tooltip>{' '}
                          {bull}{' '}
                        </>
                      ) : (
                        ''
                      )}
                      {isOrganiser ? (
                        <Typography
                          variant="body2"
                          component="span"
                          color="textPrimary"
                        >
                          Organiser {bull}{' '}
                        </Typography>
                      ) : (
                        ''
                      )}
                      {`responded ${responseDate}`}
                    </>
                  }
                  onClick={user => setUserDialog(user)}
                  secondaryAction={
                    (isYou || isAdmin) &&
                    !eventHasPassed && (
                      <Tooltip title="Change attendance">
                        <IconButton
                          onClick={(
                            event: React.MouseEvent<HTMLButtonElement>,
                          ) => {
                            event.stopPropagation();
                            toggleAttendanceChange(item.code);
                          }}
                          edge="end"
                          aria-label="change attendance"
                        >
                          {expanded[item.code] ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    )
                  }
                />
                {(isYou || isAdmin) && !eventHasPassed && (
                  <Collapse in={expanded[item.code]}>
                    <Box
                      className={classes.changeAttendance}
                      display="flex"
                      alignItems="center"
                    >
                      <Typography variant="body1">Change attendance</Typography>
                      <Box width={16} />
                      {isUser ? (
                        <AttendanceButtons
                          event={event}
                          loading={loading}
                          attending={item.attendance}
                          handleClick={setAttendance(item.code, item.user_id)}
                        />
                      ) : (
                        <GuestAttendanceForm
                          event={event}
                          attendance={item}
                          isYou={isYou}
                        />
                      )}
                    </Box>
                  </Collapse>
                )}
                <Divider
                  variant="inset"
                  component="li"
                  className={classes.divider}
                />
              </React.Fragment>
            );
          })}
        </motion.div>
        {maxExtraPersons && event.status === EVENT_STATUS.ACTIVE && (
          <ListItem>
            <ListItemAvatar>
              <Avatar
                alt="Max extra persons"
                className={classes.extraPersonsAvatar}
              >
                <PersonAddIcon color="primary" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              secondary={`Space left for ${maxExtraPersons} person(s)`}
            />
          </ListItem>
        )}
      </ul>
      {!!userDialog && (
        <UserDialog
          userId={userDialog.id}
          onClose={() => setUserDialog(undefined)}
        />
      )}
    </>
  );
};

SingleList.displayName = 'SingleList';

export default SingleList;
