import React from 'react';
import {
  Typography,
  DialogTitle as MuiDialogTitle,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { Props } from './types';
import { useStyles } from './styles';

const DialogTitle: React.FC<Props> = ({ children, handleClose, ...other }) => {
  const classes = useStyles();

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.dialogTitle}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {handleClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

DialogTitle.displayName = 'DialogTitle';

export default DialogTitle;
