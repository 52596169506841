import React from 'react';
import {
  Avatar,
  Box,
  CircularProgress,
  Typography,
  CardMedia,
  Dialog,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useUiContext } from '@gamenight/common/dist/hooks';
import { GET_ACCOUNT } from '@gamenight/common/dist/queries';
import { useQuery } from '@apollo/react-hooks';

import { Props } from './types';
import { useStyles } from './styles';
import {
  normalizeAccountData,
  getAvatarLetter,
  formatDate,
} from '../../helpers';
import GameList from '../game-list';
import ExpandableText from '../ui/expandable-text';

const UserDialog: React.FC<Props> = ({ userId, onClose }) => {
  const classes = useStyles();
  const { setSnackbarMessage } = useUiContext();

  const { loading, error, data } = useQuery(GET_ACCOUNT, {
    variables: { userId },
  });

  if (error) {
    setSnackbarMessage('Sorry - could not load user');
    return null;
  }

  const account = data ? normalizeAccountData(data) : undefined;

  return (
    <Dialog
      open={true}
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
      scroll="body"
      fullWidth
      maxWidth="sm"
    >
      {loading || !account ? (
        <Box m={4} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <CardMedia>
            <Box
              m={2}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Avatar
                alt={account.name}
                src={account.picture}
                className={classes.avatar}
              >
                {getAvatarLetter(account.name)}
              </Avatar>
              <Box ml={2}>
                <Typography variant="h5" gutterBottom>
                  {account.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Signed up since {formatDate(account.created_at, 'date')}
                </Typography>
              </Box>
            </Box>
            {onClose && (
              <IconButton
                size="small"
                className={classes.closeButton}
                onClick={() => onClose()}
              >
                <CloseIcon />
              </IconButton>
            )}
          </CardMedia>

          <DialogContent dividers>
            {!account.description && account.user_games.length === 0 && (
              <Typography variant="subtitle1" color="textSecondary">
                This user has no information available
              </Typography>
            )}
            {account.description && (
              <>
                <Typography variant="subtitle1" color="textSecondary">
                  About
                </Typography>
                <ExpandableText text={account.description} />
              </>
            )}
            {account.user_games.length > 0 && (
              <>
                <br />
                <Typography variant="subtitle1" color="textSecondary">
                  Boardgames
                </Typography>
                <Box className={classes.boardgamesContainer}>
                  <GameList games={account.user_games} showFavorite showOwn />
                </Box>
              </>
            )}
          </DialogContent>
          {/* <CardActions disableSpacing>add friend</CardActions> */}
        </>
      )}
    </Dialog>
  );
};

UserDialog.displayName = 'UserDialog';

export default UserDialog;
