import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { UPSERT_ATTENDANCE, GET_EVENT } from '@gamenight/common/dist/queries';
import { generateCode } from '@gamenight/common/dist/utils';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { useUiContext } from '@gamenight/common/dist/hooks';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import { motion } from 'framer-motion';
import ReactGA from 'react-ga';

import { Props } from './types';
import { useAuth0 } from '../../../auth';
import GuestAttendanceForm from '../../guest-attendance-form';
import AttendanceButtons from '../../attendance-buttons';
import { sendMail } from '../../../helpers';
import { useStyles } from './styles';
import LoadingScreen from '../../loading-screen';

const Attendance: React.FC<Props> = props => {
  const { user, isAuthenticated } = useAuth0();
  const { replace } = useHistory();
  const { setSnackbarMessage, setCelebrationMessage } = useUiContext();
  const [expanded, setExpanded] = React.useState(true);
  const classes = useStyles({
    expanded,
  });

  const [submitAttendanceMutation, { loading }] = useMutation(
    UPSERT_ATTENDANCE,
  );

  const { event, attendance } = props;

  const isNewAttendance = !attendance;

  // TODO: loading state buttons, show result in buttons
  const setAttendance = async (attending: boolean): Promise<void> => {
    const code = generateCode(12);

    const result = await submitAttendanceMutation({
      variables: {
        attendance: attending,
        eventId: event.id,
        code,
        userId: user?.sub,
      },
      refetchQueries: [
        {
          query: GET_EVENT,
          variables: { eventCode: event.code },
        },
      ],
    });

    if (result.data) {
      const organiserMail = event?.user?.email || event?.guest_email;

      if (isNewAttendance && organiserMail) {
        ReactGA.event({
          category: 'attendance',
          action: attending ? 'yes_user' : 'no_user',
          value: event?.id,
        });

        console.log('notifying (for user)', organiserMail);
        sendMail(
          attending ? 'attendance-notification' : 'attendance-not-notification',
          organiserMail,
          {
            event_name: event.title,
            name: user?.name || 'unknown',
            date: format(new Date(event.date), 'PPp'),
            link: `${window.location.origin}/e/${event.code}/${event.admin_code}`,
          },
        );
      } else {
        ReactGA.event({
          category: 'attendance',
          action: attending ? 'yes_user_change' : 'no_user_change',
          value: event?.id,
        });
      }
      if (attending && isNewAttendance) {
        setCelebrationMessage(
          <>
            <Typography variant="h2" color="inherit" gutterBottom>
              YAY!
            </Typography>
            <Typography variant="h6" color="inherit" gutterBottom>
              You are in! You will be attending this event: check out your
              beautiful name in the attendance list!
            </Typography>
            <Typography variant="h6" color="inherit" gutterBottom>
              You can change your attendance later on (just click your name).
            </Typography>
          </>,
        );
      } else {
        setSnackbarMessage(
          `You marked your attendance: ${attending ? 'yes' : 'no'}`,
        );
      }

      replace(`/e/${event.code}`);
    } else {
      // TODO: gracefull fallback - app should not crash
      throw new Error('could not set attendance');
    }
  };

  const variants = {
    open: { opacity: 1, height: 'auto' },
    closed: { opacity: 0, height: 0 },
  };

  return (
    <Card className={classes.attendanceBlock}>
      <CardHeader
        className={classes.header}
        disableTypography
        onClick={() => setExpanded(!expanded)}
        title={
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1" color="inherit">
              Are you in?
            </Typography>
            {expanded ? (
              <ExpandMoreIcon className={classes.closeButton} />
            ) : (
              <ExpandLessIcon className={classes.closeButton} />
            )}
          </Box>
        }
      />
      <motion.div
        animate={expanded ? 'open' : 'closed'}
        variants={variants}
        initial="open"
      >
        <CardContent className={classes.cardContent}>
          {loading && <LoadingScreen variant="overlay" fullscreen />}
          {isAuthenticated ? (
            <AttendanceButtons
              event={event}
              handleClick={setAttendance}
              attending={attendance?.attendance}
              loading={loading}
              fab
            />
          ) : (
            <GuestAttendanceForm {...props} isYou fab />
          )}
        </CardContent>
      </motion.div>
    </Card>
  );
};

Attendance.displayName = 'Attendance';

export default Attendance;
