import React from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  CardMedia,
  CardHeader,
  CardActions,
  Dialog,
  DialogContent,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import {
  AccessTime as AccessTimeIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import { useUiContext } from '@gamenight/common/dist/hooks';

import { Props, Game, GameSummary } from './types';
import { useStyles } from './styles';
import ExpandableText from '../ui/expandable-text';
import { getGameComplexity } from '../../helpers';

const functionPath =
  process.env.REACT_APP_FUNCTIONS_PATH || '/.netlify/functions';

const isGame = (gameOrSummary: Game | GameSummary): gameOrSummary is Game =>
  (gameOrSummary as Game).description !== undefined;

const GameDialog: React.FC<Props> = ({ gameOrSummary, action, onClose }) => {
  const classes = useStyles();
  const { setSnackbarMessage } = useUiContext();

  const [loading, setLoading] = React.useState(true);
  const [game, setGame] = React.useState<Game | null>(null);

  React.useEffect(() => {
    async function getBoardgame(
      bgg_id?: number | null,
      title?: string | null,
      titleType?: 'primary' | 'alternate',
    ) {
      if (bgg_id) {
        setLoading(true);
        let json;
        try {
          const response = await fetch(
            `${functionPath}/get-boardgame?id=${bgg_id}&title=${title}&title_type=${titleType}`,
          );
          json = await response.json();
        } catch (err) {
          setLoading(false);
          setSnackbarMessage('Sorry - could not load this game');
          if (onClose) {
            onClose(null);
          }
        }
        if (!gameOrSummary?.id && !json.gameTitleId) {
          setSnackbarMessage(
            'Sorry - something went wrong. Please try reloading the page',
          );
          return;
        }
        setGame({
          ...gameOrSummary,
          gameTitleId: gameOrSummary?.id,
          ...json,
          isUserGame: !!gameOrSummary?.isUserGame,
          own: !!gameOrSummary?.own,
          favorite: !!gameOrSummary?.favorite,
        });
      }
      setLoading(false);
    }
    if (gameOrSummary?.bgg_id && !isGame(gameOrSummary)) {
      getBoardgame(
        gameOrSummary.bgg_id,
        gameOrSummary.title,
        gameOrSummary.type,
      );
    } else if (gameOrSummary?.bgg_id && isGame(gameOrSummary)) {
      setGame(gameOrSummary);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameOrSummary?.bgg_id]);

  if (!gameOrSummary || !gameOrSummary?.bgg_id) {
    return null;
  }

  const { title, publish_year } = gameOrSummary;
  return (
    <Dialog
      open={!!game}
      onClose={() => {
        if (onClose) {
          onClose(game);
        }
      }}
      scroll="body"
      fullWidth
      maxWidth="sm"
    >
      {loading || !game ? (
        <Box m={4} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {game.image ? (
            <CardMedia
              className={classes.media}
              image={game.image}
              title={title}
            >
              {onClose && (
                <IconButton
                  className={classes.closeButtonMedia}
                  onClick={() => onClose(game)}
                >
                  <CloseIcon />
                </IconButton>
              )}
              <div className={classes.mediaHeader}>
                <Typography variant="h5" gutterBottom>
                  {title}
                  {publish_year && (
                    <span className={classes.publishYear}>
                      ({publish_year})
                    </span>
                  )}
                </Typography>
                {game.primaryTitle && game.primaryTitle !== title && (
                  <Typography variant="subtitle2" gutterBottom>
                    (also known as: {game.primaryTitle})
                  </Typography>
                )}
              </div>
            </CardMedia>
          ) : (
            <CardMedia>
              <CardHeader
                title={title}
                subheader={
                  game.primaryTitle && game.primaryTitle !== title
                    ? `(also known as: ${game.primaryTitle})`
                    : null
                }
              />
              {onClose && (
                <IconButton
                  className={classes.closeButton}
                  onClick={() => onClose(game)}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </CardMedia>
          )}
          <DialogContent dividers>
            <Grid container>
              <Grid item className={classes.thumbnail}>
                {game.thumbnail && <img src={game.thumbnail} alt={title} />}
              </Grid>
              <Grid xs item>
                <List component="nav" aria-label="game-details">
                  {(!!game.players_max || !!game.players_min) && (
                    <>
                      <ListItem>
                        <ListItemIcon>
                          <Tooltip placement="right" title="Number of players">
                            <PersonIcon />
                          </Tooltip>
                        </ListItemIcon>
                        <ListItemText
                          primary={`${
                            game.players_min &&
                            game.players_min !== game.players_max
                              ? `${game.players_min}`
                              : ''
                          }
                          ${
                            game.players_min &&
                            game.players_max &&
                            game.players_min !== game.players_max
                              ? ' - '
                              : ''
                          }
                            ${
                              game.players_max ? game.players_max : ''
                            } persons`}
                        />
                      </ListItem>
                      <Divider />
                    </>
                  )}
                  {(!!game.playtime_max || !!game.playtime_min) && (
                    <>
                      <ListItem>
                        <ListItemIcon>
                          <Tooltip placement="right" title="Playtime">
                            <AccessTimeIcon />
                          </Tooltip>
                        </ListItemIcon>
                        <ListItemText
                          primary={`${
                            game.playtime_min &&
                            game.playtime_min !== game.playtime_max
                              ? `${game.playtime_min}`
                              : ''
                          }${
                            game.playtime_min &&
                            game.playtime_max &&
                            game.playtime_min !== game.playtime_max
                              ? ' - '
                              : ''
                          }${
                            game.playtime_max ? game.playtime_max : ''
                          } minutes`}
                        />
                      </ListItem>
                      <Divider />
                    </>
                  )}
                  {!!game.complexity && (
                    <ListItem>
                      <ListItemIcon>
                        <Tooltip placement="right" title="Game complexity">
                          <SettingsIcon />
                        </Tooltip>
                      </ListItemIcon>
                      <ListItemText
                        primary={`${Math.floor(game.complexity * 100) /
                          100} / 5 (${getGameComplexity(game.complexity)})`}
                      />
                    </ListItem>
                  )}
                </List>
              </Grid>
            </Grid>
            {game.description && <ExpandableText text={game.description} />}
            {/* TODO: check for mechanics/categories */}
          </DialogContent>
          {action && (
            <CardActions disableSpacing>
              {action(game, () => setGame(null), onClose)}
            </CardActions>
          )}
        </>
      )}
    </Dialog>
  );
};

GameDialog.displayName = 'GameDialog';

export default GameDialog;
