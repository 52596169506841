import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  cancelIcon: {
    color: theme.status.error.main,
  },
  messageField: {
    width: '100%',
  },
}));
