import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';

import { Props } from './types';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    textAlign: 'center',
  },
  button: (props: Props) =>
    props.fab === true
      ? {
          width: 100,
          height: 60,
          '& span': { fontSize: 16 },
        }
      : {},
  buttonYes: (props: Props) =>
    props.attending === true
      ? {
          color: theme.palette.getContrastText(green[500]),
          backgroundColor: green[500],
          '&:hover': {
            backgroundColor: green[700],
          },
        }
      : {},
  buttonNo: (props: Props) =>
    props.attending === false
      ? {
          color: theme.palette.getContrastText(red[500]),
          backgroundColor: red[500],
          '&:hover': {
            backgroundColor: red[700],
          },
        }
      : {},
}));
