import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Box,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { BlockProps } from './types';
import { useStyles } from './styles';

const Block: React.FC<BlockProps> = ({
  children,
  description,
  optionValue,
  optionName,
  hasValue,
  title,
  cardContent,
  form,
  errors,
  activeOptions,
  setActiveOptions,
}) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = React.useState(false);
  const optionError =
    !!errors && errors[optionName] && activeOptions[optionName];

  // track if the option should be set to inactive after a cancel-event
  const activeOnStart = React.useMemo(() => activeOptions[optionName], [
    optionName,
    activeOptions,
  ]);
  const setActiveOption = (value: boolean): void => {
    setActiveOptions({ ...activeOptions, [optionName]: value });
    // hack: force validation by calling form on next tick
    setTimeout(() => form.setValues(form.values, true), 0);
  };

  const updateOption = () => {
    if (hasValue) {
      form.setFieldValue(
        optionName,
        optionValue,
        true, // validate options
      );
    } else {
      setActiveOption(false); // no val? disable option
    }
    setShowDialog(false);
  };

  const cardClasses = [
    classes.card,
    hasValue ? classes.activeCard : '',
    !!optionError ? classes.errorCard : '',
  ];

  return (
    <>
      <Card
        onClick={() => {
          setActiveOption(true);
          setShowDialog(true);
        }}
        className={cardClasses.join(' ')}
        variant="outlined"
      >
        {' '}
        <CardContent>
          <Grid container>
            <Grid item container>
              <Grid item xs>
                <Typography
                  variant="h6"
                  color={!!optionError ? 'error' : 'inherit'}
                >
                  {title}
                </Typography>
              </Grid>
              {hasValue && (
                <Grid item>
                  <Tooltip title="Disable option" aria-label="disable option">
                    <IconButton
                      size="small"
                      onClick={event => {
                        event.stopPropagation();
                        setActiveOption(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
            <Box className={classes.valueBox}>{cardContent}</Box>
          </Grid>
        </CardContent>
      </Card>
      <Dialog
        open={showDialog}
        disableBackdropClick
        scroll="body"
        onClose={() => setShowDialog(false)}
        aria-labelledby="dialog-option-title"
        aria-describedby="dialog-option-description"
      >
        <DialogTitle id="dialog-option-title">{title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="dialog-option-description">
            {description}
          </DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowDialog(false);
              if (!activeOnStart) {
                // was option inactive? keep it that way
                setActiveOption(false);
              }
            }}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              updateOption();
            }}
            color="secondary"
            variant="contained"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

Block.displayName = 'Block';

export default Block;
