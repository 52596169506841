import { Event, Attendance } from '@gamenight/common/dist/types';

// returns the amount of people attending
export const getAttendingCount = (event: Event): number => {
  if (!event.attendees) return 0;
  const attendees = event.attendees.filter(
    (item: Attendance) =>
      !!item.attendance &&
      (item.user || (!item.user && item.guest_email && item.guest_name)),
  );
  return attendees.length;
};
