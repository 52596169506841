import React from 'react';
import { Box, Avatar, Tooltip, Typography } from '@material-ui/core';

import PersonAvatar from '../person-avatar';
import { Props } from './types';
import { useStyles } from './styles';

const maxAttendees = 6;

const AttendeesGroup: React.FC<Props> = ({ attendees }) => {
  const classes = useStyles();

  const attendeesHidden: number = Math.max(attendees.length - maxAttendees, 0);

  return (
    <Box className={classes.avatarGroup}>
      {attendees.slice(0, maxAttendees).map(item => (
        <PersonAvatar key={item.code} {...item} />
      ))}
      {attendeesHidden > 0 && (
        <Tooltip title={`And ${attendeesHidden} other(s)`}>
          <Avatar>
            <Typography variant="subtitle2">{`+${attendeesHidden}`}</Typography>
          </Avatar>
        </Tooltip>
      )}
    </Box>
  );
};

AttendeesGroup.displayName = 'AttendeesGroup';

export default AttendeesGroup;
