import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Container, Box, Button, Typography } from '@material-ui/core';
import { EVENT_LIST, EVENT_LIST_ARCHIVE } from '@gamenight/common/dist/queries';
import { Event as EventType } from '@gamenight/common/dist/types';

import LoadingScreen from '../loading-screen';
import EventItem from '../event-item';
import { Props } from './types';

const EventList: React.FC<Props> = ({
  userId,
  archive,
  setNewEventDialogOpen,
}) => {
  const currentDate = React.useMemo(() => new Date().toISOString(), []);
  const query = archive ? EVENT_LIST_ARCHIVE : EVENT_LIST;

  const { loading, error, data } = useQuery(query, {
    variables: {
      currentDate,
      userId,
    },
  });

  if (loading) return <LoadingScreen />;
  // TODO: error screen feedback / sentry
  if (error) return <div>Error!... {error.message}</div>;

  return (
    <Container maxWidth="sm">
      {data.event.length > 0 ? (
        data.event.map((event: EventType) => (
          <EventItem key={event.code} event={event} />
        ))
      ) : (
        <Box my={5} textAlign="center">
          <Typography variant="h6" color="textSecondary">
            No events here...
          </Typography>
          <br />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setNewEventDialogOpen(true)}
          >
            Create a new event
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default EventList;
