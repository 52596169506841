import React from 'react';
import {
  // createMuiTheme,
  // makeStyles,
  // createStyles,
  // Theme as AugmentedTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';

import { theme } from './theme';

const ThemeProvider: React.FC = ({ children }) => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
);

ThemeProvider.displayName = 'ThemeProvider';

export default ThemeProvider;
