import React from 'react';
import {
  Grid,
  Avatar,
  Chip,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import { Schedule as ScheduleIcon } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { set, format, formatDistance, isBefore, addDays } from 'date-fns';

import Block from './block';
import { useStyles } from './styles';
import { BlockProps } from './types';
import { getTimeOptions } from '../../helpers';

const optionName = 'duration';

const Duration: React.FC<BlockProps<string>> = props => {
  const { form, errors, activeOptions, setActiveOptions } = props;

  const optionError = activeOptions[optionName] && errors[optionName];

  const classes = useStyles(props);

  const defaultDate = new Date(form.values.date); //getDefaultDeadline(new Date(form.values.date));
  const hasValue =
    !!activeOptions[optionName] && !!form.values[optionName]?.date;

  const value: string = form.values[optionName];

  const inputLabel = React.useRef<HTMLLabelElement>(null);

  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current?.offsetWidth || 0);
  }, []);

  return (
    <Block
      title="End time"
      description={
        <span>Specify until what time (or date) the event will last.</span>
      }
      optionValue={value}
      optionName={optionName}
      hasValue={hasValue}
      form={form}
      errors={errors}
      cardContent={
        hasValue && !optionError ? (
          <Chip
            icon={<ScheduleIcon />}
            color="secondary"
            variant="outlined"
            label={`${formatDistance(
              new Date(form.values.date || undefined),
              new Date(form.values[optionName]?.date || defaultDate.toString()),
              { addSuffix: false },
            )} after event start`}
            className={[classes.chip, classes.multilineChip].join(' ')}
          />
        ) : (
          <Avatar
            className={[
              classes.avatarLarge,
              !!optionError ? classes.avatarError : '',
            ].join(' ')}
          >
            <ScheduleIcon color="inherit" />
          </Avatar>
        )
      }
      activeOptions={activeOptions}
      setActiveOptions={setActiveOptions}
    >
      <Grid
        container
        spacing={1}
        alignItems="center"
        className={classes.inputGroup}
      >
        <FormControl variant="outlined">
          <InputLabel
            ref={inputLabel}
            id="demo-simple-select-outlined-label"
            className={classes.label}
          >
            Time
          </InputLabel>
          <Select
            className={classes.inputField}
            id="outlined-age-native-simple"
            labelId="demo-simple-select-outlined-label"
            labelWidth={labelWidth}
            name="endTime"
            value={
              form.values[optionName]?.date
                ? format(new Date(form.values[optionName]?.date), 'HH:mm')
                : ''
            }
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              if (typeof event.target.value === 'string') {
                const [hours, minutes] = event.target.value.split(':');

                let newDate = set(
                  new Date(
                    form.values[optionName]?.date || defaultDate.toString(),
                  ),
                  {
                    hours: parseInt(hours),
                    minutes: parseInt(minutes),
                  },
                );

                if (isBefore(newDate, new Date(form.values.date))) {
                  // if time is before start, add an exta day
                  newDate = addDays(newDate, 1);
                }

                form.setFieldValue(
                  optionName,
                  {
                    ...form.values[optionName],
                    date: newDate.toString(),
                  },
                  true,
                );
              }
            }}
          >
            {getTimeOptions()}
          </Select>
        </FormControl>
        <DatePicker
          clearable
          inputVariant="outlined"
          disablePast={true}
          label="Date"
          minDate={form.values.date ? new Date(form.values.date) : new Date()}
          name="endDate"
          value={form.values[optionName]?.date || defaultDate.toString()}
          format="dd/MM/yyyy"
          helperText={errors[optionName]?.date}
          error={Boolean(errors[optionName]?.date)}
          onChange={date => {
            form.setFieldValue(
              optionName,
              {
                ...form.values[optionName],
                date,
              },
              true,
            );
          }}
        />

        {form.values[optionName]?.date && (
          <Box my={1}>
            <Typography variant="body1" color="textSecondary">
              {formatDistance(
                new Date(form.values.date || undefined),
                new Date(form.values[optionName]?.date),
                { addSuffix: false },
              )}{' '}
              after event start
            </Typography>
          </Box>
        )}
      </Grid>
    </Block>
  );
};

Duration.displayName = 'Duration';

export default Duration;
