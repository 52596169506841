import { makeStyles } from '@material-ui/core/styles';

import { Props } from './types';

export const useStyles = makeStyles({
  attendanceStatus: (props: Props) => ({
    position: 'absolute' as 'absolute',
    top: 15,
    right: props.isAuthenticated ? 36 : 100,
    backgroundColor: 'white',
    paddingRight: props.isAuthenticated ? 24 : 0,
  }),
});
