import React from 'react';
import { Button, Avatar, Chip, IconButton } from '@material-ui/core';
import {
  Casino as BoardgameIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import { Game } from '@gamenight/common/dist/types';
import { useQuery } from '@apollo/react-hooks';
import { GET_GAMES_BY_GAME_TITLE_IDS } from '@gamenight/common/dist/queries';
import * as Sentry from '@sentry/browser';

import Block from './block';
import { useStyles } from './styles';
import { BlockProps } from './types';
import { LocationType } from '../../types';
import GameSearch from '../../../game-search';
import GameList from '../../../game-list';
import { useAuth0 } from '../../../../auth';
import { normalizeGame, sortGames } from '../../../../helpers';

const optionName = 'boardgames';

const Boardgames: React.FC<BlockProps<LocationType>> = props => {
  const { form, activeOptions, setActiveOptions } = props;
  const classes = useStyles(props);
  const { user } = useAuth0();
  const [games, setGames] = React.useState<Game[]>([]);
  const { data, error } = useQuery(GET_GAMES_BY_GAME_TITLE_IDS, {
    variables: {
      gameTitleIds: form.values.boardgames?.gameList || [],
    },
  });

  React.useEffect(() => {
    if (data && data.game_title && data.game_title.length > 0) {
      const eventGames = sortGames(
        data.game_title.map(normalizeGame),
        form.values.boardgames?.gameList,
      );
      setGames(eventGames);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    if (games.length > 0) {
      const eventGames = sortGames(games, form.values.boardgames?.gameList);
      setGames(eventGames);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  if (error) {
    Sentry.captureException(`Failed to load event games: ${error.toString()}`);
    console.log(error);
    throw new Error('could-not-fetch');
  }

  const updateFieldValue = (games: Game[]) => {
    const gameList = games.map(game => game.gameTitleId);
    form.setFieldValue(
      optionName,
      {
        ...form.values[optionName],
        gameList,
      },
      true,
    );
  };

  const addGame = (newGame: Game) => {
    if (!games.find(game => game.bgg_id === newGame.bgg_id)) {
      const newGames = [...games, newGame];
      setGames(newGames);
      updateFieldValue(newGames);
    }
  };

  const removeGame = (removeGame: Game) => {
    const newGames = games.filter(game => game.bgg_id !== removeGame.bgg_id);
    setGames(newGames);
    updateFieldValue(newGames);
  };

  const hasValue =
    !!activeOptions[optionName] &&
    !!form.values[optionName]?.gameList &&
    form.values[optionName].gameList.length > 0;

  const cellAction = (game: Game) => (
    <IconButton
      aria-label="remove from boardgames list"
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        removeGame(game);
      }}
    >
      <DeleteIcon color="action" />
    </IconButton>
  );

  const action = (
    game: Game,
    closeDialog: () => void,
    closeOptions?: () => void,
  ) => {
    const isInList = games.find(
      gameInList => gameInList.bgg_id === game.bgg_id,
    );

    return isInList ? (
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => {
          removeGame(game);
          if (closeDialog) closeDialog();
          if (closeOptions) closeOptions();
        }}
        aria-label="remove from boardgames list"
        startIcon={<DeleteIcon color="secondary" />}
      >
        Remove game
      </Button>
    ) : (
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          addGame(game);
          if (closeDialog) closeDialog();
          if (closeOptions) closeOptions();
        }}
        startIcon={<AddIcon />}
      >
        Add game
      </Button>
    );
  };

  return (
    <Block
      title="Boardgames"
      description="What boardgames are on the menu for your event?"
      optionValue={form.values[optionName]}
      optionName={optionName}
      hasValue={hasValue}
      form={form}
      cardContent={
        hasValue ? (
          <Chip
            icon={<BoardgameIcon />}
            color="secondary"
            variant="outlined"
            label={`${form.values[optionName].gameList.length} game(s)`}
            className={classes.chip}
          />
        ) : (
          <Avatar className={classes.avatarLarge}>
            <BoardgameIcon color="inherit" />
          </Avatar>
        )
      }
      activeOptions={activeOptions}
      setActiveOptions={setActiveOptions}
    >
      <GameSearch
        userGames={games}
        action={action}
        initialOptions={user?.user_games}
      />
      <br />
      <GameList
        games={games}
        action={action}
        showDragHandle
        onDrag={updateFieldValue}
        hideHeader
        cellAction={cellAction}
      />
    </Block>
  );
};

Boardgames.displayName = 'Boardgames';

export default Boardgames;
