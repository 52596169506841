import React from 'react';
import {
  Grid,
  Avatar,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
} from '@material-ui/core';
import { Public as PublicIcon } from '@material-ui/icons';

import Block from './block';
import { useStyles } from './styles';
import { BlockProps } from './types';

const optionName = 'public';

const Public: React.FC<BlockProps<number>> = props => {
  const { form, errors, activeOptions, setActiveOptions } = props;

  const optionError = activeOptions[optionName] && errors?.[optionName];

  const classes = useStyles(props);

  const hasValue =
    !!activeOptions[optionName] && !!form.values[optionName]?.share;

  const handleChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    form.setFieldValue(
      optionName,
      {
        ...form.values[optionName],
        [name]: event.target.checked,
      },
      true,
    );
  };

  return (
    <Block
      title="Public event"
      description="Want more people? Let friends of friends or other intested people join the fun!"
      optionValue={form.values[optionName]}
      optionName={optionName}
      hasValue={hasValue}
      form={form}
      errors={errors}
      cardContent={
        hasValue && !optionError ? (
          <Chip
            icon={<PublicIcon />}
            color="secondary"
            variant="outlined"
            label={form.values[optionName]?.share ? 'yep' : ''}
            className={classes.chip}
          />
        ) : (
          <Avatar
            className={[
              classes.avatarLarge,
              !!optionError ? classes.avatarError : '',
            ].join(' ')}
          >
            <PublicIcon color="inherit" />
          </Avatar>
        )
      }
      activeOptions={activeOptions}
      setActiveOptions={setActiveOptions}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item xs>
          <FormControl component="fieldset">
            {/* <FormLabel component="legend">
              Allow your friends to invite others
            </FormLabel> */}
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={form.values[optionName]?.share}
                    onChange={handleChange('share')}
                    value="share"
                  />
                }
                label="Allow friends to invite others"
              />
            </FormGroup>
            <br />
            <br />
            {/* <FormHelperText>
              Only friends who sign up can invite others
            </FormHelperText> */}
            <FormControlLabel
              control={
                <Switch
                  disabled
                  checked={form.values[optionName]?.connections}
                  onChange={handleChange('connections')}
                  value="connections"
                />
              }
              label="Show to friends of my connections"
            />
            <FormControlLabel
              control={
                <Switch
                  disabled
                  checked={form.values[optionName]?.location}
                  onChange={handleChange('location')}
                  value="location"
                />
              }
              label="Show to people close by the location"
            />
            {/* <FormHelperText>
              Only available with an event <b>location</b>
            </FormHelperText> */}
            <FormControlLabel
              control={
                <Switch
                  disabled
                  checked={form.values[optionName]?.games}
                  onChange={handleChange('games')}
                  value="games"
                />
              }
              label="Show to fans of the games at the event"
            />
            {/* <FormHelperText>
              Only available when you enter the event <b>boardgames</b>
            </FormHelperText>  */}
          </FormControl>
          <br />
          <Chip color="primary" label="not yet available in beta" />
        </Grid>
      </Grid>
    </Block>
  );
};

Public.displayName = 'Public';

export default Public;
