import { Game } from '@gamenight/common/dist/types';

export const normalizeUserGames = (data: any): Game[] => {
  if (!data) return [];
  // prop can be different depending on query
  const userGameProp = data.user_game ? 'user_game' : 'user_games';
  if (!data[userGameProp]) return [];
  return data[userGameProp].map((item: any) => {
    if (!item.game_title?.id) {
      throw new Error(
        `User game is missing gameTitleId for user-game ${item.id}`,
      );
    }
    return {
      id: item.game_title?.gameByBggId?.id,
      gameTitleId: item.game_title?.id,
      own: item.own,
      favorite: item.favorite,
      order: item.order,
      title: item.game_title?.title,
      publish_year: item.game_title?.gameByBggId?.publish_year,
      thumbnail: item.game_title?.gameByBggId?.thumbnail,
      image: item.game_title?.gameByBggId?.image,
      players_min: item.game_title?.gameByBggId?.players_min,
      players_max: item.game_title?.gameByBggId?.players_max,
      playtime_min: item.game_title?.gameByBggId?.playtime_min,
      playtime_max: item.game_title?.gameByBggId?.playtime_max,
      complexity: item.game_title?.gameByBggId?.complexity,
      description: item.game_title?.gameByBggId?.description,
      bgg_id: item.game_title?.gameByBggId?.bgg_id,
      primaryGameTitleId: item.game_title?.gameByBggId?.gameTitlesByBggId?.id,
      primaryTitle: item.game_title?.gameByBggId?.gameTitlesByBggId?.title,
      isUserGame: !!item.id,
    };
  });
};
