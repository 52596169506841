import React from 'react';
import { TextField, Grid, Avatar, Chip } from '@material-ui/core';
import {
  NotListedLocation as NotListedLocationIcon,
  LocationOn as LocationOnIcon,
} from '@material-ui/icons';

import Block from './block';
import { useStyles } from './styles';
import { BlockProps } from './types';
import { LocationType } from '../../types';

const optionName = 'location';

const Location: React.FC<BlockProps<LocationType>> = props => {
  const { form, errors, activeOptions, setActiveOptions, ...other } = props;

  const optionError = activeOptions[optionName] && errors?.[optionName];

  const classes = useStyles(props);

  const hasValue =
    !!activeOptions[optionName] && !!form.values[optionName]?.location;

  return (
    <Block
      title="Location"
      description="Be aware that your location will be public for anybody who can see your event"
      optionValue={form.values[optionName]}
      optionName={optionName}
      hasValue={hasValue}
      form={form}
      errors={errors}
      cardContent={
        hasValue && !optionError ? (
          <Chip
            icon={<LocationOnIcon />}
            color="secondary"
            variant="outlined"
            label={form.values[optionName]?.location}
            className={classes.chip}
          />
        ) : (
          <Avatar
            className={[
              classes.avatarLarge,
              !!optionError ? classes.avatarError : '',
            ].join(' ')}
          >
            <NotListedLocationIcon color="inherit" />
          </Avatar>
        )
      }
      activeOptions={activeOptions}
      setActiveOptions={setActiveOptions}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <LocationOnIcon color="inherit" />
        </Grid>
        <Grid item xs>
          <TextField
            autoFocus
            type="text"
            className={classes.inputField}
            variant="outlined"
            multiline
            rows={4}
            rowsMax={4}
            value={form.values[optionName]?.location}
            label="Location"
            helperText={errors?.[optionName]?.location}
            error={!!errors?.[optionName]?.location}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.preventDefault();
              form.setFieldValue(
                'location',
                {
                  ...form.values[optionName],
                  location: event.target.value,
                },
                true,
              );
            }}
            {...other}
          />
        </Grid>
      </Grid>
    </Block>
  );
};

Location.displayName = 'Location';

export default Location;
