import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { isPast } from 'date-fns';

import LoadingScreen from '../components/loading-screen';
import { Props, Context, Auth0Client, User } from './types';

const DEFAULT_REDIRECT_CALLBACK = (): void => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

export const Auth0Context = React.createContext<Context>({} as Context);

export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider: React.FC<Props> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  // ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User>();
  const [idToken, setIdToken] = useState('');
  const [expiry, setExpiry] = useState<number>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  const parseClaims = (claims: any): void => {
    if (!claims) {
      throw new Error('invalid auth claims');
    }
    const { __raw, exp, ...rest } = claims;
    setIdToken(__raw);
    setExpiry(exp);
    setUser(rest);
  };

  useEffect(() => {
    const initAuth0 = async (): Promise<void> => {
      const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
      const domain = process.env.REACT_APP_AUTH0_DOMAIN;
      const audience = process.env.REACT_APP_AUDIENCE;

      const auth0FromHook = await createAuth0Client({
        // eslint-disable-next-line @typescript-eslint/camelcase
        client_id: clientId,
        domain,
        audience,
        scope: 'openid profile',
        // eslint-disable-next-line @typescript-eslint/camelcase
        redirect_uri: `${window.location.origin}`,
      } as Auth0ClientOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        parseClaims(await auth0FromHook.getIdTokenClaims());
      }
      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    if (!auth0Client) {
      throw new Error('No Auth0 client found');
    }
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      // TODO: sent to Sentry
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    parseClaims(await auth0Client.getIdTokenClaims());
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    if (!auth0Client) {
      throw new Error('No Auth0 client found');
    }
    setLoading(true);
    const result = await auth0Client.handleRedirectCallback();
    parseClaims(await auth0Client.getIdTokenClaims());
    setLoading(false);
    setIsAuthenticated(true);
    return result;
  };

  const getIdToken = async (): Promise<string> => {
    if (auth0Client && expiry && isPast(expiry * 1000)) {
      await auth0Client.getTokenSilently();
      const claims = await auth0Client.getIdTokenClaims();
      console.log('getIdToken::::', claims);
      parseClaims(claims);
      return claims.__raw;
    }
    return idToken;
  };

  if (!auth0Client) return null;

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        setUser,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdToken,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) =>
          auth0Client.logout({ ...p, returnTo: window.location.origin }),
      }}
    >
      {loading ? <LoadingScreen fullscreen /> : children}
    </Auth0Context.Provider>
  );
};
