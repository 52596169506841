import React from 'react';
import {
  Grid,
  Avatar,
  Chip,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import { HourglassEmpty as HourglassEmptyIcon } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { set, format, formatDistance, isAfter, subDays } from 'date-fns';

import Block from './block';
import { useStyles } from './styles';
import { BlockProps } from './types';
import { getTimeOptions, getDefaultDeadline } from '../../helpers';

const optionName = 'deadline';

const Deadline: React.FC<BlockProps<string>> = props => {
  const { form, errors, activeOptions, setActiveOptions } = props;

  const optionError = activeOptions[optionName] && errors[optionName];

  const classes = useStyles(props);

  const defaultDate = getDefaultDeadline(new Date(form.values.date));
  const hasValue =
    !!activeOptions[optionName] && !!form.values[optionName]?.date;

  const value: string = form.values.deadline;

  const inputLabel = React.useRef<HTMLLabelElement>(null);

  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current?.offsetWidth || 0);
  }, []);

  return (
    <Block
      title="Deadline"
      description={
        <span>
          Specify when people should let you know if they will attend. You will
          get a reminder mail when the deadline expires.
        </span>
      }
      optionValue={value}
      optionName={optionName}
      hasValue={hasValue}
      form={form}
      errors={errors}
      cardContent={
        hasValue && !optionError ? (
          <Chip
            icon={<HourglassEmptyIcon />}
            color="secondary"
            variant="outlined"
            label={`${formatDistance(
              new Date(form.values.date || undefined),
              new Date(form.values[optionName]?.date || defaultDate.toString()),
              { addSuffix: false },
            )} before event start`}
            className={[classes.chip, classes.multilineChip].join(' ')}
          />
        ) : (
          <Avatar
            className={[
              classes.avatarLarge,
              !!optionError ? classes.avatarError : '',
            ].join(' ')}
          >
            <HourglassEmptyIcon color="inherit" />
          </Avatar>
        )
      }
      activeOptions={activeOptions}
      setActiveOptions={setActiveOptions}
    >
      <Grid
        container
        spacing={1}
        alignItems="center"
        className={classes.inputGroup}
      >
        <Box mt={1}>
          <FormControl variant="outlined">
            <InputLabel
              ref={inputLabel}
              id="demo-simple-select-outlined-label"
              className={classes.label}
            >
              Time
            </InputLabel>
            <Select
              className={classes.inputField}
              id="outlined-age-native-simple"
              labelId="demo-simple-select-outlined-label"
              labelWidth={labelWidth}
              name="deadlineTime"
              value={
                form.values[optionName]?.date
                  ? format(new Date(form.values[optionName]?.date), 'HH:mm')
                  : ''
              }
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                if (typeof event.target.value === 'string') {
                  const [hours, minutes] = event.target.value.split(':');

                  let newDate = set(
                    new Date(
                      form.values[optionName]?.date || defaultDate.toString(),
                    ),
                    {
                      hours: parseInt(hours),
                      minutes: parseInt(minutes),
                    },
                  );

                  if (isAfter(newDate, new Date(form.values.date))) {
                    // if time is before start, add an exta day
                    newDate = subDays(newDate, 1);
                  }

                  form.setFieldValue(
                    optionName,
                    {
                      ...form.values.deadline,
                      date: newDate.toString(),
                    },
                    true,
                  );
                }
              }}
            >
              {getTimeOptions()}
            </Select>
          </FormControl>
        </Box>
        <DatePicker
          clearable
          inputVariant="outlined"
          disablePast={true}
          label="Date"
          maxDate={form.values.date ? new Date(form.values.date) : new Date()}
          name="deadlineDate"
          value={form.values[optionName]?.date || defaultDate.toString()}
          format="dd/MM/yyyy"
          helperText={errors[optionName]?.date}
          error={Boolean(errors[optionName]?.date)}
          onChange={date => {
            form.setFieldValue(
              optionName,
              {
                ...form.values.deadline,
                date,
              },
              true,
            );
          }}
        />
        {form.values[optionName]?.date && (
          <Box my={1}>
            <Typography variant="body1" color="textSecondary">
              {formatDistance(
                new Date(form.values.date || undefined),
                new Date(form.values[optionName]?.date),
                { addSuffix: false },
              )}{' '}
              before start event
            </Typography>
          </Box>
        )}
      </Grid>
    </Block>
  );
};

Deadline.displayName = 'Deadline';

export default Deadline;
