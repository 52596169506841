import { makeStyles } from '@material-ui/core/styles';

import { Props } from './types';

export const useStyles = makeStyles(theme => ({
  textField: (props: Props) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '100%',
    borderColor: props.fab ? theme.palette.background.paper : 'auto',
  }),
  okButton: {
    marginLeft: theme.spacing(2),
  },
}));
