import React from 'react';
import {
  Box,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { set, format, formatDistanceToNow } from 'date-fns';

import { DateTimePickerFieldProps } from '../types';
import { useStyles } from './blocks/styles';

const DateTimePickerField: React.FC<DateTimePickerFieldProps> = ({
  field,
  form,
  ...other
}) => {
  const currentError = form.errors[field.name];
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const classes = useStyles();

  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current?.offsetWidth || 0);
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      className={classes.dateTimeInput}
    >
      <DatePicker
        inputVariant="outlined"
        disablePast={true}
        name={field.name}
        value={field.value}
        format="dd/MM/yyyy"
        helperText={currentError}
        error={Boolean(currentError)}
        onChange={date => {
          form.setFieldValue(field.name, date, true);
        }}
        {...other}
      />
      <Box ml={1}>
        <FormControl variant="outlined">
          <InputLabel
            ref={inputLabel}
            id="demo-simple-select-outlined-label"
            className={classes.label}
          >
            Time
          </InputLabel>
          <Select
            id="outlined-age-native-simple"
            labelId="demo-simple-select-outlined-label"
            labelWidth={labelWidth}
            value={format(new Date(field.value), 'HH:mm')}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              if (typeof event.target.value === 'string') {
                const [hours, minutes] = event.target.value.split(':');

                const newDate = set(new Date(field.value), {
                  hours: parseInt(hours),
                  minutes: parseInt(minutes),
                }).toString();

                form.setFieldValue(field.name, newDate, true);
              }
            }}
          >
            {[...Array(24)].map((val, index) => {
              const hour = ('0' + index).slice(-2);
              const partsOfDay: Record<string, string> = {
                '00': 'Night',
                '08': 'Morning',
                '12': 'Afternoon',
                '18': 'Evening',
                '22': 'Night',
              };
              const timeOptions =
                hour in partsOfDay
                  ? [
                      <MenuItem key={partsOfDay[hour]} value="" disabled>
                        <em>{partsOfDay[hour]}</em>
                      </MenuItem>,
                    ]
                  : [];
              for (let minutes = 0; minutes <= 45; minutes += 15) {
                const timeStr = `${hour}:${('0' + minutes).slice(-2)}`;
                timeOptions.push(
                  <MenuItem key={timeStr} value={timeStr}>
                    {timeStr}
                  </MenuItem>,
                );
              }
              return timeOptions;
            })}
          </Select>
        </FormControl>
      </Box>
      <Box ml={1}>
        <Typography variant="body2" color="textSecondary">
          ({formatDistanceToNow(Date.parse(field.value), { addSuffix: true })})
        </Typography>
      </Box>
    </Box>
  );
};

DateTimePickerField.displayName = 'DateTimePickerField';

export default DateTimePickerField;
