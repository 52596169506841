import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  author: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),

    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
}));
