import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles(theme => ({
  card: {
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
    }),
    color: grey[500],
    width: 240,
    minHeight: 140,
    cursor: 'pointer',
  },
  valueBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    width: '100%',
  },
  activeCard: {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.main,
    color: 'black',
  },
  errorCard: {
    backgroundColor: 'white',
    borderColor: theme.palette.error.main,
  },
  inactiveCardCheck: {
    visibility: 'hidden',
    cursor: 'default',
    'pointer-events': 'none',
  },
  dateTimeInput: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > div': {
        marginLeft: 0,
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
      },
    },
  },
  chip: {
    maxWidth: '100%',
  },
  label: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: 2,
    paddingRight: 2,
  },
  inputFieldSmall: {
    minWidth: 180,
  },
  inputField: {
    width: '100%',
    maxWidth: 300,
    minWidth: 120,
  },
  inputFieldFullWidth: {
    width: '100%',
    minWidth: 120,
  },
  inputGroup: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
  },
  multilineChip: {
    height: 'auto',
    minHeight: 32,
    '& > span': {
      whiteSpace: 'normal',
    },
  },
  avatarMedium: {
    backgroundColor: grey[500],
    color: 'white',
    height: 30,
    width: 30,
    fontSize: 14,
  },
  avatarLarge: {
    backgroundColor: grey[500],
    color: 'white',
    height: 40,
    width: 40,
  },
  avatarActive: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  avatarError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  avatarLabel: {
    marginRight: 8,
  },
  avatarDivider: {
    width: 40,
    padding: '0 10px',
    marginLeft: 12,
    marginRight: 12,
    height: 1,
    borderTop: `5px ${theme.palette.secondary.main} dotted`,
  },
  checkboxOtherTextField: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(-4),
  },
}));
