import React from 'react';
import { Tooltip, Avatar } from '@material-ui/core';
import generateColorHash from 'material-color-hash';

import { getAvatarLetter } from '../../helpers';
import { Props } from './types';
import { useStyles } from './styles';

const PersonAvatar: React.FC<Props> = ({ guest_name, user, code, border }) => {
  const classes = useStyles({ border });
  const name = user?.name || guest_name || '?';
  const avatarProps = user?.picture
    ? {
        src: user.picture,
      }
    : {
        style: {
          backgroundColor: generateColorHash(code || 'no-code', 600)
            .backgroundColor,
        },
      };
  return (
    <Tooltip key={code || name} title={name}>
      <Avatar alt={name} {...avatarProps} className={classes.root}>
        {getAvatarLetter(name)}
      </Avatar>
    </Tooltip>
  );
};

PersonAvatar.displayName = 'PersonAvatar';

export default PersonAvatar;
