import React from 'react';
import { Container, Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import Logo from '../../logo';
import { useStyles } from './styles';

const LayoutHeader: React.FC = () => {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <footer className={classes.footer}>
      <Container maxWidth="md">
        <Box
          className={classes.footerLinks}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Logo className={classes.footerLogo} />
          <Link to="/new-event" className={classes.footerLink}>
            <Typography variant="body1">new event</Typography>
          </Link>
          {bull}
          <Link to="/faq" className={classes.footerLink}>
            <Typography variant="body1">frequently asked questions</Typography>
          </Link>
          {bull}
          <Link to="/feedback" className={classes.footerLink}>
            <Typography variant="body1">feedback</Typography>
          </Link>
        </Box>
        <Box
          className={classes.footerLinks}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt={1}
        >
          <Typography variant="caption" color="textSecondary">
            release: {process.env.REACT_APP_SENTRY_RELEASE || 'local'}
          </Typography>
          {bull}
          <Link to="/terms-conditions" className={classes.footerLink}>
            <Typography variant="caption" color="textSecondary">
              terms &amp; conditions
            </Typography>
          </Link>
          {bull}
          <Link to="/privacy-policy" className={classes.footerLink}>
            <Typography variant="caption" color="textSecondary">
              privacy policy
            </Typography>
          </Link>
        </Box>
      </Container>
    </footer>
  );
};

LayoutHeader.displayName = 'LayoutHeader';

export default LayoutHeader;
