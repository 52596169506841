import React from 'react';
import { Typography, Container, Card, CardContent } from '@material-ui/core';

import Layout from '../../components/layout';

const PrivacyPolicy: React.FC = () => {
  return (
    <Layout>
      <Container maxWidth="md">
        <Typography variant="h2" gutterBottom>
          Privacy Policy
        </Typography>
        <Card>
          <CardContent>
            <Typography variant="h3" gutterBottom>
              Gamenight
            </Typography>
            <Typography variant="body1" gutterBottom>
              Gamenight is a website by Ru Nacken-van der Rest
              (info@are-you.nl), based in Amsterdam, Netherlands.
              <br />
              <br /> This privacy policy will explain how our organization uses
              the personal data we collect from you when you use our website.
            </Typography>
            <Typography variant="caption" gutterBottom>
              This document is based on a template from GDPR.eu
              (https://gdpr.eu/privacy-notice/)
              <br />
              <br />
              <hr />
              <br />
            </Typography>
            <Typography variant="h4" gutterBottom>
              Topics:
            </Typography>
            <Typography variant="body1" gutterBottom>
              <ul>
                <li>What data do we collect?</li>
                <li>How do we collect your data?</li>
                <li>How will we use your data?</li>
                <li>How do we store your data?</li>
                <li>Marketing</li>
                <li>What are your data protection rights?</li>
                <li>What are cookies?</li>
                <li>How do we use cookies?</li>
                <li>What types of cookies do we use?</li>
                <li>How to manage your cookies</li>
                <li>Privacy policies of other websites</li>
                <li>Changes to our privacy policy</li>
                <li>How to contact us</li>
                <li>How to contact the appropriate authorities</li>
              </ul>
            </Typography>

            <Typography variant="h4" gutterBottom>
              What data do we collect?
            </Typography>
            <Typography variant="body1" gutterBottom>
              Gamenight collects the following data:
              <ul>
                <li>
                  Personal identification information (name, email address,
                  phone number, etc.)
                </li>
                <li>Event data (linked to your account or email address)</li>
                <li>
                  Attendance data (linked to your account or email address)
                </li>
              </ul>
            </Typography>

            <Typography variant="h4" gutterBottom>
              How do we collect your data?
            </Typography>
            <Typography variant="body1" gutterBottom>
              You directly provide Gamenight with most of the data we collect.
              We collect data and process data when you:
              <ul>
                <li>Register online.</li>
                <li>
                  Voluntarily complete a customer survey or provide feedback via
                  email.
                </li>
                <li>Use or view our website via your browser’s cookies.</li>
              </ul>
              {/* 
Gamenight may also receive your data indirectly from the following sources:

[Add any indirect source of data yGamenight has] */}
            </Typography>

            <Typography variant="h4" gutterBottom>
              How will we use your data?
            </Typography>
            <Typography variant="body1" gutterBottom>
              Gamenight collects your data so that we can:
              <ul>
                <li>
                  Process your events or attendance-sign-ups and manage your
                  account.
                </li>
                <li>
                  Email you with information about your event (new attendees,
                  etc).
                </li>
                <li>Email you with a request for feedback on the platform.</li>
              </ul>
              {/* If you agree, Gamenight will share your data with our partner companies so that they may offer you their products and services.

[List organizations that will receive data]
When Gamenight processes your order, it may send your data to, and also use the resulting information from, credit reference agencies to prevent fraudulent purchases. */}
            </Typography>
            <Typography variant="h4" gutterBottom>
              How do we store your data?
            </Typography>
            <Typography variant="body1" gutterBottom>
              Gamenight securely stores your data at a server from Netlify (see
              https://netlify.com). Our database is stored at Heroku (see
              https://heroku.com).
              <br />
              Gamenight will keep your personal, event and attendance data as
              long as we need to provide you with the Gamenight service.
            </Typography>
            <Typography variant="h4" gutterBottom>
              Marketing
            </Typography>
            <Typography variant="body1" gutterBottom>
              Currently we have no plans to send you any information about any
              products or services.
              {/* Gamenight would like to send you information about products and services of ours that we think you might like, as well as those of our partner companies.

[List organizations that will receive data]
If you have agreed to receive marketing, you may always opt out at a later date.

You have the right at any time to stop Gamenight from contacting you for marketing purposes or giving your data to other members of the Gamenight Group.

If you no longer wish to be contacted for marketing purposes, please click here. */}
            </Typography>
            <Typography variant="h4" gutterBottom>
              What are your data protection rights?
            </Typography>
            <Typography variant="body1" gutterBottom>
              We would like to make sure you are fully aware of all of your data
              protection rights. In Holland this is known as 'Algemene
              verordening gegevensbescherming'. Every user is entitled to the
              following:
              <ul>
                <li>
                  The right to access – You have the right to request Our
                  Company for copies of your personal data. We may charge you a
                  small fee for this service.
                </li>
                <li>
                  The right to rectification – You have the right to request
                  that Gamenight correct any information you believe is
                  inaccurate. You also have the right to request Gamenight to
                  complete the information you believe is incomplete.
                </li>
                <li>
                  The right to erasure – You have the right to request that Our
                  Company erase your personal data, under certain conditions.
                </li>
                <li>
                  The right to restrict processing – You have the right to
                  request that Gamenight restrict the processing of your
                  personal data, under certain conditions.
                </li>
                <li>
                  The right to object to processing – You have the right to
                  object to Gamenight’s processing of your personal data, under
                  certain conditions.
                </li>
                <li>
                  The right to data portability – You have the right to request
                  that Gamenight transfer the data that we have collected to
                  another organization, or directly to you, under certain
                  conditions.
                </li>
              </ul>
              <br />
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us at
              our email: info@are-you.nl
              <br />
              Call us at: +31 (zero) 61403 7880.
              <br />
              Or write to us: Are-you.nl / Gamenight. Lucellestr. 8-3hoog.
              1055HX Amsterdam. The Netherlands.
            </Typography>
            <Typography variant="h4" gutterBottom>
              Cookies
            </Typography>
            <Typography variant="body1" gutterBottom>
              Cookies are text files placed on your computer to collect standard
              Internet log information and visitor behavior information. When
              you visit our websites, we may collect information from you
              automatically through cookies or similar technology.
              <br />
              For further information, visit allaboutcookies.org.
            </Typography>

            <Typography variant="h4" gutterBottom>
              How do we use cookies?
            </Typography>
            <Typography variant="body1" gutterBottom>
              Gamenight uses cookies in a range of ways to improve your
              experience on our website, including:
              <ul>
                <li>Keeping you signed in (cookies via Auth0)</li>
                <li>
                  Analyzing user behavior and counting site visits (cookies via
                  Google Analytics)
                </li>
              </ul>
            </Typography>
            <Typography variant="h4" gutterBottom>
              What types of cookies do we use?
            </Typography>
            <Typography variant="body1" gutterBottom>
              There are a number of different types of cookies, however, our
              website uses: Currently, we only use functionality cookies to
              recognize you on our website. These cookies are set by a
              third-party authentication and authorization company called auth0.
              See https://auth0.com/
              {/* 
Functionality – Gamenight uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.
Advertising – Gamenight uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address. Gamenight sometimes shares some limited aspects of this data with third parties for advertising purposes. We may also share online data collected through cookies with our advertising partners. This means that when you visit another website, you may be shown advertising based on your browsing patterns on our website.
[Add any other types of cookies yGamenight uses] */}
            </Typography>
            <Typography variant="h4" gutterBottom>
              How to manage cookies
            </Typography>
            <Typography variant="body1" gutterBottom>
              You can set your browser not to accept cookies, and the above
              website tells you how to remove cookies from your browser.
              However, in a few cases, some of our website features may not
              function as a result.
            </Typography>
            <Typography variant="h4" gutterBottom>
              Privacy policies of other websites
            </Typography>
            <Typography variant="body1" gutterBottom>
              The Gamenight website contains links to other websites. Our
              privacy policy applies only to our website, so if you click on a
              link to another website, you should read their privacy policy.
            </Typography>
            <Typography variant="h4" gutterBottom>
              Changes to our privacy policy
            </Typography>
            <Typography variant="body1" gutterBottom>
              Gamenight keeps its privacy policy under regular review and places
              any updates on this web page. This privacy policy was last updated
              on 9 January 2019.
            </Typography>
            <Typography variant="h4" gutterBottom>
              How to contact us
            </Typography>
            <Typography variant="body1" gutterBottom>
              If you have any questions about Gamenight’s privacy policy, the
              data we hold on you, or you would like to exercise one of your
              data protection rights, please do not hesitate to contact us.
              <br />
              <br />
              Email us at: info@are-you.nl
              <br />
              Call us: +31 (zero) 61403 7880.
              <br />
              Or write to us at: Are-you.nl / Gamenight. Lucellestr. 8-3hoog.
              1055HX Amsterdam. The Netherlands.
            </Typography>

            <Typography variant="h4" gutterBottom>
              How to contact the appropriate authority
            </Typography>
            <Typography variant="body1" gutterBottom>
              Should you wish to report a complaint or if you feel that Our
              Company has not addressed your concern in a satisfactory manner,
              you may contact the Information Commissioner’s Office (Autoriteit
              Persoonsgegevens).
              <br />
              <br />
              Telephone: (+31) - (0)70 - 888 85 00
              <br />
              Address: Autoriteit Persoonsgegevens. PO Box 93374. 2509 AJ The
              Hague. The Netherlands
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Layout>
  );
};

PrivacyPolicy.displayName = 'PrivacyPolicy';

export default PrivacyPolicy;
