import React from 'react';
import { TextField, Grid, Avatar, Chip } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';

import Block from './block';
import { useStyles } from './styles';
import { BlockProps } from './types';

const optionName = 'description';

const Description: React.FC<BlockProps<string>> = props => {
  const { form, errors, activeOptions, setActiveOptions, ...other } = props;

  const optionError = activeOptions[optionName] && errors?.[optionName];

  const classes = useStyles(props);

  const hasValue = !!activeOptions[optionName] && !!form.values[optionName];

  return (
    <Block
      title="Description"
      description="Use this text for any information people should know about your event. Things like what snacks/drinks to bring, the planned duration, what games you will play, etc."
      optionValue={form.values[optionName]}
      optionName={optionName}
      hasValue={hasValue}
      form={form}
      errors={errors}
      cardContent={
        hasValue && !optionError ? (
          <Chip
            icon={<InfoIcon />}
            color="primary"
            variant="outlined"
            label={form.values[optionName]}
            className={classes.chip}
          />
        ) : (
          <Avatar
            className={[
              classes.avatarLarge,
              !!optionError ? classes.avatarError : '',
            ].join(' ')}
          >
            <InfoIcon color="inherit" />
          </Avatar>
        )
      }
      activeOptions={activeOptions}
      setActiveOptions={setActiveOptions}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <InfoIcon color="inherit" />
        </Grid>
        <Grid item xs>
          <TextField
            type="text"
            variant="outlined"
            className={classes.inputFieldFullWidth}
            multiline
            rows={6}
            rowsMax={6}
            value={form.values[optionName]}
            label="Description"
            helperText={errors?.[optionName]}
            error={!!errors?.[optionName]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.preventDefault();
              form.setFieldValue('description', event.target.value, true);
            }}
            {...other}
          />
        </Grid>
      </Grid>
    </Block>
  );
};

Description.displayName = 'Description';

export default Description;
