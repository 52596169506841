import React from 'react';
import {
  Box,
  Card,
  Container,
  CardContent,
  Grid,
  Chip,
} from '@material-ui/core';
import { Cancel as CancelIcon, Lock as LockIcon } from '@material-ui/icons';
import { isPast } from 'date-fns';
import { useQuery } from '@apollo/react-hooks';
import { GET_EVENT } from '@gamenight/common/dist/queries';
import {
  Attendance as AttendanceType,
  EVENT_STATUS,
} from '@gamenight/common/dist/types';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useAuth0 } from '../../auth';
import LoadingScreen from '../loading-screen';
import { getAttendees, getAttendingCount } from '../../helpers';
import AttendanceList from '../attendance-list';
import Header from './header';
import Author from './author';
import Details from './details';
import Invite from './invite';
import Attendance from './attendance';
import Share from './share';
import { QueryResult, Props, QueryProps } from './types';
import { useStyles } from './styles';
import EditEventDialog from '../edit-event-dialog';
import UserAttendanceStatus from './user-attendance-status';
import UpdateOwnerDialog from './update-owner-dialog';
import SetOwnerDialog from './set-owner-dialog';

const EventContent: React.FC<Props> = ({ eventCode, attendanceCode }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { isAuthenticated, user } = useAuth0();
  const classes = useStyles();
  const history = useHistory();
  const { data, loading, error } = useQuery<QueryResult, QueryProps>(
    GET_EVENT,
    {
      variables: { eventCode },
    },
  );

  const event = data?.event[0];

  const attendingCount = React.useMemo(
    () => (event ? getAttendingCount(event) : 0),
    [event],
  );

  if (loading) return <LoadingScreen fullscreen />;

  if (error || !event) {
    console.log('EROR:::', error);
    // TODO: sentry
    history.replace('/404');
    return null;
  }

  const eventHasPassed = isPast(new Date(event.date));
  const attendees = getAttendees(event);
  const eventIsFull =
    event.options?.persons?.max && event.options.persons.max <= attendingCount;

  const guestHasAdminLinkForUserEvent =
    !isAuthenticated && event.admin_code === attendanceCode && !!event.user_id;

  const userIsAdmin =
    (!isAuthenticated &&
      event.admin_code === attendanceCode &&
      !event.user_id) ||
    (isAuthenticated &&
      !!user &&
      !!event.user_id &&
      event.user_id === user.sub);

  const eventHasAuthor =
    (!event.user && !!event.guest_name) || !!event.user?.name;

  const userAttendance =
    !!attendees &&
    attendees.find(
      (item: AttendanceType) =>
        (!isAuthenticated && item.code === attendanceCode) ||
        (isAuthenticated && !!user && item.user_id === user.sub),
    );

  return (
    <>
      <Helmet>
        <title>{event.title} | Gamenight</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content={`${event.title} | Gamenight`} />
      </Helmet>
      {!eventHasPassed && typeof userAttendance?.attendance === 'boolean' && (
        <UserAttendanceStatus
          isAttending={!!userAttendance?.attendance}
          isAuthenticated={isAuthenticated}
        />
      )}
      <Header
        event={event}
        userIsAdmin={userIsAdmin}
        setEditDialogOpen={setDialogOpen}
      />
      <Container maxWidth="md" className={classes.container}>
        <Box display="flex" className={classes.contentContainer}>
          <Box className={classes.content}>
            <Card>
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                >
                  <Grid xs item>
                    {event.status === EVENT_STATUS.LOCKED && (
                      <Chip
                        variant="outlined"
                        label="Locked"
                        icon={<LockIcon />}
                        className={classes.eventChip}
                      />
                    )}
                    {event.status === EVENT_STATUS.CANCELED && (
                      <Chip
                        variant="outlined"
                        label="Canceled"
                        icon={<CancelIcon />}
                        className={classes.eventChip}
                      />
                    )}
                    {event.status !== EVENT_STATUS.CANCELED &&
                      eventHasPassed && (
                        <Chip
                          variant="outlined"
                          label="This event is in the past"
                          className={classes.eventChip}
                        />
                      )}
                  </Grid>
                </Grid>
                <Details
                  event={event}
                  userAttendance={userAttendance}
                  userIsAdmin={userIsAdmin}
                />
              </CardContent>
            </Card>
            {eventHasAuthor && (
              <Card className={classes.attending}>
                <CardContent>
                  <AttendanceList
                    attendees={attendees}
                    event={event}
                    attendanceCode={attendanceCode}
                  />
                </CardContent>
              </Card>
            )}
          </Box>
          <Box className={classes.sidebar}>
            {!userIsAdmin &&
              event.options?.public?.share === true &&
              !eventHasPassed &&
              event.status === EVENT_STATUS.ACTIVE && <Share event={event} />}
            {eventHasAuthor && (
              <Author
                event={event}
                setEditDialogOpen={setDialogOpen}
                userIsAdmin={userIsAdmin}
                guestHasAdminLinkForUserEvent={guestHasAdminLinkForUserEvent}
              />
            )}
            {/* {!eventHasPassed && <Calendar event={event} />} */}
          </Box>
        </Box>
      </Container>
      {eventHasAuthor &&
        !eventHasPassed &&
        !userAttendance &&
        !eventIsFull &&
        event.status === EVENT_STATUS.ACTIVE && <Attendance event={event} />}
      {eventHasAuthor &&
        userIsAdmin &&
        !eventHasPassed &&
        event.status === EVENT_STATUS.ACTIVE && <Invite event={event} />}
      <EditEventDialog
        event={event}
        dialogOpen={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      />
      {eventHasAuthor && isAuthenticated && attendanceCode && user && (
        <UpdateOwnerDialog
          event={event}
          attendanceCode={attendanceCode}
          userAttendance={userAttendance}
          user={user}
        />
      )}
      {!eventHasAuthor && <SetOwnerDialog event={event} />}
    </>
  );
};
export default EventContent;
