import React from 'react';
import { Typography, Box, List } from '@material-ui/core';
import { isPast } from 'date-fns';
import { Attendance as AttendanceType } from '@gamenight/common/dist/types';

import { Props } from './types';
import { useStyles } from './styles';
import SingleList from './single-list';

const AttendanceList: React.FC<Props> = props => {
  const classes = useStyles(props);
  const { attendees, event, attendanceCode } = props;

  const attendeesYes = attendees.filter(
    (item: AttendanceType) => !!item.attendance,
  );
  const attendeesNo = attendees.filter(
    (item: AttendanceType) => !item.attendance,
  );

  const eventHasPassed = isPast(new Date(event.date));

  const maxExtraPersons =
    !!event.options?.persons?.max &&
    event.options?.persons?.max > attendeesYes.length
      ? event.options?.persons?.max - attendeesYes.length
      : undefined;

  return (
    <Box>
      {attendees.length > 0 ? (
        <List className={classes.root} subheader={<li />}>
          {attendeesYes.length > 0 && (
            <li key="attendees-yes" className={classes.listSection}>
              <SingleList
                attendees={attendeesYes}
                event={event}
                attendanceCode={attendanceCode}
                maxExtraPersons={maxExtraPersons}
                attending
              >
                Attending ({attendeesYes.length})
              </SingleList>
            </li>
          )}
          {attendeesNo.length > 0 && (
            <>
              <br />
              <li key="attendees-no" className={classes.listSection}>
                <SingleList
                  attendees={attendeesNo}
                  event={event}
                  attendanceCode={attendanceCode}
                  attending={false}
                >
                  Not attending ({attendeesNo.length})
                </SingleList>
              </li>
            </>
          )}
        </List>
      ) : eventHasPassed ? (
        <Typography gutterBottom variant="subtitle1" color="textSecondary">
          Nobody responded
        </Typography>
      ) : (
        <>
          <Typography gutterBottom variant="subtitle1" color="textSecondary">
            No responses yet
          </Typography>
        </>
      )}
    </Box>
  );
};

AttendanceList.displayName = 'AttendanceList';

export default AttendanceList;
