import React from 'react';
import { Typography, Box, Tooltip, IconButton } from '@material-ui/core';
import { format, formatDistanceToNow, isPast } from 'date-fns';
import generateColorHash from 'material-color-hash';
import {
  Today as TodayIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons';
import { EVENT_STATUS } from '@gamenight/common/dist/types';

import { Props } from './types';
import { useStyles } from './styles';
import { getCalendarLink } from '../../../helpers';
import AdminMenu from '../admin-menu';

const Header: React.FC<Props> = props => {
  const { event, userIsAdmin, setEditDialogOpen } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const color1 = generateColorHash(event.code, 500);
  const color2 = generateColorHash(
    event.admin_code || `${event.code}_gradient`,
    500,
  );

  const classes = useStyles({
    ...props,
    color1: color1.backgroundColor,
    color2: color2.backgroundColor,
    isPast: isPast(new Date(event.date)),
  });

  return (
    <div className={classes.header}>
      <div className={classes.headerOverlay} />
      <Box position="relative" zIndex="1">
        <Typography
          variant="h1"
          component="h1"
          color="inherit"
          className={[classes.headerText, classes.title].join(' ')}
        >
          {event.title}
          {userIsAdmin && event.status !== EVENT_STATUS.CANCELED && (
            <>
              {' '}
              <IconButton
                aria-controls="author-admin-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <Tooltip title="Admin actions">
                  <SettingsIcon color="inherit" />
                </Tooltip>
              </IconButton>
              <AdminMenu
                event={event}
                setEditDialogOpen={setEditDialogOpen}
                anchorEl={anchorEl}
                id="author-admin-menu"
                open={Boolean(anchorEl)}
                onClose={handleClose}
                keepMounted
                closeMenu={() => setAnchorEl(null)}
              />
            </>
          )}
        </Typography>
        <Typography
          variant="h5"
          color="inherit"
          className={classes.headerText}
          gutterBottom
        >
          ({formatDistanceToNow(new Date(event.date), { addSuffix: true })})
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="subtitle1"
            component="h3"
            color="inherit"
            className={classes.headerText}
          >
            {event.options?.duration?.date ? (
              <>
                <Typography component="p" variant="inherit" gutterBottom>
                  start: {format(new Date(event.date), 'PPp')}
                </Typography>
                <Typography component="p" variant="inherit" gutterBottom>
                  until: {format(new Date(event.options.duration.date), 'PPp')}
                </Typography>
              </>
            ) : (
              format(new Date(event.date), 'PPp')
            )}
          </Typography>

          {!isPast(new Date(event.date)) && (
            <IconButton
              component="a"
              target="_blank"
              href={getCalendarLink(event)}
            >
              <Tooltip title="Add to Google Calendar">
                <TodayIcon className={classes.calendarIcon} />
              </Tooltip>
            </IconButton>
          )}
        </Box>
      </Box>
    </div>
  );
};

Header.displayName = 'Header';

export default Header;
