import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useUiContext } from '@gamenight/common/dist/hooks/ui-context';

import ProfileTab from './components/profile-tab';
import BoardgamesTab from './components/boardgames-tab';
import DeleteDialog from './components/delete-dialog';
import Layout from '../../components/layout';
import { useAuth0 } from '../../auth';
import { getAvatarLetter, formatDate } from '../../helpers';
import { useStyles } from './styles';

const Account: React.FC = () => {
  const { isAuthenticated, user } = useAuth0();
  const { replace } = useHistory();
  const classes = useStyles();
  const { accountTab, setAccountTab } = useUiContext();

  const [deleteDialog, setDeleteDialog] = React.useState(false);

  if (!isAuthenticated || !user) {
    replace('/');
    return null;
  }

  const changeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setAccountTab(newValue);
  };

  return (
    <Layout>
      <Box my={3} display="flex" alignItems="center" justifyContent="center">
        <Avatar alt={user.name} src={user.picture} className={classes.avatar}>
          {getAvatarLetter(user.name)}
        </Avatar>
        <Box ml={2}>
          <Typography variant="h5" gutterBottom>
            {user.name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Signed up since {formatDate(user.created_at, 'date')}
          </Typography>
        </Box>
      </Box>

      <Container maxWidth="sm">
        <Card>
          <Tabs
            aria-label="account tabs"
            value={accountTab}
            onChange={changeTab}
            centered
            variant="fullWidth"
          >
            <Tab label="My profile" />
            <Tab label="Boardgames" />
          </Tabs>
          <CardContent>
            {accountTab === 0 && <ProfileTab />}
            {accountTab === 1 && <BoardgamesTab user={user} />}
          </CardContent>
        </Card>
        <br />
        {accountTab === 0 && (
          <Card>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                Danger zone!
              </Typography>
              <Typography variant="caption" gutterBottom>
                Careful, once you delete your account you will lose all your
                data. Any events or responses to events posted by you will
                disappear.
                <br />
                <br />
              </Typography>

              <Button
                variant="contained"
                className={classes.deleteButton}
                onClick={() => setDeleteDialog(true)}
              >
                Delete account
              </Button>
            </CardContent>
          </Card>
        )}
      </Container>
      <DeleteDialog
        open={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
      />
    </Layout>
  );
};

Account.displayName = 'Account';

export default Account;
