import * as Yup from 'yup';

export const getValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .min(1, 'Too short')
      .max(80, 'Too long')
      .required('Required'),
    description: Yup.string().max(2000, 'Too long'),
  });
