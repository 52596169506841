import React from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_USER, UPDATE_USER_EMAIL } from '@gamenight/common/dist/queries';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Grid,
} from '@material-ui/core';
import { Email as EmailIcon } from '@material-ui/icons';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as Sentry from '@sentry/browser';

import { useAuth0 } from '../../auth/auth';
import { Values } from './types';
import LoadingScreen from '../../components/loading-screen';
import { normalizeAccountData } from '../../helpers';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Required')
    .email('Invalid email address'),
});

// component to load database-user content and store it on auth-object
const UserQuery: React.FC = ({ children }) => {
  const { isAuthenticated, user, setUser } = useAuth0();
  const [userLoaded, setUserLoaded] = React.useState(false);
  const [getUser, { data }] = useLazyQuery(GET_USER);
  const [submitUpdateUserEmailMutation, { loading }] = useMutation(
    UPDATE_USER_EMAIL,
  );

  React.useEffect(() => {
    if (isAuthenticated && !!user && !!user.sub) {
      Sentry.configureScope(function(scope) {
        scope.setUser({ email: user.email, id: user.sub });
      });
      getUser({ variables: { userId: user.sub } });
    }
    if (!isAuthenticated && userLoaded) {
      setUser(undefined);
      setUserLoaded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  React.useEffect(() => {
    if (!userLoaded && data) {
      const account = normalizeAccountData(data);
      setUserLoaded(true);
      if (account) {
        setUser({
          nickname: user?.nickname,
          sub: user?.sub,
          iat: user?.iat,
          ...account,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSubmit = async (values: Values) => {
    if (!user) {
      throw new Error('Missing user object');
    }
    // set email, then call getUser to trigger effect
    await submitUpdateUserEmailMutation({
      variables: {
        userId: user.sub,
        email: values.email,
      },
    });
    setUser({
      ...user,
      email: values.email,
    });
  };

  // TODO: handle guest-user flow
  if (isAuthenticated && !userLoaded) return null;

  return (
    <>
      {children}
      {isAuthenticated && user && !user.email && (
        <Formik
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            // setFieldValue
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            submitForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Dialog open={true}>
                <DialogTitle id="alert-dialog-title">
                  {"We're missing your email address"}
                </DialogTitle>
                <DialogContent>
                  {loading && <LoadingScreen variant="overlay" fullscreen />}
                  <DialogContentText id="alert-dialog-description">
                    We need an email address from you, to notify you about any
                    important event-stuff.
                  </DialogContentText>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <EmailIcon />
                    </Grid>
                    <Grid item>
                      <TextField
                        autoFocus
                        variant="outlined"
                        error={!!errors.email && touched.email}
                        id="input-email"
                        type="email"
                        label="Email address"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        helperText={
                          errors.email && touched.email && errors.email
                        }
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={submitForm}
                    color="secondary"
                    variant="contained"
                    disabled={loading}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

UserQuery.displayName = 'UserQuery';

export default UserQuery;
