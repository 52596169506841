// check if authenticated
// check if attendanceCode
// == admin_code > is this your event? (show avatar + event title)? > yes (connect) / no (push to default event-url)
// == attendance > double checking: did you respond to this event earlier as 'old name'? yes (connect) / no (push)

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Link,
  TextField,
} from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import {
  UPDATE_EVENT_OWNER,
  GET_EVENT,
  UPSERT_ATTENDANCE,
  UPSERT_GUEST_ATTENDANCE,
} from '@gamenight/common/dist/queries';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useEventChange } from '../../../helpers';
import { useAuth0 } from '../../../auth';
import { Props, Values } from './types';
import { useStyles } from './styles';

const intitialFormValues: Values = {
  guestEmail: '',
  guestName: '',
};

const validationSchema = Yup.object().shape({
  guestEmail: Yup.string()
    .required('Required')
    .email('Invalid email address'),
  guestName: Yup.string()
    .min(1, 'Too short')
    .max(30, 'Too long')
    .required('Required'),
});

const SetOwnerDialog: React.FC<Props> = props => {
  const { event } = props;
  const eventChange = useEventChange();
  const classes = useStyles();
  const { isAuthenticated, user, loginWithPopup } = useAuth0();
  const [dialogOpen, setDialogOpen] = React.useState(true);

  const [submitAttendanceMutation] = useMutation(UPSERT_ATTENDANCE);
  const [submitGuestAttendanceMutation] = useMutation(UPSERT_GUEST_ATTENDANCE);
  const [updateEventOwnerMutation] = useMutation(UPDATE_EVENT_OWNER);

  React.useEffect(() => {
    const updateEventOwnerWithUser = async () => {
      console.log('calling our mutation with user:', user);

      // update event
      await updateEventOwnerMutation({
        variables: {
          id: event.id,
          userId: user?.id,
        },
      });

      // update attendance
      const attendanceData = {
        code: event.admin_code,
        attendance: true,
        eventId: event.id,
      };

      await submitAttendanceMutation({
        variables: {
          ...attendanceData,
          userId: user?.sub,
        },
        refetchQueries: [
          {
            query: GET_EVENT,
            variables: { eventCode: event?.code },
          },
        ],
      });

      eventChange(event, isAuthenticated, true);
    };

    if (isAuthenticated && !!user) {
      updateEventOwnerWithUser();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const handleSubmit = async (values: Values) => {
    console.log('SET', event, values);
    await updateEventOwnerMutation({
      variables: {
        id: event.id,
        guestEmail: values.guestEmail,
        guestName: values.guestName,
      },
    });

    const attendanceData = {
      code: event.admin_code,
      attendance: true,
      eventId: event.id,
    };

    await submitGuestAttendanceMutation({
      variables: {
        ...attendanceData,
        guestName: values.guestName,
        guestEmail: values.guestEmail,
      },
      refetchQueries: [
        {
          query: GET_EVENT,
          variables: { eventCode: event?.code },
        },
      ],
    });

    eventChange(
      {
        ...event,
        guest_name: values.guestName,
        guest_email: values.guestEmail,
      },
      isAuthenticated,
      true,
    );
  };

  return (
    <Formik
      initialValues={intitialFormValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        // setFieldValue
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        submitForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Dialog
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            aria-label="update ownership of event"
          >
            <DialogTitle id="dialog-update-owner-title">
              What is your name?
            </DialogTitle>
            <DialogContent>
              <>
                <Typography variant="body1" gutterBottom>
                  You will get an email to edit the event later on. Your email
                  will not be visible for anybody.
                </Typography>
                <Box display="flex" flexDirection="column">
                  <div>
                    <TextField
                      className={classes.textField}
                      error={!!errors.guestName && touched.guestName}
                      id="guest-form-input-name"
                      label="Your name"
                      type="text"
                      name="guestName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.guestName}
                      helperText={
                        errors.guestName &&
                        touched.guestName &&
                        errors.guestName
                      }
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={!!errors.guestEmail && touched.guestEmail}
                      id="guest-form-input-email"
                      label="Email"
                      type="email"
                      name="guestEmail"
                      onChange={handleChange}
                      placeholder="Email (not visible for others)"
                      onBlur={handleBlur}
                      value={values.guestEmail}
                      helperText={
                        errors.guestEmail &&
                        touched.guestEmail &&
                        errors.guestEmail
                      }
                      variant="outlined"
                    />
                  </div>

                  <div>
                    <br />
                    <Typography variant="body2">
                      Do you already have an account, or do you want one?{' '}
                      <Link href="#" onClick={() => loginWithPopup()}>
                        login or signup
                      </Link>
                      .
                    </Typography>
                  </div>
                </Box>
              </>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  submitForm();
                }}
                color="secondary"
                variant="contained"
                autoFocus
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      )}
    </Formik>
  );
};

SetOwnerDialog.displayName = 'SetOwnerDialog';

export default SetOwnerDialog;
