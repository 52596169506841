import { Game } from '@gamenight/common/dist/types';

// order games based on order array
export const sortGames = (games: Game[], order: number[] = []): Game[] =>
  order.map(gameTitleId => {
    const currentGame = games.find(game => game.gameTitleId === gameTitleId);
    if (!currentGame) {
      throw new Error(`missing game with gameTitleId ${gameTitleId}`);
    }
    return currentGame;
  });
