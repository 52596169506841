import React from 'react';

import { useStyles } from './styles';

const Bullet = () => {
  const classes = useStyles();
  return <span className={classes.bullet}>•</span>;
};

Bullet.displayName = 'Bullet';

export default Bullet;
