import React from 'react';
import { Typography, Box, Link, Button } from '@material-ui/core';
import {
  Today as TodayIcon,
  Group as GroupIcon,
  Settings as SettingsIcon,
  Share as ShareIcon,
} from '@material-ui/icons';

import { useStyles } from './styles';
import Layout from '../../components/layout';
import EditEventDialog from '../../components/edit-event-dialog';
import InfoBlock from '../../components/info-block';

const NewEvent: React.FC = () => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <Layout>
      <Typography variant="h2" gutterBottom>
        Make a new event
      </Typography>
      <Typography variant="h6" gutterBottom>
        Already know the drill?{' '}
        <Link onClick={() => setDialogOpen(true)}>Click here</Link> to create a
        new event!
      </Typography>
      <Typography variant="h6" gutterBottom>
        If not, check out these steps for a perfect gamenight!
      </Typography>
      <InfoBlock corner="1." variant="left" backgroundIcon={<TodayIcon />}>
        <Typography variant="h4" gutterBottom>
          Pick a date
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          A gamenight starts with a title, date/time and a short description.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Some tips for a perfect group:
          <ul>
            <li>Check up front with one or two friends if they can make it</li>
            <li>
              Use the description to tell what kind of gamenight this will be
            </li>
          </ul>
        </Typography>
      </InfoBlock>
      <InfoBlock corner="2." variant="right" backgroundIcon={<GroupIcon />}>
        <Typography variant="h4" gutterBottom>
          Max (or min) persons
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Really want to play that game that is perfect with 5 players?
          <br />
          Control how many people sign up for your event:
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <li>
            <b>Max persons</b> (people can no longer sign up after this)
          </li>
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <li>
            <b>Min persons</b>
          </li>
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Want to know who is coming before a certain time? Use the{' '}
          <b>deadline</b> option. We will send you an email when the deadline
          expires, so you can choose to lock or cancel an event.
        </Typography>
      </InfoBlock>
      <InfoBlock corner="3." variant="left" backgroundIcon={<SettingsIcon />}>
        <Typography variant="h4" gutterBottom>
          Other options
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Add a <b>location</b>. Set the <b>end-time</b> of your event. Instruct
          people <b>what to bring</b>. Use the extra options to tell your
          friends what they can expect.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          When you save your event you will get an email with the special
          event-link. That will allow you to check out and change your event
          later on.
        </Typography>
      </InfoBlock>
      <InfoBlock corner="4." variant="right" backgroundIcon={<ShareIcon />}>
        <Typography variant="h4" gutterBottom>
          Share
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Use <b>WhatsApp</b>, <b>mail</b>, <b>SMS</b> or anything else (by
          using the <b>event link</b> option) to let your friends know you are
          planning a gamenight. With WhatsApp, you can just put the link in your
          groups as well.
          <br />
          They will click the link and can sign up for your event.
        </Typography>
      </InfoBlock>
      <InfoBlock corner="5." variant="full">
        <Typography variant="h4" gutterBottom>
          That's it!
        </Typography>
        <Typography variant="subtitle1">
          Remember: you can share your event to other people at a later stage as
          well. You can also lock the event (prevent any new sign-ups), notify
          your guests or cancel the event.
        </Typography>
      </InfoBlock>
      <Box my={6} display="flex" alignItems="center" justifyContent="center">
        <Button
          className={classes.newEventButton}
          variant="contained"
          color="secondary"
          onClick={() => setDialogOpen(true)}
        >
          Create your event!
        </Button>
      </Box>
      <EditEventDialog
        dialogOpen={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      />
    </Layout>
  );
};

NewEvent.displayName = 'NewEvent';

export default NewEvent;
