import React from 'react';
import {
  Card,
  CardContent,
  Container,
  Button,
  Typography,
} from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import UiContextProvider from '@gamenight/common/dist/hooks/ui-context';
import { Auth0Provider } from '../../auth/auth';

import Layout from '../layout';
import { Props, State, ErrorInfo } from './types';

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // TODO: Sentry log
    this.setState({
      hasError: true,
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <BrowserRouter>
          <Auth0Provider>
            <UiContextProvider>
              <Layout variant="error">
                <Container maxWidth="sm" style={{ justifyContent: 'center' }}>
                  <Card>
                    <CardContent style={{ backgroundColor: '#76b39d' }}>
                      {this.state.error?.message === 'could-not-fetch' ? (
                        <>
                          <Typography variant="h4" gutterBottom>
                            It looks like you are offline
                          </Typography>
                          <Typography variant="subtitle1" gutterBottom>
                            Double check your connection and refresh this page.
                            <br />
                          </Typography>
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => window.location.reload(true)}
                          >
                            Refresh page
                          </Button>
                        </>
                      ) : (
                        <>
                          <Typography variant="h4" gutterBottom>
                            Something went wrong :(
                          </Typography>
                          <Typography variant="subtitle1" gutterBottom>
                            You can try to wait a sec and refresh the page.
                            <br />
                          </Typography>
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => window.location.reload(true)}
                          >
                            Refresh page
                          </Button>
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Container>
              </Layout>
            </UiContextProvider>{' '}
          </Auth0Provider>
        </BrowserRouter>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
