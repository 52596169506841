import React from 'react';
import { Chip } from '@material-ui/core';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@material-ui/icons';

import { Props } from './types';
import { useStyles } from './styles';

const UserAttendanceStatus: React.FC<Props> = props => {
  const classes = useStyles(props);
  const { isAttending } = props;

  return (
    <Chip
      className={classes.attendanceStatus}
      label={isAttending ? 'You will be there' : "You won't be there"}
      icon={isAttending ? <CheckCircleIcon /> : <CancelIcon />}
    />
  );
};

UserAttendanceStatus.displayName = 'UserAttendanceStatus';

export default UserAttendanceStatus;
