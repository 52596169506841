import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import {
  DELETE_USER_GAME,
  GET_USER_GAMES,
  UPSERT_USER_GAME,
} from '@gamenight/common/dist/queries';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useUiContext } from '@gamenight/common/dist/hooks';
import { Game, GameSummary } from '@gamenight/common/dist/types';

import GameSearch from '../../../components/game-search';
import GameList from '../../../components/game-list';
import { useStyles } from './styles';
import LoadingScreen from '../../../components/loading-screen';
import { normalizeUserGames } from '../../../helpers';
import { BoardgameTabProps } from './types';
import InfoBlock from '../../../components/info-block';

const checkboxesDefault = {
  own: false,
  favorite: false,
};

const BoardgamesTab: React.FC<BoardgameTabProps> = ({ user }) => {
  const { setSnackbarMessage } = useUiContext();
  const classes = useStyles();

  const [upsertUserGameMutation] = useMutation(UPSERT_USER_GAME);
  const [deleteUserGameMutation] = useMutation(DELETE_USER_GAME);
  const { data, loading, error } = useQuery(GET_USER_GAMES, {
    variables: { userId: user.id },
  });
  const [mutationLoading, setMutationLoading] = React.useState(false);
  const [userGames, setUserGames] = React.useState<Game[]>([]);
  const [checkboxes, setCheckboxes] = React.useState(checkboxesDefault);

  React.useEffect(() => {
    setUserGames(normalizeUserGames(data));
  }, [data]);

  if (!user) {
    return null;
  }

  if (error) {
    throw new Error('Could not load user data');
  }

  const resetCheckboxes = (game: GameSummary | null) => {
    if (!game || !game.isUserGame) {
      setCheckboxes(checkboxesDefault);
    }
    setCheckboxes({
      own: !!game?.own,
      favorite: !!game?.favorite,
    });
  };

  const selectCheckbox = (box: 'own' | 'favorite') => (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setCheckboxes({
      ...checkboxes,
      [box]: checked,
    });
  };

  const upsertUserGame = (
    game: Game,
    closeDialog: () => void,
    closeOptions?: () => void,
  ) => async () => {
    if (!user || !user.id) return;
    setMutationLoading(true);
    try {
      await upsertUserGameMutation({
        variables: {
          gameTitleId: game.gameTitleId,
          own: !!checkboxes.own,
          favorite: !!checkboxes.favorite,
          order: game.order || 0,
          userId: user.id,
        },
        refetchQueries: [
          {
            query: GET_USER_GAMES,
            variables: { userId: user.id },
          },
        ],
      });
    } catch (error) {
      console.log(error);
      throw new Error(error.message);
    }
    if (closeOptions) closeOptions();
    closeDialog();
    setMutationLoading(false);
    setSnackbarMessage(
      game.isUserGame ? 'Updated game' : 'Added game to your list',
    );
  };

  const removeUserGame = (
    game: Game,
    closeDialog: () => void,
    closeOptions?: () => void,
  ) => async () => {
    if (!game.gameTitleId || !user || !user.id) return;
    setMutationLoading(true);
    await deleteUserGameMutation({
      variables: {
        userId: user.id,
        gameTitleId: game.gameTitleId,
      },
      refetchQueries: [
        {
          query: GET_USER_GAMES,
          variables: { userId: user.id },
        },
      ],
    });
    if (closeOptions) closeOptions();
    closeDialog();
    setMutationLoading(false);
    setSnackbarMessage('Removed game from your boardgames');
  };

  const onDragEnd = async (newGames: Game[]) => {
    await Promise.all(
      newGames.map(async (game, index: number) => {
        await upsertUserGameMutation({
          variables: {
            gameTitleId: game.gameTitleId,
            own: game.own,
            favorite: game.favorite,
            order: index,
            userId: user.id,
          },
          // TODO: refetch after batched update
        });
      }),
    );
    // done
  };

  const getAction = () => (
    game: Game,
    closeDialog: () => void,
    closeOptions?: () => void,
  ) => (
    <Grid container>
      {game.isUserGame && (
        <Grid item>
          <Box className={classes.deleteButton}>
            <IconButton
              onClick={removeUserGame(game, closeDialog, closeOptions)}
              aria-label="remove from boardgames list"
              disabled={mutationLoading}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Grid>
      )}
      <Grid xs item>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxes.own}
                onChange={selectCheckbox('own')}
                value="own"
                disabled={mutationLoading}
              />
            }
            label="I own it"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxes.favorite}
                onChange={selectCheckbox('favorite')}
                value="favorite"
                disabled={mutationLoading}
              />
            }
            label="Favorite"
          />
          <Button
            color="secondary"
            variant="contained"
            aria-label="add to boardgames list"
            onClick={upsertUserGame(game, closeDialog, closeOptions)}
            disabled={mutationLoading}
          >
            {game.isUserGame ? 'Update' : 'Add to boardgame list'}
          </Button>
        </FormGroup>
      </Grid>
    </Grid>
  );

  return loading ? (
    <LoadingScreen />
  ) : (
    <>
      <GameSearch
        onChange={resetCheckboxes}
        userGames={userGames}
        action={getAction()}
        initialOptions={userGames}
      />
      <br />
      {userGames && (
        <GameList
          games={userGames}
          action={getAction()}
          onSelect={game =>
            setCheckboxes({ own: !!game.own, favorite: !!game.favorite })
          }
          onDrag={onDragEnd}
          showOwn
          showFavorite
          showIndex
          showDragHandle
          emptyList={
            <InfoBlock>
              <Typography variant="h5" gutterBottom>
                Your boardgames
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Add the boardgames in your closet (your collection), or any
                other games you have played.
                <br />
                <br />
                Your boardgames will be visible for others, so other people can
                get an idea what kind of boardgamer you are.
              </Typography>
            </InfoBlock>
          }
        />
      )}
    </>
  );
};

BoardgamesTab.displayName = 'BoardgamesTab';

export default BoardgamesTab;
