import React from 'react';
import {
  Avatar,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import {
  Favorite as FavoriteIcon,
  CheckCircle as CheckCircleIcon,
  DragIndicator as DragIndicatorIcon,
} from '@material-ui/icons';
import { Game } from '@gamenight/common/dist/types';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';

import GameDialog from '../game-dialog';
import { Props } from './types';
import { useStyles } from './styles';
import ConditionalWrapper from '../conditional-wrapper';

const GameList: React.FC<Props> = props => {
  const {
    games: gamesProps,
    action,
    onSelect,
    onDrag,
    showIndex = false,
    showDragHandle = false,
    showOwn = false,
    showFavorite = false,
    hideHeader = false,
    emptyList,
    cellAction,
    cellActionHeader,
  } = props;
  const classes = useStyles();
  const [games, setGames] = React.useState<Game[]>(gamesProps);
  const [game, setGame] = React.useState<Game | null>(null);

  React.useEffect(() => {
    setGames(gamesProps);
  }, [gamesProps]);

  // if (!games) return null;
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const from: number = result.source.index;
    const to: number = result.destination.index;

    if (from === to) {
      return;
    }

    // move game to new index in array
    const newGames = [...games];
    const movedGame = newGames.splice(from, 1)[0];

    newGames.splice(to, 0, movedGame);
    setGames(newGames);

    if (onDrag) {
      onDrag(newGames);
    }
  };

  return games.length > 0 ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <TableContainer component={Paper}>
        <Table>
          {!hideHeader && (
            <TableHead>
              <TableRow>
                {showDragHandle && <TableCell />}
                {showIndex && <TableCell align="right">#</TableCell>}
                <TableCell>&nbsp;</TableCell>
                <TableCell>Game title</TableCell>
                {showOwn && <TableCell>own</TableCell>}
                {showFavorite && <TableCell>favorite</TableCell>}
                {cellAction && <TableCell>{cellActionHeader || ''}</TableCell>}
              </TableRow>
            </TableHead>
          )}
          <Droppable droppableId="table">
            {(provided: DroppableProvided) => (
              <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                {games.map((game, index) => (
                  <ConditionalWrapper
                    key={`row-${game.bgg_id}`}
                    condition={!!onDrag}
                    wrapper={children => (
                      <Draggable draggableId={`${game.bgg_id}`} index={index}>
                        {(
                          provided: DraggableProvided,
                          snapshot: DraggableStateSnapshot,
                        ) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            hover
                            className={clsx(classes.tableRow, {
                              [classes.tableRowDragging]: snapshot.isDragging,
                            })}
                            onClick={() => {
                              setGame(game);
                              if (onSelect) onSelect(game);
                            }}
                          >
                            {children}
                          </TableRow>
                        )}
                      </Draggable>
                    )}
                    elseWrapper={children => (
                      <TableRow
                        className={classes.tableRow}
                        onClick={() => {
                          setGame(game);
                          if (onSelect) onSelect(game);
                        }}
                      >
                        {children}
                      </TableRow>
                    )}
                  >
                    {showDragHandle && (
                      <TableCell className={classes.contentFitColumn}>
                        {' '}
                        <DragIndicatorIcon color="action" />
                      </TableCell>
                    )}
                    {showIndex && (
                      <TableCell
                        align="right"
                        className={classes.contentFitColumn}
                      >
                        {index + 1}
                      </TableCell>
                    )}
                    <TableCell className={classes.contentFitColumn}>
                      <Avatar
                        variant="rounded"
                        alt={game.title}
                        src={game.thumbnail}
                      >
                        {game.title.charAt(0)}
                      </Avatar>
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {game.title}
                      {game.publish_year && (
                        <Typography
                          component="span"
                          variant="body2"
                          color="textSecondary"
                        >
                          &nbsp;({game.publish_year})
                        </Typography>
                      )}
                    </TableCell>
                    {showOwn && (
                      <TableCell className={classes.contentFitColumn}>
                        {!!game.own ? <CheckCircleIcon color="primary" /> : ''}
                      </TableCell>
                    )}
                    {showFavorite && (
                      <TableCell className={classes.contentFitColumn}>
                        {!!game.favorite ? (
                          <FavoriteIcon color="primary" />
                        ) : (
                          ''
                        )}
                      </TableCell>
                    )}
                    {cellAction && (
                      <TableCell align="right">{cellAction(game)}</TableCell>
                    )}
                  </ConditionalWrapper>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </Table>
      </TableContainer>
      {game && (
        <GameDialog
          gameOrSummary={game}
          action={action}
          onClose={() => setGame(null)}
        />
      )}
    </DragDropContext>
  ) : (
    <>{emptyList}</>
  );
};

GameList.displayName = 'GameList';

export default GameList;
