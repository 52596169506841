import React from 'react';
import Layout from '../../components/layout';
import { Typography } from '@material-ui/core';
import { useParams, Link } from 'react-router-dom';

import EventContent from '../../components/event-content';

interface Params {
  eventCode: string;
  attendanceCode: string
}

const EventPage: React.FC = () => {
  const { eventCode, attendanceCode } = useParams<Params>();

  return eventCode ? (
    <Layout variant="event">
      <EventContent eventCode={eventCode} attendanceCode={attendanceCode} />
    </Layout>
  ) : (
      <Layout>
        <Typography variant="h4">Event</Typography>
        <Typography>No event found, please go back</Typography>
        <Link to={'/'}>Home</Link>
      </Layout>
    );
};

export default EventPage;
