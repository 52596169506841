import React from 'react';
import { Box, Button, Chip, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import { UPDATE_USER_PROFILE, GET_USER } from '@gamenight/common/dist/queries';
import { useUiContext } from '@gamenight/common/dist/hooks/ui-context';
import { useMutation } from '@apollo/react-hooks';

import { useAuth0 } from '../../../auth';
import LoadingScreen from '../../../components/loading-screen';
import { getValidationSchema } from '../helpers';
import { Values } from './types';

const ProfileTab: React.FC = () => {
  const { user, setUser } = useAuth0();
  const { setSnackbarMessage } = useUiContext();
  const [updateUserProfileMutation, { loading }] = useMutation(
    UPDATE_USER_PROFILE,
  );

  const initialValues = {
    name: user?.name || 'unknown',
    description: user?.description || '',
  };

  const handleSubmit = async (values: Values) => {
    if (!user) {
      throw new Error('No User object');
    }
    await updateUserProfileMutation({
      variables: {
        userId: user.id,
        name: values.name,
        description: values.description,
      },
      refetchQueries: [
        {
          query: GET_USER,
          variables: { userId: user.id },
        },
      ],
    });
    setUser({
      ...user,
      ...values,
    });
    window.scrollTo(0, 0);
    setSnackbarMessage('Profile updated');
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        submitForm,
      }) => (
        <form onSubmit={handleSubmit}>
          {loading && <LoadingScreen variant="overlay" fullscreen />}

          <Box display="flex" flexDirection="column" my={2}>
            <TextField
              variant="outlined"
              error={!!errors.name && touched.name}
              id="account-form-input-name"
              type="text"
              label="Your name"
              name="name"
              placeholder="Enter your name, sonny"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              helperText={errors.name && touched.name && errors.name}
            />
            <br />
            <TextField
              multiline
              rows={4}
              rowsMax={8}
              variant="outlined"
              error={!!errors.description && touched.description}
              id="account-form-input-description"
              type="text"
              label="About you"
              name="description"
              placeholder="Your favorite games, etc"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              helperText={
                errors.description && touched.description && errors.description
              }
            />

            {/* TODO: location */}
          </Box>
          <br />
          <Chip color="primary" label="Changing avatar is not yet possible" />
          <br />
          <br />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => submitForm()}
          >
            Save changes
          </Button>
        </form>
      )}
    </Formik>
  );
};

ProfileTab.displayName = 'ProfileTab';

export default ProfileTab;
