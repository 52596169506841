import { Event } from '@gamenight/common/dist/types';
import * as Yup from 'yup';

import { getAttendingCount } from '../../../helpers';

export const getValidationSchema = (
  event: Event | undefined,
  activeOptions: Record<string, boolean>,
) => {
  const attendingCount = event && getAttendingCount(event);

  return Yup.object().shape({
    title: Yup.string()
      .min(1, 'Too short')
      .max(180, 'Too long')
      .required('Required'),
    description: Yup.string().max(750, 'Too long'),
    location: Yup.lazy(() =>
      activeOptions.location
        ? Yup.object().shape({
            location: Yup.string().max(500, 'Too long'),
          })
        : Yup.object(),
    ),
    persons: Yup.lazy(() =>
      activeOptions.persons
        ? Yup.object().shape({
            min: Yup.number()
              .min(1, 'Too few')
              .max(50, 'Too many'),
            max: Yup.number()
              .min(
                attendingCount ? attendingCount : 2,
                attendingCount
                  ? 'You have more people signed up already'
                  : 'Too few',
              )
              .max(50, 'Too many'),
          })
        : Yup.object(),
    ),
    responseDeadline: Yup.lazy(() =>
      activeOptions.responseDeadline
        ? Yup.number().min(1, 'Must be more than 0')
        : Yup.number(),
    ),
  });
};
