import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import UiContextProvider from '@gamenight/common/dist/hooks/ui-context';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import ErrorBoundary from '../error-boundary';
import AppWithRouting from '../../router/app-with-routing';
import { Auth0Provider } from '../../auth/auth';
import ApolloProvider from './apollo-provider';
import UserQuery from './user-query';
import ThemeProvider from '../../styles/theme-provider';

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <ErrorBoundary>
        <Auth0Provider>
          <ApolloProvider>
            <UserQuery>
              <BrowserRouter>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <UiContextProvider>
                    <CssBaseline />
                    <AppWithRouting />
                  </UiContextProvider>
                </MuiPickersUtilsProvider>
              </BrowserRouter>
            </UserQuery>
          </ApolloProvider>
        </Auth0Provider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
