import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1,
  },
  closeButtonMedia: {
    position: 'absolute',
    color: 'white',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1,
  },
  media: {
    position: 'relative',
    height: 0,
    paddingTop: '35%',
    backgroundSize: '120%',
  },
  mediaHeader: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: `linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.8) 100%)`,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    padding: theme.spacing(2),
    color: 'white',
    '& span': {
      color: 'white',
    },
  },
  thumbnail: {
    maxWidth: '35%',
    marginRight: theme.spacing(2),
    '& > img': {
      width: '100%',
    },
  },
  publishYear: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1),
    fontSize: 13,
  },
  content: {},
  expand: {
    padding: 0,
    alignSelf: 'flex-end',
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));
