import { makeStyles } from '@material-ui/core/styles';

import { StyledPersonAvatar } from './types';

export const useStyles = makeStyles({
  root: (props: StyledPersonAvatar) => ({
    border: props.border ? '1px solid white' : '0',
    textAlign: 'center',
  }),
});
