import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative' as 'relative',
  },
  contentContainer: {
    marginTop: -1 * theme.spacing(4),
    marginBottom: theme.spacing(4),
    flexDirection: 'column',
    alignItems: 'flex-start',

    [theme.breakpoints.up('md')]: {
      marginTop: -1 * theme.spacing(10),
      flexDirection: 'row',
    },
  },
  content: {
    width: '100%',
  },
  sidebar: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 350,
      paddingLeft: theme.spacing(4),
    },
  },
  attending: {
    marginTop: theme.spacing(2),
  },
  eventChip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipSuccess: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
    '& > svg': {
      color: theme.palette.success.main,
    },
  },
  chipError: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    '& > svg': {
      color: theme.palette.error.main,
    },
  },
}));
